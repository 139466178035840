import { FormControl, MenuItem, Stack, FormLabel, Box } from "@mui/material";
import { Control } from "react-hook-form";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import FormAutoComplete from "../../../shareComponents/form/FormAutoComplete";
import FormInput from "../../../shareComponents/form/FormInput";
import BaseInput from "../../../shareComponents/input/BaseInput";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../../../services/dbConstants";
import { useTranslation } from "react-i18next";

interface AssessmentFormProps {
  control: Control<TreatmentRecordFormState>;
}

const defaultSelectItems = [
  {
    id: -1,
    displayText: "Select one",
  },
];

const AssessmentForm = (props: AssessmentFormProps) => {
  const { control } = props;

  const { t } = useTranslation();

  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const octMachineTypes = defaultSelectItems.concat(
    dbConstants?.data?.octMachineTypes ?? []
  );

  const snellenTypes = defaultSelectItems.concat(
    dbConstants?.data?.snellenTypes ?? []
  );

  return (
    <Stack>
      <FormControl sx={{ width: 1 }}>
        <FormLabel
          error={control._formState.errors.hasOwnProperty("octMachineId")}
          className="labelStyles"
        >
          {t("singlePharse.OctMachine")}
        </FormLabel>
        <FormInput
          select
          control={control}
          name={"octMachineId"}
          size="small"
          sx={{ pb: 1 }}
        >
          {octMachineTypes.map((e) => (
            <MenuItem key={e.id} value={e.id}>
              {e.displayText}
            </MenuItem>
          ))}
        </FormInput>
        <FormLabel
          error={control._formState.errors.hasOwnProperty("cst")}
          className="labelStyles"
        >
          CST
        </FormLabel>
        <FormInput
          control={control}
          name="cst"
          type="number"
          inputProps={{
            min: 130,
            max: 1200,
          }}
          size="small"
          placeholder="Enter here..."
        />
      </FormControl>

      <Box>
        <FormLabel
          error={control._formState.errors.hasOwnProperty("vaSnellen")}
          className="labelStyles"
        >
          VA
        </FormLabel>
        <FormAutoComplete
          sx={{ width: 1 }}
          control={control}
          name="vaSnellen"
          size="small"
          options={snellenTypes}
          getOptionLabel={(option) =>
            option.description == null
              ? option.displayText.toString()
              : option.displayText + "  (" + option.description + ")"
          }
          renderInput={(params) => (
            <BaseInput {...params} placeholder="Select one" />
          )}
          isOptionEqualToValue={(option, value) => {
            return true;
          }}
        />
      </Box>
    </Stack>
  );
};

export default AssessmentForm;
