import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import api from "./api";
import { dbConstatnsProps } from "../utils/types/services/dbConstants";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";
const STATUS_200 = 200;

export const getAllDBConstants = async (): Promise<{
  success: boolean;
  status?: number;
  data?: dbConstatnsProps;
}> => {
  let getAllDBConstatns = null;
  try {
    getAllDBConstatns = await api.get(API_ROUTE_FULL_PATHS.userGetDBConstants);
    if (getAllDBConstatns.status === STATUS_200) {
      return {
        success: true,
        data: {
          diseaseTypes: getAllDBConstatns.data.data.diseaseTypes,
          snellenTypes: getAllDBConstatns.data.data.snellenTypes,
          drugTypes: getAllDBConstatns.data.data.drugTypes,
          treatmentPhaseTypes: getAllDBConstatns.data.data.treatmentPhaseTypes,
          octMachineTypes: getAllDBConstatns.data.data.octMachineTypes,
          instituteTypes: getAllDBConstatns.data.data.instituteTypes,
          instituteNames: getAllDBConstatns.data.data.instituteNames,
          districtList: getAllDBConstatns.data.data.districtList,
          fixedTreatmentWeekLists:
            getAllDBConstatns.data.data.fixedTreatmentWeekLists,
        },
      };
    }

    throw new Error("Retry Will Apply");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: getAllDBConstatns });
  }
};
