import { Divider, Paper, Grid } from "@mui/material";
import { useCallback, useState } from "react";
import {
  EyeDetailsProps,
  TreatmentRecord,
} from "../../../utils/types/services/patients";
import { Eye } from "../../../utils/constants/general";
import ExpandCollapseIcon from "../../shareComponents/icon/ExpandCollapseIcon";
import CSTAndVAGraphModal from "../CSTAndVAGraphModal";
import TreatmentInfo from "./TreatmentInfo";
import { GraphDataItem } from "../types";
import { useTranslation } from "react-i18next";
import { AEDataProps } from "../../../services/treatmentRecord";

interface TreatmentInfoContainerProps {
  leftEyeTreatmentRecords: TreatmentRecord[];
  rightEyeTreatmentRecords: TreatmentRecord[];
  leftEyeDetails: EyeDetailsProps;
  rightEyeDetails: EyeDetailsProps;
  handleAddTreatmentRecordClick: (eye: Eye) => void;
  onEditTreatmentInfoClick: (id: number) => void;
  treatmentMethods: [number, number];
  handleChangeTreatmentMethods: (eye: Eye, value: number) => void;
  handleOpenAEModal: (data: AEDataProps) => void;
  isOwner: boolean;
}

const TreatmentInfoContainer = (props: TreatmentInfoContainerProps) => {
  const {
    leftEyeTreatmentRecords,
    rightEyeTreatmentRecords,
    leftEyeDetails,
    rightEyeDetails,
    handleAddTreatmentRecordClick,
    onEditTreatmentInfoClick,
    treatmentMethods,
    handleChangeTreatmentMethods,
    handleOpenAEModal,
    isOwner,
  } = props;

  const { t } = useTranslation();
  const NUM_10 = 10;
  const NUM_20 = 20;

  const [graphModalOpen, setGraphModalOpen] = useState(false);
  const [graphData, setGraphData] = useState<GraphDataItem[]>([]);
  const [graphEye, setGraphEye] = useState(Eye.LEFT);
  const [showEyeInfo, setShowEyeInfo] = useState<"OD" | "OS" | "BOTH">("BOTH");

  const handleGraphModalClose = useCallback(() => {
    setGraphData([]);
    setGraphModalOpen(false);
  }, []);

  const handleGraphModalOpen = useCallback(
    (eye: Eye) => {
      setGraphData(
        (eye === Eye.LEFT ? leftEyeTreatmentRecords : rightEyeTreatmentRecords)
          .filter((e) => e.recordStatus)
          .map((record) => ({
            cst: record.cst,
            va: record.va,
            vaToDisplay: record.va,
            date: record.date,
            drugName: record.drugName,
            drugId: record.drugId,
            cstStatus: record.cstStatus,
            vaStatus: record.vaStatus,
            treatmentInterval: record.treatmentInterval,
          }))
      );

      setGraphEye(eye);
      setGraphModalOpen(true);
    },
    [leftEyeTreatmentRecords, rightEyeTreatmentRecords]
  );

  const onLeftExpandClick = useCallback(() => {
    setShowEyeInfo((val) => {
      if (val === "BOTH") return "OS";
      if (val === "OD") return "BOTH";
      return val;
    });
  }, []);

  const onRightExpandClick = useCallback(() => {
    setShowEyeInfo((val) => {
      if (val === "BOTH") return "OD";
      if (val === "OS") return "BOTH";
      return val;
    });
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        p: 2,
      }}
    >
      <CSTAndVAGraphModal
        data={graphData}
        open={graphModalOpen}
        handleClose={handleGraphModalClose}
        graphEye={graphEye}
      />

      <Grid
        columns={{ sm: 12, md: 21 }}
        container
        item
        md={21}
        sm={12}
        direction="row"
        justifyContent="center"
        alignItems="flex-start"
      >
        <Grid
          item
          md={
            showEyeInfo === "BOTH" ? NUM_10 : showEyeInfo === "OD" ? NUM_20 : 1
          }
          sm={12}
          style={{
            display:
              showEyeInfo === "BOTH" || showEyeInfo === "OD" ? "flex" : "none",
          }}
        >
          <TreatmentInfo
            header={t("patientDetailPage.rightEye")}
            treatmentRecords={rightEyeTreatmentRecords}
            eyeDetails={rightEyeDetails}
            handleGraphModalOpen={() => handleGraphModalOpen(Eye.RIGHT)}
            handleAddTreatmentRecordClick={() =>
              handleAddTreatmentRecordClick(Eye.RIGHT)
            }
            onEditTreatmentInfoClick={onEditTreatmentInfoClick}
            showEyeInfo={showEyeInfo}
            treatmentMethod={treatmentMethods[Eye.RIGHT]}
            handleChangeTreatmentMethods={(value) =>
              handleChangeTreatmentMethods(Eye.RIGHT, value)
            }
            handleOpenAEModal={handleOpenAEModal}
            isOwner={isOwner}
          />
        </Grid>

        <Grid
          item
          md={1}
          display={{ xs: "none", sm: "none", md: "flex" }}
          justifyContent="center"
          sx={{ height: "100%" }}
        >
          <Divider orientation="vertical">
            <ExpandCollapseIcon
              orientation="horizontal"
              onUpLeftClick={onLeftExpandClick}
              onDownRightClick={onRightExpandClick}
              showUpLeftIcon={showEyeInfo !== "OS"}
              showDownRightIcon={showEyeInfo !== "OD"}
            />
          </Divider>
        </Grid>

        <Grid
          item
          md={
            showEyeInfo === "BOTH" ? NUM_10 : showEyeInfo === "OS" ? NUM_20 : 1
          }
          sm={12}
          style={{
            display:
              showEyeInfo === "BOTH" || showEyeInfo === "OS" ? "flex" : "none",
          }}
        >
          <TreatmentInfo
            header={t("patientDetailPage.leftEye")}
            treatmentRecords={leftEyeTreatmentRecords}
            eyeDetails={leftEyeDetails}
            handleGraphModalOpen={() => handleGraphModalOpen(Eye.LEFT)}
            handleAddTreatmentRecordClick={() =>
              handleAddTreatmentRecordClick(Eye.LEFT)
            }
            onEditTreatmentInfoClick={onEditTreatmentInfoClick}
            showEyeInfo={showEyeInfo}
            treatmentMethod={treatmentMethods[Eye.LEFT]}
            handleChangeTreatmentMethods={(value) =>
              handleChangeTreatmentMethods(Eye.LEFT, value)
            }
            handleOpenAEModal={handleOpenAEModal}
            isOwner={isOwner}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TreatmentInfoContainer;
