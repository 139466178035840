import { TextFieldProps, TextField } from "@mui/material";
import { forwardRef } from "react";

const NoneDisplayInput = forwardRef<HTMLInputElement, TextFieldProps>(
  (props, ref) => {
    return (
      <TextField
        ref={ref}
        value={props.value}
        onChange={props.onChange}
        sx={{ display: "none" }}
      />
    );
  },
);

export default NoneDisplayInput;
