import { Grid, Typography, Paper } from "@mui/material";
import pageImage from "../../assets/images/LoginPage/Innovision-login-image.jpg";
import LoginForm from "./LoginForm";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const LoginWebPage = () => {
  const { t } = useTranslation();

  return (
    <Grid
      container
      sx={{
        height: "100vh",
        width: "100vw",
        backgroundColor: "background.paper",
      }}
    >
      <Grid
        container
        item
        xs={2}
        sm={4}
        md={6}
        lg={5}
        xl={4}
        sx={{
          backgroundColor: "primary.main",
          overFlow: "hidden",
          position: "relative",
        }}
        alignItems="center"
      >
        <img
          src={pageImage}
          alt="login-page-logo"
          loading="lazy"
          style={{
            width: "100%",
            height: "100vh",
            objectFit: "cover",
          }}
        />
      </Grid>
      <Grid xl={1}></Grid>
      <Grid
        container
        item
        xs={10}
        sm={8}
        md={6}
        lg={7}
        xl={6}
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "background.paper" }}
      >
        <LoginForm />
        <Paper
          sx={{
            position: "fixed",
            bottom: 10,
            right: 10,
            maxWidth: "28vw",
          }}
          elevation={0}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("loginPage.help")}
          </Typography>
        </Paper>
        <Paper
          sx={{
            position: "fixed",
            bottom: 10,
            left: 10,
            maxWidth: "68vw",
            backgroundColor: "primary.light",
            px: 1,
          }}
          elevation={0}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {t("loginPage.regulatoryStatement")}
          </Typography>
        </Paper>
      </Grid>
      <Grid xl={1}></Grid>
    </Grid>
  );
};

export default LoginWebPage;
