import {
  FormControl,
  MenuItem,
  Box,
  Grid,
  Typography,
  FormLabel,
} from "@mui/material";
import { useState, useCallback, ChangeEvent } from "react";
import { Control } from "react-hook-form";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import FormInput from "../../../shareComponents/form/FormInput";
import { REACT_QUERY_KEYS } from "../../../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../../../services/dbConstants";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getProductDetailsFromQRScan } from "../../../../services/treatmentRecord";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../../utils/constants/ga";
import BaseButton from "../../../shareComponents/button/BaseButton";
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import ProductScanModal from "./ProductScanModal";
import { userGetData } from "../../../../services/user";
import { TreatmentRecord } from "../../../../utils/types/services/patients";

type DRUG_INFO = {
  drugId: number;
  serialNumber: string;
  lotNumber: string;
  expiryDate: string;
};

interface InjectionFormProps {
  control: Control<TreatmentRecordFormState>;
  treatmentRecord?: TreatmentRecord | null;
  checkDrugDataChanged: (drugId: number, scanned: string | null) => void;
  setScannedDetails: (scannedData: DRUG_INFO) => void;
  scannedDataValue: string | null;
  setScannedDataValue: (data: string | null) => void;
  serialNumberStatus: {
    isused: boolean;
    isNotExist: boolean;
  };
  setSerialNumberStatus: (data: {
    isused: boolean;
    isNotExist: boolean;
  }) => void;
}

const listHeader = {
  color: "#737373",
  fontSize: "14px",
  fontWeight: "400",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
} as const;

const defaultSelectItems = [
  {
    id: -1,
    displayText: "Select a Drug",
  },
];

const InjectionForm = (props: InjectionFormProps) => {
  const {
    control,
    treatmentRecord,
    checkDrugDataChanged,
    setScannedDetails,
    scannedDataValue,
    setScannedDataValue,
    serialNumberStatus,
    setSerialNumberStatus,
  } = props;

  const { t } = useTranslation();
  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );
  const SPACE_BETWEEN = "space-between";
  const NUM_18 = 18;
  const NUM_33 = 33;
  const NUM_43 = 43;
  const NUM_4 = 4;
  const NUM_6 = 6;

  const { data: userData } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const instituteTypes = userData?.data?.instituteName ?? [];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { mutateAsync } = useMutation(getProductDetailsFromQRScan);

  const onSubmit = useCallback(
    async (scanDetails: any) => {
      const rawData = scanDetails[0].rawValue.replaceAll("\u001d"," ").trim();
      const qrSN = rawData.substring(NUM_18, NUM_33);
      const qrLotNumber = rawData.substring(NUM_43);
      setSerialNumberStatus({ isused: false, isNotExist: false });
      const result = await mutateAsync({
        serialNumber: qrSN,
        lotNumber: qrLotNumber,
      });
      let selDrug: DRUG_INFO = {
        drugId: -1,
        serialNumber: "",
        lotNumber: "",
        expiryDate: "",
      };
      if (result.success) {
        setScannedDataValue(qrSN);
        if (
          result.data &&
          result.data.isAvailable !== undefined &&
          !result.data.isAvailable
        ) {
          setSerialNumberStatus({ isused: false, isNotExist: true });
          selDrug = {
            drugId: -1,
            serialNumber: qrSN,
            lotNumber: qrLotNumber,
            expiryDate: "",
          };
        } else {
          if (result.data && result.data.Exist) {
            setSerialNumberStatus({ isused: true, isNotExist: false });
          }
          const reqData = result.data ? result.data.data[0] : null;
          selDrug = {
            drugId: reqData ? reqData.id : -1,
            serialNumber: reqData ? reqData.serialNumber : "",
            lotNumber: reqData ? reqData.lotNumber : "",
            expiryDate: reqData ? reqData.expiryDate : "",
          };
        }
      }
      setScannedDetails(selDrug);
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Scan_Product,
      });
    },
    [mutateAsync]
  );

  const onSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const temp: number = parseInt(e.target.value);
    setSerialNumberStatus({ isused: false, isNotExist: false });
    checkDrugDataChanged(temp, scannedDataValue);
  };

  const drugTypes = defaultSelectItems.concat(
    dbConstants?.data?.drugTypes ?? []
  );

  const handleToggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const displayDrugName = (id: number) => {
    const item = dbConstants?.data?.drugTypes.find(
      (drugItem) => drugItem.id === id
    );
    return item?.displayText;
  };

  return (
    <Grid>
      <ProductScanModal
        isOpen={isModalOpen}
        toggleModal={handleToggleModal}
        onUpdate={(err, result) => {
          if (result) {
            setIsModalOpen(false);
            onSubmit(result);
          } else setScannedDataValue(null);
        }}
      />
      <Grid container>
        {(treatmentRecord?.serialNumber === undefined ||
          treatmentRecord?.serialNumber === "") && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 5,
              marginTop: "5px",
              width: "100%",
              justifyContent: SPACE_BETWEEN,
            }}
          >
            <FormControl sx={{ width: 0.5 }}>
              <FormInput
                select
                control={control}
                name={"drugId"}
                size="small"
                disabled={
                  scannedDataValue !== null &&
                  scannedDataValue !== "" &&
                  control._formValues.drugId !== -1
                }
                onChange={onSelectChange}
              >
                {drugTypes.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.displayText}
                  </MenuItem>
                ))}
              </FormInput>
            </FormControl>

            <Grid
              item
              xs
              sm
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                textAlign: "end",
              }}
            >
              <BaseButton
                sx={{ px: 2, mb: 3 }}
                startIcon={<CameraAltOutlinedIcon />}
                onClick={() => setIsModalOpen(true)}
              >
                {t("productScan.scanQrCode")}
              </BaseButton>
            </Grid>
          </Box>
        )}
        <FormControl sx={{ width: 1 }}>
          <FormInput
            select
            control={control}
            name="treatedInstitute"
            label="Institute Name"
            sx={{ width: 1 }}
            size="small"
          >
            {instituteTypes.map((e) => (
              <MenuItem key={e} value={e}>
                {e}
              </MenuItem>
            ))}
          </FormInput>
        </FormControl>
      </Grid>
      <Grid
        item
        md
        sx={{ display: { xs: "none", sm: "none", md: "block" }, pt: 1 }}
      >
        <Typography color="primary">{t("productScan.mobileOnly")}</Typography>
      </Grid>

      {control._formValues.serialNumber && (
        <Grid item xs={12} sm={12} md={6} sx={{ paddingTop: "10px" }}>
          <Typography variant="subtitle2">
            {t("productScan.details")}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold", py: 1 }}>
            {displayDrugName(control._formValues.drugId)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: SPACE_BETWEEN,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Typography variant="body1" sx={listHeader}>
              {t("productScan.serialNumber")} :{" "}
            </Typography>
            <Typography variant="body1" sx={listValue}>
              <b>{control._formValues.serialNumber}</b>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: SPACE_BETWEEN,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Typography variant="body1" sx={listHeader}>
              {t("productScan.lotNo")} :
            </Typography>
            <Typography variant="body1" sx={listValue}>
              <b>{control._formValues.lotNumber}</b>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: SPACE_BETWEEN,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Typography variant="body1" sx={listHeader}>
              {t("productScan.expiry")} :
            </Typography>
            <Typography variant="body1" sx={listValue}>
              <b>
                {" "}
                {control._formValues.expiryDate.substring(NUM_4, NUM_6) +
                  control._formValues.expiryDate.substring(0, NUM_4)}
              </b>
            </Typography>
          </Box>
        </Grid>
      )}

      {serialNumberStatus.isNotExist && (
        <Typography color="error">{t("productScan.snNotExists")}</Typography>
      )}

      {serialNumberStatus.isused && (
        <Typography color="error">{t("productScan.snUsed")}</Typography>
      )}
    </Grid>
  );
};

export default InjectionForm;
