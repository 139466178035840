import { Typography, Grid } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import ConsentPage from "./ConsentPage";

const ConfirmCareGiverConsetPage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "InnoVision - Caregiver Consent";
  }, []);

  return (
    <Grid
      container
      item
      xs={12}
      columnSpacing={{ xs: 0, sm: 0, md: 2 }}
      rowSpacing={{ xs: 1, sm: 1, md: 2 }}
      sx={{
        padding: "1%",
      }}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Typography
          variant="h5"
          textAlign={"left"}
          sx={{
            fontWeight: "bold",
            pb: 2,
          }}
        >
          Caregiver Consent
        </Typography>

        <ConsentPage />
      </Grid>
    </Grid>
  );
};

export default ConfirmCareGiverConsetPage;
