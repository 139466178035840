import { Grid } from "@mui/material";
import LoginWebPage from "./LoginWebPage";
import LoginMobilePage from "./LoginMobilePage";
import { useEffect } from "react";

const LoginPage = () => {
  useEffect(() => {
    document.title = "InnoVision - Login";
  }, []);

  return (
    <Grid>
      <Grid item xs sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
        <LoginMobilePage></LoginMobilePage>
      </Grid>
      <Grid
        item
        sm
        md
        sx={{ display: { xs: "none", sm: "block", md: "block" } }}
      >
        <LoginWebPage></LoginWebPage>
      </Grid>
    </Grid>
  );
};

export default LoginPage;
