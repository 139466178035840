export enum ViewPeriodOptionId {
    ALL = 1,
    SIX_MONTHS,
    ONE_YEAR,
    TWO_YEARS,
  }
  
  export const VIEW_PERIOD_OPTIONS: {
    [key in ViewPeriodOptionId]: { displayText: string; value: number };
  } = {
    [ViewPeriodOptionId.ALL]: {
      displayText: "singlePharse.all",
      value: -1,
    },
    [ViewPeriodOptionId.SIX_MONTHS]: {
      displayText: "patientDetailPage.cstvaChart.6months",
      value: 6,
    },
    [ViewPeriodOptionId.ONE_YEAR]: {
      displayText: "patientDetailPage.cstvaChart.1year",
      value: 12,
    },
    [ViewPeriodOptionId.TWO_YEARS]: {
      displayText: "patientDetailPage.cstvaChart.2years",
      value: 24,
    },
  };
  
  export enum DatasetIndex {
    CST,
    VA,
    DRUG,
  }
  