import { ChartData } from "chart.js";
import { GraphDataItem } from "../types";
import { DatasetDisplayState } from ".";
import syringePng from "../../../assets/images/PatientDetailPage/syringe-with-medication.png";
import { isMobile } from "react-device-detect";

interface GetGraphDataArgs {
  periodFilteredInputData: GraphDataItem[];
  datasetDisplayState: DatasetDisplayState;
}
export const getGraphData = ({
  periodFilteredInputData,
  datasetDisplayState,
}: GetGraphDataArgs): ChartData<"line", GraphDataItem[], unknown> => {
  const maxVa = Math.max(
    ...periodFilteredInputData.map((e) => (e.va === null ? 0 : e.va)),
  );

  const NUM_5 = 5;
  const NUM_7 = 7;
  const NUM_8 = 8;
  const NUM_10 = 10;
  const NUM_50 = 50;
  const NUM_0_1 = 0.1;
  const NUM_25 = 25;

  return {
    datasets: [
      {
        label: "CST",
        yAxisID: "cst",
        data: periodFilteredInputData.filter((d) => d.cst !== null),
        parsing: {
          xAxisKey: "date",
          yAxisKey: "cst",
        },
        hidden: !datasetDisplayState.cst,
        borderColor: "rgba(12,65,205,1)",
        backgroundColor: "rgba(12,65,205,1)",
        pointRadius: isMobile ? NUM_5 : NUM_7,
        pointHoverRadius: isMobile ? NUM_8 : NUM_10,
        pointHoverBorderColor: "rgba(12,65,205,0.7)",
        pointHoverBackgroundColor: "rgba(12,65,205,0.7)",
      },
      {
        label: "VA",
        yAxisID: "va",
        data: periodFilteredInputData.filter((d) => d.va !== null),
        parsing: {
          xAxisKey: "date",
          yAxisKey: "va",
        },
        hidden: !datasetDisplayState.va,
        borderColor: "rgba(242,55,52,1)",
        backgroundColor: "rgba(242,55,52,1)",
        pointRadius: isMobile ? NUM_5 : NUM_7,
        pointHoverRadius: isMobile ? NUM_8 : NUM_10,
        pointHoverBorderColor: "rgba(242,55,52,0.7)",
        pointHoverBackgroundColor: "rgba(242,55,52,0.7)",
      },
      {
        label: "Injection",
        yAxisID: "va",
        data: periodFilteredInputData.map((d) => ({
          ...d,
          drugValue: d.drugId === null ? null : maxVa * NUM_0_1,
        })),
        parsing: {
          xAxisKey: "date",
          yAxisKey: "drugValue",
        },
        pointStyle: () => {
          const image = isMobile ? new Image(NUM_25, NUM_25) : new Image(NUM_50, NUM_50);
          image.src = syringePng;
          return image;
        },
        borderColor: "transparent",
      },
    ],
  };
};
