import {
  Grid,
  Typography,
  Box,
  Divider,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { PatientDetails } from "../../../../utils/types/services/patients";
import SideDrawer from "../../side-drawer";
import ReactGA from "react-ga4";
import { useEffect, useState, useCallback } from "react";
import { ModalViewType } from "../../../../utils/constants/ga";
import BaseButton from "../../button/BaseButton";
import { InstituteDetails } from "../../../../utils/types/services/user";
import { WorkingHours } from "../../../../utils/types/services/hcp";
import SecondaryButton from "../../button/SecondaryButton";
import BaseCheckBox from "../../checkbox/BaseCheckBox";
import { TIMES } from "../../../../utils/constants/timeRelated";

interface UpdateInstituteInfoProps {
  isOpen: boolean;
  handleClose: () => void;
  instDet: InstituteDetails[];
  updateIndex: number;
  handleUpdateSave: (details: InstituteDetails, index: number) => void;
}

const defaultStartTime = "9:00 AM";
const defaultEndTime = "5:00 PM";

const UpdateInstituteInfo = (props: UpdateInstituteInfoProps) => {
  const { isOpen, handleClose, instDet, updateIndex, handleUpdateSave } = props;
  const reqDetails = instDet ? instDet[updateIndex] : null;

  const { t } = useTranslation();

  const headerText = "Update Business Hours";
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCloseDrawer = () => {
    handleClose();
  };
  const setStartHours = (det: string) => {
    if (det !== null && det !== "Closed") {
      let start = det.split("to")[0];
      return start.trim();
    }
    return "9:00 AM";
  };
  const setEndHours = (det: string) => {
    if (det !== null && det !== "Closed") {
      let end = det.split("to")[1];
      return end.trim();
    }
    return "5:00 PM";
  };

  const [workingHours, setWorkingHours] = useState<{
    sunday: WorkingHours;
    monday: WorkingHours;
    tuesday: WorkingHours;
    wednesday: WorkingHours;
    thursday: WorkingHours;
    friday: WorkingHours;
    saturday: WorkingHours;
  }>({
    sunday: { isOpen: false, start: "", end: "" },
    monday: { isOpen: false, start: "", end: "" },
    tuesday: { isOpen: false, start: "", end: "" },
    wednesday: { isOpen: false, start: "", end: "" },
    thursday: { isOpen: false, start: "", end: "" },
    friday: { isOpen: false, start: "", end: "" },
    saturday: { isOpen: false, start: "", end: "" },
  });

  const [isTabClicked, setIsTabClicked] = useState(false);

  useEffect(() => {
    if (isOpen) {
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.PatientModal}/${headerText}`,
      });
      if (reqDetails) {
        let tempChecked = false;
        if (
          reqDetails.Sunday === "Closed" &&
          reqDetails.Monday === "Closed" &&
          reqDetails.Tuesday === "Closed" &&
          reqDetails.Wednesday === "Closed" &&
          reqDetails.Thursday === "Closed" &&
          reqDetails.Friday === "Closed" &&
          reqDetails.Saturday === "Closed"
        ) {
          tempChecked = true;
        }
        setIsChecked(
          reqDetails.hideHours ? reqDetails.hideHours || tempChecked : false
        );
        setWorkingHours({
          sunday: {
            isOpen:
              reqDetails &&
              reqDetails.Sunday !== null &&
              reqDetails.Sunday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails.Sunday),
            end: setEndHours(reqDetails.Sunday),
          },
          monday: {
            isOpen:
              reqDetails &&
              reqDetails.Monday !== null &&
              reqDetails.Monday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails?.Monday),
            end: setEndHours(reqDetails?.Monday),
          },
          tuesday: {
            isOpen:
              reqDetails &&
              reqDetails.Tuesday !== null &&
              reqDetails.Tuesday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails.Tuesday),
            end: setEndHours(reqDetails.Tuesday),
          },
          wednesday: {
            isOpen:
              reqDetails &&
              reqDetails.Wednesday !== null &&
              reqDetails.Wednesday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails.Wednesday),
            end: setEndHours(reqDetails.Wednesday),
          },
          thursday: {
            isOpen:
              reqDetails &&
              reqDetails.Thursday !== null &&
              reqDetails.Thursday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails.Thursday),
            end: setEndHours(reqDetails.Thursday),
          },
          friday: {
            isOpen:
              reqDetails &&
              reqDetails.Friday !== null &&
              reqDetails.Friday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails.Friday),
            end: setEndHours(reqDetails.Friday),
          },
          saturday: {
            isOpen:
              reqDetails &&
              reqDetails.Saturday !== null &&
              reqDetails.Saturday !== "Closed"
                ? true
                : false,
            start: setStartHours(reqDetails.Saturday),
            end: setEndHours(reqDetails.Saturday),
          },
        });
      }
    }
  }, [isOpen]);

  const handleToggleDay = (day: string) => {
    setWorkingHours((prevState) => ({
      ...prevState,
      [day]: {
        ...(prevState as { [key: string]: WorkingHours })[day],
        isOpen: !(prevState as { [key: string]: WorkingHours })[day].isOpen,
        start: (prevState as { [key: string]: WorkingHours })[day].isOpen
          ? "9:00 AM"
          : (prevState as { [key: string]: WorkingHours })[day].start,
        end: (prevState as { [key: string]: WorkingHours })[day].isOpen
          ? "5:00 PM"
          : (prevState as { [key: string]: WorkingHours })[day].end,
      },
    }));
    setIsTabClicked(true);
  };
  const handleTimeChange = (value: string, day: string, field: string) => {
    setTimeout(() => {
      if (value === "12:00 AM" && field === "start") {
        setWorkingHours((prevState) => ({
          ...prevState,
          [day]: {
            ...(prevState as { [key: string]: WorkingHours })[day],
            end: "1:00 AM",
          },
        }));
      }
    }, 0);
    setWorkingHours((prevState) => ({
      ...prevState,
      [day]: {
        ...(prevState as { [key: string]: WorkingHours })[day],
        [field]: value,
      },
    }));
  };

  const handleSave = () => {
    let tempDet: any = reqDetails;
    Object.entries(workingHours).map(([day, data]) => {
      let key = day.charAt(0).toUpperCase() + day.slice(1);
      tempDet[key] = data.isOpen ? data.start + " to " + data.end : "Closed";
    });
    tempDet.hideHours = isChecked;
    handleUpdateSave(tempDet, updateIndex);
    handleClose();
  };

  const setHoursForDays = (
    isChecked: boolean | undefined,
    isDefaultClosed?: boolean
  ) => {
    const checkValue = isChecked ? isChecked : false;
    return {
      isOpen: checkValue && !isDefaultClosed,
      start: defaultStartTime,
      end: defaultEndTime,
    };
  };

  const handleCheckBoxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const tempChecked = event.target.checked;
    setIsChecked(tempChecked);
    setWorkingHours({
      sunday: setHoursForDays(!tempChecked, true),
      monday: setHoursForDays(!tempChecked),
      tuesday: setHoursForDays(!tempChecked),
      wednesday: setHoursForDays(!tempChecked),
      thursday: setHoursForDays(!tempChecked),
      friday: setHoursForDays(!tempChecked),
      saturday: setHoursForDays(!tempChecked, true),
    });
  };

  useEffect(() => {
    if (isTabClicked) {
      let isOpen = false;
      Object.entries(workingHours).forEach((entry) => {
        if (entry[1].isOpen) {
          isOpen = true;
        }
      });
      setIsChecked(!isOpen);
      setIsTabClicked(false);
    }
  }, [workingHours, isTabClicked]);

  const endTimeArray = useCallback(
    (dataDay: any, day: string) => {
      let startIndex = TIMES.indexOf(dataDay.start);
      let endIndex = TIMES.indexOf(dataDay.end);
      if (startIndex >= endIndex && startIndex !== 23) {
        setTimeout(() => {
          handleTimeChange(TIMES[startIndex + 1], day, "end");
        }, 0);
      }
      if (startIndex === 23) {
        return TIMES.slice(0, startIndex);
      } else {
        return TIMES.slice(startIndex + 1);
      }
    },
    [workingHours]
  );

  return (
    <SideDrawer
      open={isOpen}
      onClose={handleCloseDrawer}
      headerText={headerText}
      handleCloseDrawerClick={handleCloseDrawer}
    >
      <Grid container flexDirection="row" sx={{ mt: 5, height: "100%" }}>
        <Box sx={{ height: "90%" }}>
          <Grid container spacing={2}>
            {Object.entries(workingHours).map(([day, data]) => (
              <Grid key={day} item xs={12} mt={2}>
                <Box display="flex" alignItems="center">
                  <Typography>
                    {day.charAt(0).toUpperCase() + day.slice(1)}
                  </Typography>
                  <Box sx={{ flexGrow: 1 }} />
                  <Switch
                    checked={data.isOpen}
                    onChange={() => handleToggleDay(day)}
                  />
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="span"
                  >
                    {data.isOpen ? "Open" : "Close"}
                  </Typography>

                  <Box sx={{ width: "10px" }} />
                  <Select
                    size="small"
                    value={data.start}
                    onChange={(e) =>
                      handleTimeChange(e.target.value as string, day, "start")
                    }
                    inputProps={{
                      id: `${day}-start-time`,
                    }}
                    sx={{ minWidth: "110px" }}
                    disabled={!data.isOpen}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: "350px" } },
                    }}
                  >
                    {TIMES.map((displayText) => (
                      <MenuItem
                        key={`${displayText}-${day}-start`}
                        value={displayText}
                      >
                        {displayText}
                      </MenuItem>
                    ))}
                    {/*[...Array(12)].map((_, index) => {
                      const hour = index + 1;
                      const displayHour =
                        hour === 12 ? "12:00 PM" : `${hour}:00 AM`;
                      return (
                        <MenuItem key={hour} value={`${displayHour}`}>
                          {displayHour}
                        </MenuItem>
                      );
                    })*/}
                  </Select>
                  <Box sx={{ width: "5%" }} />

                  <Select
                    size="small"
                    value={data.end}
                    onChange={(e) =>
                      handleTimeChange(e.target.value as string, day, "end")
                    }
                    inputProps={{
                      id: `${day}-end-time`,
                    }}
                    sx={{ minWidth: "110px" }}
                    disabled={!data.isOpen}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: "350px" } },
                    }}
                  >
                    {endTimeArray(data, day).map((displayText) => (
                      <MenuItem
                        key={`${displayText}-${day}-end`}
                        value={displayText}
                      >
                        {displayText}
                      </MenuItem>
                    ))}
                    {/*[...Array(12)].map((_, index) => {
                      const hour = index + 1;
                      const displayHour =
                        hour === 12 ? "12:00 AM" : `${hour}:00 PM`;
                      return (
                        <MenuItem key={hour} value={`${displayHour}`}>
                          {displayHour}
                        </MenuItem>
                      );
                    })*/}
                  </Select>
                </Box>
              </Grid>
            ))}
            <Grid container flexDirection={"row"} sx={{ pt: 1 }}>
              <Grid>
                <BaseCheckBox
                  checked={isChecked}
                  onChange={handleCheckBoxChange}
                />
              </Grid>
              <Grid xs item>
                <Typography
                  sx={{ textAlign: "left", pt: 1.2 }}
                  variant="subtitle1"
                >
                  Hide institute working hours in Patient App.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Divider sx={{ my: 1, width: "100%" }} />
          <Grid item>
            <SecondaryButton onClick={handleClose} sx={{ mr: 2 }}>
              Cancel
            </SecondaryButton>
            <BaseButton onClick={handleSave}>Done</BaseButton>
          </Grid>
        </Grid>
      </Grid>
    </SideDrawer>
  );
};

export default UpdateInstituteInfo;
