import { CSTAndVAStatus } from "../types/services/patients";

export const STATUS_STRING = {
  [CSTAndVAStatus.Improved]: `🟢 ${CSTAndVAStatus.Improved}`,
  [CSTAndVAStatus.Stable]: `🔵 ${CSTAndVAStatus.Stable}`,
  [CSTAndVAStatus.Worsened]: `🟠 ${CSTAndVAStatus.Worsened}`,
  [CSTAndVAStatus.Exacerbated]: `🔴 ${CSTAndVAStatus.Exacerbated}`,
} as const;

export const chipColorMap = {
  [CSTAndVAStatus.Improved]: "#008000",
  [CSTAndVAStatus.Stable]: "#0066CC",
  [CSTAndVAStatus.Worsened]: "#FFA500",
  [CSTAndVAStatus.Exacerbated]: "red",
} as const;
