import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../../services/dbConstants";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";

const defaultSelectItems = [
  {
    id: -1,
    displayText: "All",
  },
];

interface PatientListOptionBarProps {
  selectedDiseaseType: number;
  setSelectedDiseaseType: Dispatch<SetStateAction<number>>;
  handleAddPatientClick: () => void;
}

const PatientListOptionBar = (props: PatientListOptionBarProps) => {
  const { selectedDiseaseType, setSelectedDiseaseType, handleAddPatientClick } =
    props;

  const { t } = useTranslation();
  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const diseaseTypeSelectItems = defaultSelectItems.concat(
    dbConstants?.data?.diseaseTypes ?? []
  );

  const handleDiseaseTypeSelect = useCallback((event: SelectChangeEvent) => {
    setSelectedDiseaseType(+event.target.value);
    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Table_Filter,
      value: +event.target.value,
    });
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: 2.8, py: 0.5 }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="subtitle2">
          {t("patientListingPage.filterBy")}
        </Typography>

        <FormControl sx={{ ml: 0.5, mt: 0, mb: 0 }} size="small">
          <InputLabel id="disease-type">
            {t("singlePharse.diseaseType")}
          </InputLabel>
          <Select
            labelId="disease-type"
            value={selectedDiseaseType.toString()}
            label="Disease Type"
            sx={{ width: 150 }}
            onChange={handleDiseaseTypeSelect}
          >
            {diseaseTypeSelectItems.map(({ id, displayText }) => (
              <MenuItem key={id} value={id}>
                {displayText}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <BaseButton onClick={handleAddPatientClick}>
        {t("patientListingPage.addPatient")}
      </BaseButton>
    </Grid>
  );
};

export default PatientListOptionBar;
