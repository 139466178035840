import { Box } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useOutlet } from "react-router-dom";
import { userGetData } from "../../services/user";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";

const ProtectedHCPLayer = () => {
  const outlet = useOutlet();
  const { data, isSuccess, isLoading } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );
  const STATUS_401 = 401;

  if (isLoading) return <div></div>;

  if (isSuccess) {
    if (data.status === STATUS_401)
      return <Navigate to={ROUTE_PATHS.LOGIN} replace />;
    if (data.data?.role === "admin")
      return <Navigate to={ROUTE_PATHS.ADMININIT} replace />;
    if (data.data?.role === "IT Admin")
      return <Navigate to={ROUTE_PATHS.ADMINITINIT} replace />;
  }

  return (
    <Box
      component="main"
      sx={{
        backgroundColor: "background",
        p: 3,
        minHeight: "100%",
        height: "fit-content",
      }}
    >
      {outlet}
    </Box>
  );
};

export default ProtectedHCPLayer;
