import { useTranslation } from "react-i18next";
import { Grid, Typography, Link } from "@mui/material";

const Disclaimer = () => {
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.disclaimer.body1")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.disclaimer.body2")}
        <Link href="#">{t("policyText.disclaimer.body3")}</Link>
        {t("policyText.disclaimer.body4")}
      </Typography>
    </Grid>
  );
};

export default Disclaimer;
