import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import SimpleModal from "../../components/shareComponents/modal/SimpleModal";
import { DRAWER_WIDTH } from "../../Layouts/constants";
import { useCallback, useState } from "react";
import PrivacyPolicy from "../../Layouts/declarationBody/PrivacyPolicy";
import Termsconditions from "../../Layouts/declarationBody/Termsconditions";
import Disclaimer from "../../Layouts/declarationBody/Disclaimer";

const initState = {
  disclaimer: false,
  termsNCond: false,
  privcyNotice: false,
} as const;

const DisclaimerPage = () => {
  const { t } = useTranslation();
  const NUM_100 = 100;
  const [modalState, setModalState] = useState(initState);

  const closeModal = useCallback(() => {
    setModalState(initState);
  }, []);

  const openModal = useCallback((state: keyof typeof initState) => {
    setModalState((val) => ({
      ...val,
      [state]: true,
    }));
  }, []);

  const openPrivatePolicy = useCallback(() => {
    closeModal();
    setTimeout(() => {
      openModal("privcyNotice");
    }, NUM_100);
  }, [closeModal, openModal]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "background.paper",
        boxShadow: 3,
        my: 5,
        p: 2,
      }}
    >
      <SimpleModal
        header={
          modalState.disclaimer
            ? t("policyText.disclaimer.title")
            : modalState.termsNCond
            ? t("policyText.termsconditions.mainTitle")
            : modalState.privcyNotice
            ? t("policyText.privacypolicy.mainTitle")
            : ""
        }
        body={
          modalState.disclaimer ? (
            <Disclaimer />
          ) : modalState.termsNCond ? (
            <Termsconditions
              closeCurrentModalOpenPrivatePolicy={openPrivatePolicy}
            />
          ) : modalState.privcyNotice ? (
            <PrivacyPolicy />
          ) : (
            ""
          )
        }
        open={
          modalState.disclaimer ||
          modalState.termsNCond ||
          modalState.privcyNotice
        }
        handleClose={closeModal}
      />
      <Grid container flexDirection="row" alignItems="center">
        <Typography variant="subtitle1">
          <b
            onClick={() => openModal("disclaimer")}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Disclaimer
          </b>
          &nbsp;&nbsp;
          <b
            onClick={() => openModal("termsNCond")}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Terms and Condition
          </b>
          &nbsp;&nbsp;
          <b
            onClick={() => openModal("privcyNotice")}
            style={{
              cursor: "pointer",
              textDecoration: "underline",
            }}
          >
            Privacy Notice
          </b>
          &nbsp;&nbsp;If you have a suspected side effect or problem to report
          regarding one of our products, please contact or send the information
          to the Roche Patient Safety team via: (a) Email:
          hong_kong.drug_safety@roche.com, or (b) Phone: +852 2733 4711.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default DisclaimerPage;
