import { Control } from "react-hook-form";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import TreatmentOtherInfoRadioInput from "./TreatmentOtherInfoRadioInput";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, IconButton, Stack, Typography, Box } from "@mui/material";
import { useCallback, useState } from "react";
import SecondaryButton from "../../../shareComponents/button/SecondaryButton";
import CustomWebcam from "../../../shareComponents/CustomWebCam"; // import it
import CameraAltOutlinedIcon from "@mui/icons-material/CameraAltOutlined";
import AttachFileOutlinedIcon from "@mui/icons-material/AttachFileOutlined";
import { styled } from "@mui/material/styles";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

interface AdditionalDetailsFormProps {
  imageFiles: File[];
  imageEditFile: string | null;
  removeImage: (id: string) => void;
  onDropImage: (files: File[]) => void;
  onCaptureImage: (img: string | null) => void;
  control: Control<TreatmentRecordFormState>;
}

const AdditionalDetailsForm = (props: AdditionalDetailsFormProps) => {
  const {
    control,
    imageFiles,
    removeImage,
    onDropImage,
    onCaptureImage,
    imageEditFile,
  } = props;
  const NUM_1024 = 1024;
  const NUM_10 = 10;

  const [openCamera, setOpenCamera] = useState(false);
  const [fileName, setFileName] = useState<string>(
    imageEditFile ? imageEditFile : ""
  );

  const onDropImagefiles = (files: File[]) => {
    setOpenCamera(false);
    setFileName(files[0].name);
    onDropImage(files);
  };

  const handleCameraButton = () => {
    setOpenCamera(true);
    removeImage(fileName);
  };

  const removeCapture = () => {
    setOpenCamera(false);
    onCaptureImage(null);
  };

  const filePreview = useCallback(
    ({ name, size }: { name: string; size: number | null }, index: number) => (
      <Grid
        key={index}
        container
        sx={{
          width: 1,
          pb: 2,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Stack
            direction="row"
            spacing={2}
            sx={{ color: "#0B41CD" }}
            alignItems={"center"}
          >
            <AttachFileOutlinedIcon />
            <Typography variant="subtitle1">{name}</Typography>
            <Typography variant="subtitle1">
              {size !== null &&
                `${(size / (NUM_1024 * NUM_1024)).toFixed(1)}MB`}
            </Typography>
          </Stack>
          <IconButton
            onClick={() => removeImage(name)}
            sx={{ justifyItems: "flex-end" }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
      </Grid>
    ),
    [removeImage]
  );

  return (
    <Stack>
      <TreatmentOtherInfoRadioInput
        label="HEME"
        name="heme"
        control={control}
      />
      <TreatmentOtherInfoRadioInput label="PED" name="ped" control={control} />
      <TreatmentOtherInfoRadioInput
        label="IRF/IRC"
        name="irf"
        control={control}
      />
      <TreatmentOtherInfoRadioInput label="SRF" name="srf" control={control} />

      <Typography variant="subtitle1" sx={{ pb: 2 }}>
        OCT Image
      </Typography>

      <Stack spacing={1}>
        {imageFiles.map(filePreview)}
        {imageEditFile &&
          filePreview({ name: imageEditFile, size: null }, NUM_10)}
      </Stack>
      <Grid
        item
        sx={{ width: "100%" }}
        display={{ sx: "block", xs: "block", sm: "none" }}
      >
        {openCamera && (
          <Box
            sx={{
              background:
                "linear-gradient(0deg, rgba(0, 102, 204, 0.05), rgba(0, 102, 204, 0.05)), #FFFFFF;",
              mb: 5,
            }}
          >
            <Grid sx={{ pr: 2 }} container item justifyContent="flex-end">
              <IconButton onClick={() => removeCapture()}>
                <ClearIcon />
              </IconButton>
            </Grid>
            <CustomWebcam
              width="100%"
              height="100%"
              onCapture={onCaptureImage}
            />
          </Box>
        )}
      </Grid>

      <Grid
        item
        xs={12}
        container
        flexDirection="row"
        alignItems={"center"}
        justifyContent={"flex-start"}
        sx={{ pb: 5 }}
      >
        <SecondaryButton
          component="label"
          endIcon={<InsertDriveFileOutlinedIcon />}
          sx={{ mr: 2, color: "#000000", borderColor: "#000000" }}
        >
          Upload file
          <VisuallyHiddenInput
            type="file"
            accept="image/*"
            onChange={(e: any) => onDropImagefiles([...e.target.files])}
          />
        </SecondaryButton>

        <SecondaryButton
          sx={{ mr: 2, color: "#000000", borderColor: "#000000" }}
          endIcon={<CameraAltOutlinedIcon />}
          onClick={() => handleCameraButton()}
        >
          Capture Image
        </SecondaryButton>
      </Grid>
    </Stack>
  );
};

export default AdditionalDetailsForm;
