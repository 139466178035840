import { Grid, Typography } from "@mui/material";
import { CSTAndVAStatus } from "../../../utils/types/services/patients";
import CircleIcon from "@mui/icons-material/Circle";
import { chipColorMap } from "../../../utils/constants/cst-va-status-string";
import * as React from "react";

interface CSTAndVAStatusItemProps {
  label: string;
  value: string | number | null;
  status: keyof typeof CSTAndVAStatus | null;
}

const CSTAndVAStatusItem = (props: CSTAndVAStatusItemProps) => {
  const { label, value, status } = props;

  return (
    <Grid container columnSpacing={1} alignItems="center">
      <Grid container item xs={12}>
        <Typography variant="subtitle2" color="text.secondary">
          {label}
        </Typography>
      </Grid>
      <Grid container item xs={5}>
        <Typography variant="subtitle1" fontWeight="bold">
          {value ? value : "-"}
        </Typography>
      </Grid>
      <Grid container item xs alignItems="center">
        {status !== null && (
          <React.Fragment>
            <CircleIcon
              sx={{ fontSize: 10, mr: 0.6, color: chipColorMap[status] }}
            />
            <Typography variant="subtitle2" fontWeight="bold">
              {status}
            </Typography>
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
};

export default CSTAndVAStatusItem;
