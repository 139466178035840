import { Pagination } from "@mui/material";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => void;
}

const TablePaginationActions = (props: TablePaginationActionsProps) => {
  const { count, page, rowsPerPage, onPageChange } = props;
  const NUM_10 = 10;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    e_page: number
  ) => {
    onPageChange(null, e_page - 1);
  };

  return (
    <Pagination
      page={page + 1}
      count={Math.ceil(count / rowsPerPage)}
      boundaryCount={2}
      siblingCount={count > NUM_10 ? 1 : 0}
      onChange={handlePageChange}
      sx={{
        p: 2,
        display: "flex",
        justifyContent: "start",
        minWidth: "30%",
      }}
    />
  );
};

export default TablePaginationActions;
