import { useQuery } from "@tanstack/react-query";
import { Navigate, useOutlet } from "react-router-dom";
import { userGetData } from "../../services/user";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";
import { Box } from "@mui/material";
import { isMobile } from "react-device-detect";

const HomeLayout = () => {
  const outlet = useOutlet();
  const { data, isSuccess, isLoading } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  if (isLoading) return <div></div>;

  if (isSuccess) {
    if (data.data?.role === "HCP") {
      let routeToRedirect: string = "";
      const initPath: string | null = localStorage.getItem("initPath");
      if (initPath !== null && initPath.indexOf("patientdetails") >= 0) {
        routeToRedirect = initPath;
      } else {
        routeToRedirect = isMobile ? ROUTE_PATHS.PATIENTS : ROUTE_PATHS.HCPINIT;
      }
      setTimeout(() => {
        localStorage.setItem("initPath", "");
      }, 0);

      return <Navigate to={routeToRedirect} replace />;
    }
    if (data.data?.role === "admin")
      return <Navigate to={ROUTE_PATHS.ADMININIT} replace />;
    if (data.data?.role === "IT Admin")
      return <Navigate to={ROUTE_PATHS.ADMINITINIT} replace />;
  }

  return <Box component="main">{outlet}</Box>;
};

export default HomeLayout;
