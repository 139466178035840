import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Grid, Typography } from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormProvider, useForm, SubmitErrorHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import {
  patientAddOne,
  checkPatientExist,
  requestPatientAccess,
  updatePatient,
} from "../../../../../services/patient";
import {
  PatientDetails,
  PatientFormState,
  RequestAccessProps,
} from "../../../../../utils/types/services/patients";
import { PatientModalMode } from "../../../../../utils/types/ui/patientModalMode";
import BaseButton from "../../../button/BaseButton";
import TabView, { TabItem } from "../../../tabView";
import MedicalDetailsForm from "./MedicalDetailsForm";
import PatientPersonalDetailsForm from "./PatientPersonalDetailsForm";
import DeletePatientModal from "./DeletePatientModal";
import useModal from "../../../../../hooks/useModal";
import nameYup, {
  nameOptionalYup,
} from "../../../../../utils/helperFunction/yupValidations/name";
import {
  mobileLocalYup,
  mobileNotRequiredYup,
} from "../../../../../utils/helperFunction/yupValidations/mobile";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ReactGA from "react-ga4";
import {
  CategoryType,
  FormType,
  SubtleType,
} from "../../../../../utils/constants/ga";
import { REACT_QUERY_KEYS } from "../../../../../utils/constants/reactQueryKeys";
import { mobileNumberPrefix } from "../../../../../utils/constants/general";
import SimpleModal from "../../SimpleModal";
import { useNavigate } from "react-router-dom";
import SecondaryButton from "../../../button/SecondaryButton";
import { timeLimits } from "../../../../../utils/constants/timeRelated";
import { ROUTE_PATHS } from "../../../../../utils/constants/routes/webPaths";
import RequestAccessScreen from "../RequestAccessScreen";
import _ from "lodash";
import CaregiverDetailsForm from "./CaregiverDetailsForm";

const MAX_50 = "required max input 50 characters";
const INVALID_NUMBER = "invalid number";
const REQ_INTEGER = "required integer";
const NUM_50 = 50;
const NUM_12 = 12;
const NUM_31 = 31;
const NUM_1000 = 1000;
const schema = yup.object().shape(
  {
    recognitionId: yup
      .string()
      .when("firstName", {
        is: (firstName: string | null) =>
          firstName === "" || firstName === null,
        then: yup
          .string()
          .min(1, "required min input 1 characters")
          .max(NUM_50, MAX_50)
          .required("required"),
        otherwise: yup.string().max(NUM_50, MAX_50),
      })
      .when("lastName", {
        is: (lastName: string | null) => lastName === "" || lastName === null,
        then: yup
          .string()
          .min(1, "required min input 1 characters")
          .max(NUM_50, MAX_50)
          .required("required"),
        otherwise: yup.string().max(NUM_50, MAX_50),
      }),
    lastName: yup.string().when("recognitionId", {
      is: (recognitionId: string | null) =>
        recognitionId === "" || recognitionId === null,
      then: nameYup,
      otherwise: nameOptionalYup,
    }),
    firstName: yup.string().when("recognitionId", {
      is: (recognitionId: string | null) =>
        recognitionId === "" || recognitionId === null,
      then: nameYup,
      otherwise: nameOptionalYup,
    }),
    gender: yup.string(),
    year: yup
      .number()
      .typeError(INVALID_NUMBER)
      .integer(REQ_INTEGER)
      .min(dayjs().year() - timeLimits.maxHumanAge)
      .max(dayjs().year())
      .required("required"),
    month: yup
      .number()
      .typeError(INVALID_NUMBER)
      .integer(REQ_INTEGER)
      .min(1, "select a month")
      .max(NUM_12, "select a month")
      .required("required"),
    day: yup
      .number()
      .typeError(INVALID_NUMBER)
      .integer(REQ_INTEGER)
      .min(1, "select a day")
      .max(NUM_31, "select a day")
      .required("required"),
    mobileLocal: mobileLocalYup,
    rightEyePastInjectionCount: yup
      .number()
      .typeError(INVALID_NUMBER)
      .min(0, "require not less than 0")
      .max(NUM_1000, "require max 1000")
      .integer(REQ_INTEGER)
      .required("required"),
    leftEyePastInjectionCount: yup
      .number()
      .typeError(INVALID_NUMBER)
      .min(0, "require not less than 0")
      .max(NUM_1000, "require max 1000")
      .integer(REQ_INTEGER)
      .required("required"),
    rightEyeDiseaseTypeId: yup
      .number()
      .integer(REQ_INTEGER)
      .required("required"),
    leftEyeDiseaseTypeId: yup
      .number()
      .integer(REQ_INTEGER)
      .required("required"),
    rightEyeFirstInjectionDate: yup
      .date()
      .nullable()
      .test("test-max-date", "required not greater than today", (val) => {
        if (val === undefined || val === null) return true;
        return dayjs().endOf("D").diff(dayjs(val), "seconds") > 0;
      }),
    leftEyeFirstInjectionDate: yup
      .date()
      .nullable()
      .test("test-max-date", "required not greater than today", (val) => {
        if (val === undefined || val === null) return true;
        return dayjs().endOf("D").diff(dayjs(val), "seconds") > 0;
      }),
    caregiver1mobile: mobileNotRequiredYup,
    caregiver2mobile: mobileNotRequiredYup,
  },
  [
    ["firstName", "recognitionId"],
    ["lastName", "recognitionId"],
  ]
);

interface AddPatientFormMobileProps {
  handleClose: () => void;
  patient?: PatientDetails;
  mode: PatientModalMode;
  requestForPatientAccess: () => void;
  resetHeaderName: () => void;
}

const AddPatientFormMobile = (props: AddPatientFormMobileProps) => {
  const {
    handleClose,
    patient,
    mode,
    requestForPatientAccess,
    resetHeaderName,
  } = props;

  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const STATUS_200 = 200;
  const STATUS_201 = 201;

  const [showRequestAccess, setShowRequestAccess] = useState<boolean>(false);
  const [isPatientExists, setIsPatientExists] = useState<{
    status: boolean;
    msg: string;
  }>({ status: false, msg: "" });
  const [isAvailable, setIsAvailable] = useState<boolean>(false);

  const handleRequestForPatientAccess = () => {
    setShowRequestAccess(true);
    requestForPatientAccess();
  };
  const [accessDetails, setAccessDetails] = useState<RequestAccessProps[]>();
  const handleMobileNumberCheck = useCallback(
    async (data: string) => {
      const mobileNumber = data;
      const checkResult = await mutateAsync3({
        mobileNumber,
      });
      if (checkResult?.data?.available) {
        setIsPatientExists({
          status: true,
          msg: "* Patient exists in your List.",
        });
        setAccessDetails(checkResult?.data.data);
        setIsAvailable(true);
      } else if (checkResult?.data?.Exist) {
        setIsPatientExists({
          status: true,
          msg: "* Patient exists. Please request for patient profile access.",
        });
        setAccessDetails(checkResult?.data?.data);
        setIsAvailable(false);
      }
    },
    [patient]
  );

  const useFormMethods = useForm<PatientFormState>({
    defaultValues: {
      recognitionId: "",
      firstName: "",
      lastName: "",
      gender: "male",
      year: "",
      month: 0,
      day: 0,
      mobileLocal: "",
      mobilePrefix: mobileNumberPrefix,
      rightEyeFirstInjectionDate: null,
      leftEyeFirstInjectionDate: null,
      rightEyePastInjectionCount: 0,
      leftEyePastInjectionCount: 0,
      rightEyeDiseaseTypeId: -1,
      leftEyeDiseaseTypeId: -1,
      caregiver1mobile: "",
      caregiver1Prefix: mobileNumberPrefix,
      caregiver2mobile: "",
      caregiver2Prefix: mobileNumberPrefix,
    },
    resolver: yupResolver(schema),
    mode: "all",
  });

  const { trigger, watch, reset, formState, handleSubmit } = useFormMethods;

  useEffect(() => {
    if (!patient) return;
    reset({
      recognitionId: patient.recognitionId ?? "",
      firstName: patient.firstName ?? "",
      lastName: patient.lastName ?? "",
      gender: patient.gender,
      month: patient.month,
      day: patient.day,
      mobileLocal: patient.mobileNumber.substring(mobileNumberPrefix.length),
      mobilePrefix: patient.mobileNumber.substring(
        0,
        mobileNumberPrefix.length
      ),
      year: patient.year.toString(),
      rightEyePastInjectionCount: patient.rightEyeDetails.pastInjectionCount,
      leftEyePastInjectionCount: patient.leftEyeDetails.pastInjectionCount,
      rightEyeFirstInjectionDate: patient.rightEyeDetails.firstInjectionDate
        ? dayjs(patient.rightEyeDetails?.firstInjectionDate)
        : null,
      leftEyeFirstInjectionDate: patient.leftEyeDetails?.firstInjectionDate
        ? dayjs(patient.leftEyeDetails?.firstInjectionDate)
        : null,
      rightEyeDiseaseTypeId: patient.rightEyeDetails?.diseaseTypeId ?? -1,
      leftEyeDiseaseTypeId: patient.leftEyeDetails?.diseaseTypeId ?? -1,
      caregiver1mobile: patient.cgMob1
        ? patient.cgMob1.substring(mobileNumberPrefix.length)
        : "",
      caregiver1Prefix: patient.cgMob1
        ? patient.cgMob1.substring(0, mobileNumberPrefix.length)
        : mobileNumberPrefix,
      caregiver2mobile: patient.cgMob2
        ? patient.cgMob2.substring(mobileNumberPrefix.length)
        : "",
      caregiver2Prefix: patient.cgMob2
        ? patient.cgMob2.substring(0, mobileNumberPrefix.length)
        : mobileNumberPrefix,
    });
  }, [patient]);

  useEffect(() => {
    if (watch("firstName") === "" && watch("lastName") === "") return;
    trigger("recognitionId");
  }, [...watch(["firstName", "lastName"])]);

  useEffect(() => {
    if (watch("recognitionId") !== "") trigger(["firstName", "lastName"]);
  }, [watch("recognitionId")]);

  const { mutateAsync, isLoading } = useMutation(patientAddOne);
  const { mutateAsync: mutateAsync2, isLoading: isLoading2 } =
    useMutation(updatePatient);
  const { mutateAsync: mutateAsync3 } = useMutation(checkPatientExist);
  const { mutateAsync: mutateAsync4 } = useMutation(requestPatientAccess);

  const {
    isOpen: isPatientDeleteModalOpen,
    closeModal: closePatientDeleteModal,
    openModal: openPatientDeleteModal,
  } = useModal();

  const {
    isOpen: isConfirmCreateModalOpen,
    closeModal: closeConfirmCreateModal,
    openModal: openConfirmCreateModal,
  } = useModal();

  const [currentTab, setCurrentTab] = useState<number>(0);

  const tabs: TabItem[] = useMemo(() => {
    const errors = formState.errors;
    const personalDetailsText = t("patientModal.addPatient.personalDetails");
    const medicalDetailsText = t("patientModal.addPatient.medicalDetails");
    const careGiverDetailsText = t("patientModal.addPatient.caregiverDetails");

    return [
      {
        label:
          errors.year ||
          errors.month ||
          errors.day ||
          errors.firstName ||
          errors.lastName ||
          errors.gender ||
          errors.mobileLocal ||
          isPatientExists.status ? (
            <Typography sx={{ color: "error.main" }}>
              {personalDetailsText}
            </Typography>
          ) : (
            personalDetailsText
          ),
        component: (
          <PatientPersonalDetailsForm
            patient={patient}
            mode={mode}
            isPatientExists={isPatientExists}
            setIsPatientExists={setIsPatientExists}
            handleMobileNumberCheck={handleMobileNumberCheck}
            requestForPatientAccess={handleRequestForPatientAccess}
            isAvailable={isAvailable}
          />
        ),
      },
      {
        label:
          errors.rightEyeFirstInjectionDate ||
          errors.rightEyePastInjectionCount ||
          errors.leftEyeFirstInjectionDate ||
          errors.leftEyePastInjectionCount ||
          errors.rightEyeDiseaseTypeId ||
          errors.leftEyeDiseaseTypeId ? (
            <Typography sx={{ color: "error.main" }}>
              {medicalDetailsText}
            </Typography>
          ) : (
            medicalDetailsText
          ),
        component: <MedicalDetailsForm />,
      },
      {
        label:
          errors.caregiver1mobile || errors.caregiver2mobile ? (
            <Typography sx={{ color: "error.main" }}>
              {careGiverDetailsText}
            </Typography>
          ) : (
            careGiverDetailsText
          ),
        component: <CaregiverDetailsForm patient={patient} mode={mode} />,
      },
    ];
  }, [t, patient, formState]);

  const onSubmit = useCallback(
    async (data: PatientFormState) => {
      const addPatientResult = await mutateAsync({
        ...data,
        confirmed: isConfirmCreateModalOpen,
      });
      if (addPatientResult.success) {
        if (addPatientResult.status === STATUS_200) {
          queryClient.refetchQueries([REACT_QUERY_KEYS.PATIENTS]);
          closeConfirmCreateModal();
          handleClose();
          navigate(
            ROUTE_PATHS.PATIENT_DETAILS.replace(
              ":id",
              addPatientResult.data!.patientId.toString()
            )
          );
        } else if (addPatientResult.status === STATUS_201) {
          openConfirmCreateModal();
        }
      }
      ReactGA.event({
        category: CategoryType.FormSubmit,
        action: FormType.PatientForm,
        label: SubtleType.Add,
        value: 200,
      });
    },
    [
      handleClose,
      mutateAsync,
      openConfirmCreateModal,
      closeConfirmCreateModal,
      isConfirmCreateModalOpen,
    ]
  );

  const onSubmit2 = useCallback(
    async (data: PatientFormState) => {
      const updatePatientResult = await mutateAsync2({
        ...data,
        id: patient?.id!,
      });
      if (updatePatientResult.success) {
        queryClient.refetchQueries([REACT_QUERY_KEYS.PATIENT, patient?.id!]);
        handleClose();
      }
      ReactGA.event({
        category: CategoryType.FormSubmit,
        action: FormType.PatientForm,
        label: SubtleType.Edit,
        value: 200,
      });
    },
    [handleClose, mutateAsync2, patient?.id]
  );

  const handleSubmitError: SubmitErrorHandler<PatientFormState> = useCallback(
    (error) => {
      const fields = Object.keys(error);
      ReactGA.event({
        category: `${CategoryType.FormValidationError}_Main_Patient`,
        action: FormType.PatientForm,
        label: `${fields.flatMap((e) => e)}`,
        nonInteraction: false,
        value: 400,
      });
    },
    []
  );

  const getCurrentTabIndex = useCallback((val: number) => {
    setCurrentTab(val);
  }, []);

  const finalSubmit = handleSubmit(
    mode === "add" ? onSubmit : onSubmit2,
    handleSubmitError
  );

  const handleCancel = () => {
    resetHeaderName();
    setShowRequestAccess(false);
  };
  const handleDone = useCallback(async () => {
    if (accessDetails && accessDetails.length) {
      const patientId: number = accessDetails[0].id;
      const ownerId: number = accessDetails[0].hcp_id;
      const checkResult = await mutateAsync4({
        patientId,
        ownerId,
      });
      if (checkResult.success) {
        handleClose();
      }
    }
  }, [accessDetails]);

  return (
    <FormProvider {...useFormMethods}>
      <SimpleModal
        header={t("patientModal.addPatient.patientConsentNotGrantedTitle")}
        body={t("patientModal.addPatient.patientConsentNotGrantedBody")}
        open={isConfirmCreateModalOpen}
        handleClose={closeConfirmCreateModal}
        buttons={{
          positiveButtonIsLoading: isLoading || isLoading2,
          positiveAction: finalSubmit,
        }}
      />
      <DeletePatientModal
        isOpen={!!patient?.id && isPatientDeleteModalOpen}
        togglePatientDeleteModal={closePatientDeleteModal}
        patientId={patient?.id ?? -1}
      />
      {showRequestAccess && (
        <RequestAccessScreen
          accessDetails={accessDetails}
          handleCancel={handleCancel}
          handleDone={handleDone}
        ></RequestAccessScreen>
      )}
      {!showRequestAccess && (
        <Grid container flexDirection="row" sx={{ minHeight: "95%" }}>
          <Grid item xs={12}>
            <TabView
              tabs={tabs}
              getCurrentTabIndex={getCurrentTabIndex}
              changeCurrentTabIndexTo={currentTab}
            />
          </Grid>
          {!_.isEmpty(formState.errors) && (
            <Typography sx={{ color: "#d32f2f", pb: 2 }}>
              Please fix the errors before submitting
            </Typography>
          )}

          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Grid item>
              {patient?.id && (
                <Button
                  variant="outlined"
                  onClick={openPatientDeleteModal}
                  color="error"
                  disabled={isLoading || isLoading2}
                >
                  {t("singlePharse.delete")}
                </Button>
              )}
            </Grid>

            <Grid item>
              <SecondaryButton
                onClick={handleClose}
                sx={{ mr: 2 }}
                disabled={isLoading || isLoading2}
              >
                {t("singlePharse.cancel")}
              </SecondaryButton>
              <BaseButton
                type="submit"
                onClick={
                  mode === "add" && currentTab !== 2
                    ? () =>
                        setCurrentTab((val) => {
                          ++val;
                          return val;
                        })
                    : finalSubmit
                }
                disabled={isLoading || isLoading2}
              >
                {mode === "add" && currentTab !== 2
                  ? t("singlePharse.next")
                  : t("singlePharse.save")}
              </BaseButton>
            </Grid>
          </Grid>
        </Grid>
      )}
    </FormProvider>
  );
};

export default AddPatientFormMobile;
