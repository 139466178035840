import { Dayjs } from "dayjs";

export type PickRename<T, K extends keyof T, R extends PropertyKey> = {
  [P in R]: T[K];
};

export type PickType<T, K extends keyof T> = T[K];

export const CSTAndVAStatus = {
  Improved: "Improved",
  Stable: "Stable",
  Worsened: "Worsened",
  Exacerbated: "Exacerbated",
} as const;

interface Patient {
  id: number;
  firstName: string | null;
  lastName: string | null;
  recognitionId: string | null;
  gender: "male" | "female";
  year: number;
  month: number;
  day: number;
  mobileNumber: string;
  hasConsent: boolean;
  cgMob1?: string;
  cgMob2?: string;
  cg1Consent?: boolean;
  cg2Consent?: boolean;
}

interface DiseaseType {
  id: number;
  name: string;
}

export const eyeStatus = {
  new: "new",
  active: "active",
  inactive: "inactive",
  completed: "completed",
} as const;

export type PatientFormState = Pick<
  Patient,
  "firstName" | "lastName" | "gender" | "month" | "day" | "recognitionId"
> &
  PickRename<DiseaseType, "id", "rightEyeDiseaseTypeId"> &
  PickRename<DiseaseType, "id", "leftEyeDiseaseTypeId"> & {
    year: string | "";
    rightEyeFirstInjectionDate: Dayjs | null;
    leftEyeFirstInjectionDate: Dayjs | null;
    rightEyePastInjectionCount: number;
    leftEyePastInjectionCount: number;
    mobileLocal: string;
    mobilePrefix: string;
    caregiver1mobile: string;
    caregiver1Prefix: string;
    caregiver2mobile: string;
    caregiver2Prefix: string;
  };

export type PatientsProps = Pick<
  Patient,
  | "id"
  | "firstName"
  | "lastName"
  | "gender"
  | "recognitionId"
  | "cgMob1"
  | "cgMob2"
> & {
  diseaseTypeOS: PickType<DiseaseType, "id"> | null;
  diseaseTypeOD: PickType<DiseaseType, "id"> | null;
  diseaseTypeNameOS: PickType<DiseaseType, "name"> | null;
  diseaseTypeNameOD: PickType<DiseaseType, "name"> | null;
  lastTreatmentDate: string | null;
  owner: string;
  organization: string;
  hcp: number;
  hasConsent: boolean;
};

export interface TreatmentRecord {
  id: number;
  date: string;
  diseaseTypeId: number | null;
  drugId: number | null;
  drugName: string | null;
  cst: number | null;
  cstStatus: keyof typeof CSTAndVAStatus | null;
  va: any;
  vaStatus: keyof typeof CSTAndVAStatus | null;
  heme: boolean | null;
  ped: boolean | null;
  irf: boolean | null;
  srf: boolean | null;
  editImage: string | null;
  octMachine: number | null;
  treatmentInterval: string;
  vaSnellen: number | null;
  recordStatus: boolean;
  latestHeme: boolean | null;
  latestPed: boolean | null;
  latestIrf: boolean | null;
  latestSrf: boolean | null;
  latestCst: number | null;
  latestVa: number | null;
  letterScoreDesc: string | null;
  latestLetterScoreDesc: string | null;
  serialNumber: string;
  lotNumber: string;
  expiryDate: string;
  treatedInstitute?: string | null;
}

interface NextTreatmentRecommendation {
  basedOn: number;
  suggestInterval: string | null;
  suggestDate: string | null;
  hints: string | null;
}

export type EyeDetailsProps = {
  id: number;
  diseaseTypeId: PickType<DiseaseType, "id"> | null;
  diseaseType: PickType<DiseaseType, "name"> | null;
  firstInjectionDate: string | null;
  pastInjectionCount: number;
  status: keyof typeof eyeStatus | null;
  calStatus: keyof typeof eyeStatus | null;
  fixedWeeks: number | null;
  treatmentPhaseIdSelected: number | null;
  recommendation: NextTreatmentRecommendation[];
};

export type PatientDetails = {
  rightEyeDetails: EyeDetailsProps;
  leftEyeDetails: EyeDetailsProps;
  rightEyeTreatmentRecords: TreatmentRecord[];
  leftEyeTreatmentRecords: TreatmentRecord[];
  Owner?: number;
} & Patient;

export type RequestAccessProps = {
  id: number;
  pFirstName: string | null;
  pLastName: string | null;
  mobile: string;
  pRecogId: string;
  hcp_id: number;
  first_name: string;
  last_name: string;
  preferred_name: string;
  avatar_url: string | null;
  institute_name: string;
};

export type RequestPatientAccessProps = {
  available?: boolean;
  Exist?: boolean;
  data?: RequestAccessProps[];
};
