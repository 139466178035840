import { useTheme, Grid, Typography } from "@mui/material";
import ReactLoading from "react-loading";

const CenterLoader = () => {
  const theme = useTheme();
  return (
    <Grid
      container
      item
      sx={{ height: "100%" }}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item sx={{ width: "10vmin", height: "10vmin" }}>
        <ReactLoading
          type={"spin"}
          color={theme.palette.primary.main}
          height={"100%"}
          width={"100%"}
        />
      </Grid>
      <Typography variant="h2" fontWeight={"bold"}>
        InnoVision
      </Typography>
    </Grid>
  );
};

export default CenterLoader;
