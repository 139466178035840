import { Box, Toolbar, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useOutlet } from "react-router-dom";
import { userGetData } from "../../services/user";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";
import Footer from "../Footer";
import Appbar from "../Appbar";
import SideBar from "../SideBar";
import { Theme } from "@mui/material/styles";

const mainStyles = (theme: Theme) =>
  ({
    display: "flex",
    minHeight: "100%",
    pb: "7vh",
    [theme.breakpoints.down("sm")]: {
      pb: "1vh",
    },
  } as const);

const layerStyles = (theme: Theme) =>
  ({
    flexGrow: 1,
    p: 3,
    minHeight: "100%",
    height: "fit-content",
    [theme.breakpoints.down("sm")]: {
      pt: 1.5,
    },
  } as const);

const ProtectedHCPLayer = () => {
  const outlet = useOutlet();
  const { data, isSuccess, isLoading } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );
  const STATUS_401 = 401;

  if (isLoading) return <div></div>;

  if (isSuccess) {
    if (data.status === STATUS_401) {
      localStorage.setItem("initPath", window.location.pathname);
      return <Navigate to={ROUTE_PATHS.LOGIN} replace />;
    }
    if (data.data?.role === "admin")
      return <Navigate to={ROUTE_PATHS.ADMININIT} replace />;
    if (data.data?.role === "IT Admin")
      return <Navigate to={ROUTE_PATHS.ADMINITINIT} replace />;
  }

  return (
    <Box sx={{ minHeight: "100%", backgroundColor: "background" }}>
      <Box sx={mainStyles}>
        <Appbar />
        <Grid display={{ sm: "none", xs: "none", md: "block" }}>
          <SideBar />
        </Grid>
        <Box component="main" sx={layerStyles}>
          <Toolbar />
          {outlet}
        </Box>
      </Box>
      <Grid display={{ sm: "none", xs: "none", md: "block" }}>
        <Footer />
      </Grid>
    </Box>
  );
};

export default ProtectedHCPLayer;
