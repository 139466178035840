import {
  Grid,
  Typography,
  FormControlLabel,
  FormLabel,
  Checkbox,
  FormGroup,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { SyntheticEvent, useCallback, useMemo, useState } from "react";
import BaseButton from "../../shareComponents/button/BaseButton";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";

export interface DiseaseTypeProps {
  nAMD: boolean;
  PCV: boolean;
  DME: boolean;
  RVO: boolean;
  mCNV: boolean;
  others: boolean;
}

export type FilterValueProps = {
  diseaseType: DiseaseTypeProps;
};

interface FilterOptionsPopoverProps {
  applyFilter: (apply: boolean, data: FilterValueProps | null) => void;
  value: FilterValueProps;
}

const FilterOptionsPopover = (props: FilterOptionsPopoverProps) => {
  const { applyFilter, value: initValue } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState<FilterValueProps>(initValue);

  const handleToggleAllCheckBox = useCallback(
    (event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setValue((val) => ({
        ...val,
        diseaseType: {
          nAMD: checked,
          PCV: checked,
          DME: checked,
          RVO: checked,
          mCNV: checked,
          others: checked,
        },
      }));
    },
    [],
  );

  const handleDiseaseTypeCheckBox = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      checked: boolean,
      diseaseType: keyof typeof value.diseaseType,
    ) => {
      setValue((val) => ({
        ...val,
        diseaseType: {
          ...val.diseaseType,
          [diseaseType]: checked,
        },
      }));
    },
    [],
  );

  const allChecked = useMemo(() => {
    const diseaseTypeArray = Object.values(value.diseaseType);
    return !diseaseTypeArray.some((e) => e === false);
  }, [value.diseaseType]);

  return (
    <Grid container sx={{ minWidth: 300, maxWidth: 500, p: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ p: 2 }}>
          {t("dashboardPage.filters")}
        </Typography>
      </Grid>

      <FormGroup>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <FormLabel id="radio-buttons-group-label">
              {t("singlePharse.diseaseType")}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label={t("dashboardPage.allDiseaseTypes")}
              checked={allChecked}
              onChange={handleToggleAllCheckBox}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="nAMD"
              checked={value.diseaseType.nAMD}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "nAMD")
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="PCV"
              checked={value.diseaseType.PCV}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "PCV")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="DME"
              checked={value.diseaseType.DME}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "DME")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="RVO"
              checked={value.diseaseType.RVO}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "RVO")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="mCNV"
              checked={value.diseaseType.mCNV}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "mCNV")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="Others"
              checked={value.diseaseType.others}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "others")
              }
            />
          </Grid>
        </Grid>
      </FormGroup>

      <Grid container item xs={12} direction="row-reverse" sx={{ mt: 1 }}>
        <BaseButton sx={{ ml: 1 }} onClick={() => applyFilter(true, value)}>
          {t("singlePharse.apply")}
        </BaseButton>
        <SecondaryButton onClick={() => applyFilter(false, null)}>
          {t("singlePharse.cancel")}
        </SecondaryButton>
      </Grid>
    </Grid>
  );
};

export default FilterOptionsPopover;
