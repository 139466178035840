import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Page from "./Page";
import PageMobile from "./PageMobile";
import { useEffect } from "react";

const PTIReferencePage = () => {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "InnoVision - Posology Reference";
  }, []);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        padding: "1%",
      }}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      <Grid item xs={12}>
        <Typography
          variant="h5"
          textAlign={"left"}
          sx={{
            fontWeight: "bold",
          }}
        >
          {t("pTIReferencePage.header")}
        </Typography>
      </Grid>
      <Grid
        item
        md
        sm
        sx={{ display: { xs: "none", sm: "block", md: "block" } }}
      >
        <Page />
      </Grid>
      <Grid item xs sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
        <PageMobile />
      </Grid>
    </Grid>
  );
};

export default PTIReferencePage;
