import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

type SecondaryButtonProps = ButtonProps;

const SecondaryButton = styled(({ ...props }: SecondaryButtonProps) => (
  <Button {...props} variant={props.variant ?? "outlined"} />
))({});

export default SecondaryButton;
