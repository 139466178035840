import { Typography, Modal, Grid } from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { ModalViewType } from "../../../utils/constants/ga";
import dayjs from "dayjs";
import ModalBoldTitle from "../../shareComponents/modal/BoldTitle";

const style = {
  width: 500,
  minHeight: "60vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
} as const;

const MAX_CONTENT = "max-content";

interface modalData {
  img: string;
  header: string;
  body: string;
}

const SubModal = ({
  data,
  open,
  toggleModal,
}: {
  data: modalData;
  open: boolean;
  toggleModal: () => void;
}) => {
  const { img, header, body } = data;

  const dateBody = dayjs(body).isValid()
    ? dayjs(body).format("DD MMM YYYY")
    : body;

  return (
    <Modal
      hideBackdrop
      open={open}
      onClose={toggleModal}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      sx={{
        position: "absolute",
        left: "calc(50% + 25px)",
        top: "20%",
        transform: "translate(-20%, -calc(50% + 25px))",
        width: MAX_CONTENT,
        height: MAX_CONTENT,
      }}
    >
      <Grid container sx={style}>
        <ModalBoldTitle title={header} />

        <img
          alt="oct-image2"
          src={img}
          loading="lazy"
          style={{ width: "100%", objectFit: "cover" }}
        />
        <Typography id="modal-modal-description" variant="body2" sx={{ mt: 2 }}>
          {dateBody}
        </Typography>
      </Grid>
    </Modal>
  );
};

const OctImagesModal = ({
  data,
  open,
  toggleModal,
}: {
  data: modalData[];
  open: boolean;
  toggleModal: () => void;
}) => {
  useEffect(() => {
    if (open)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.OctImagesModal}`,
      });
  }, [open]);

  if (data.length === 0) return null;

  const [{ img: img1, header: header1, body: body1 }] = data;
  const dateBody = dayjs(body1).isValid()
    ? dayjs(body1).format("DD MMM YYYY")
    : body1;

  const NUM_2 = 2;

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      sx={{
        position: "absolute",
        left: "calc(50% - 525px)",
        top: "20%",
        transform: "translate(-20%, -calc(50% - 525px))",
        width: MAX_CONTENT,
      }}
    >
      <Grid container sx={style}>
        <ModalBoldTitle title={header1} />

        <img
          alt="oct-image1"
          src={img1}
          loading="lazy"
          style={{ width: "100%", objectFit: "cover" }}
        />
        <Typography id="modal-modal-description" variant="body2" sx={{ mt: 2 }}>
          {dateBody}
        </Typography>
        {data.length === NUM_2 && (
          <SubModal data={data[1]} open={open} toggleModal={toggleModal} />
        )}
      </Grid>
    </Modal>
  );
};

export default OctImagesModal;
