import { FormControl, MenuItem, Box, Grid, Typography } from "@mui/material";
import { useState, ChangeEvent } from "react";
import { Control } from "react-hook-form";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import FormInput from "../../../shareComponents/form/FormInput";
import { REACT_QUERY_KEYS } from "../../../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../../../services/dbConstants";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { userGetData } from "../../../../services/user";
import { TreatmentRecord } from "../../../../utils/types/services/patients";

interface InjectionFormProps {
  control: Control<TreatmentRecordFormState>;
  treatmentRecord?: TreatmentRecord | null;
  checkDrugDataChanged: (drugId: number, scanned: string) => void;
}

const listHeader = {
  color: "#737373",
  fontSize: "14px",
  fontWeight: "400",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
} as const;

const defaultSelectItems = [
  {
    id: -1,
    displayText: "-",
  },
];

const InjectionForm = (props: InjectionFormProps) => {
  const { control, treatmentRecord, checkDrugDataChanged } = props;
  const { t } = useTranslation();
  const SPACE_BETWEEN = "space-between";
  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );
  const NUM_4 = 4;
  const NUM_6 = 6;

  const { data: userData } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const instituteTypes = userData?.data?.instituteName ?? [];

  const onSelectChange = (e: ChangeEvent<HTMLInputElement>) => {
    const temp: number = parseInt(e.target.value);
    checkDrugDataChanged(temp, "");
  };

  const drugTypes = defaultSelectItems.concat(
    dbConstants?.data?.drugTypes ?? []
  );

  const displayDrugName = (id: number) => {
    const item = dbConstants?.data?.drugTypes.find(
      (drugItem) => drugItem.id === id
    );
    return item ? item.displayText : "";
  };

  return (
    <Grid>
      <Grid container>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 5,
            marginTop: "5px",
            width: "100%",
            justifyContent: SPACE_BETWEEN,
          }}
        >
          <FormControl sx={{ width: 1 }}>
            {(treatmentRecord?.serialNumber === undefined ||
              treatmentRecord?.serialNumber === "") && (
              <FormInput
                select
                control={control}
                name={"drugId"}
                label="Drug"
                onChange={onSelectChange}
                sx={{ width: 0.5 }}
              >
                {drugTypes.map((e) => (
                  <MenuItem key={e.id} value={e.id}>
                    {e.displayText}
                  </MenuItem>
                ))}
              </FormInput>
            )}
            <FormInput
              select
              control={control}
              name="treatedInstitute"
              label="Institute Name"
              sx={{ width: 1 }}
            >
              {instituteTypes.map((e) => (
                <MenuItem key={e} value={e}>
                  {e}
                </MenuItem>
              ))}
            </FormInput>
          </FormControl>
        </Box>
      </Grid>
      <Grid item sx={{ pt: 1 }}>
        <Typography color="primary">{t("productScan.mobileOnly")}</Typography>
      </Grid>

      {control._formValues.serialNumber && (
        <Grid item xs={12} sm={12} md={6} sx={{ paddingTop: "10px" }}>
          <Typography variant="subtitle2">
            {t("productScan.details")}
          </Typography>
          <Typography variant="h5" sx={{ fontWeight: "bold", py: 1 }}>
            {displayDrugName(control._formValues.drugId)}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: SPACE_BETWEEN,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Typography variant="body1" sx={listHeader}>
              {t("productScan.serialNumber")} :{" "}
            </Typography>
            <Typography variant="body1" sx={listValue}>
              <b>{control._formValues.serialNumber}</b>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: SPACE_BETWEEN,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Typography variant="body1" sx={listHeader}>
              {t("productScan.lotNo")} :
            </Typography>
            <Typography variant="body1" sx={listValue}>
              <b>{control._formValues.lotNumber}</b>
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: SPACE_BETWEEN,
              alignItems: "center",
              pb: 1,
            }}
          >
            <Typography variant="body1" sx={listHeader}>
              {t("productScan.expiry")} :
            </Typography>
            <Typography variant="body1" sx={listValue}>
              <b>
                {control._formValues.expiryDate.substring(NUM_4, NUM_6) +
                  control._formValues.expiryDate.substring(0, NUM_4)}
              </b>
            </Typography>
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default InjectionForm;
