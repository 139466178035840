import { Grid } from "@mui/material";
import { useCallback, useMemo } from "react";
import TabView, { TabItem } from "../../shareComponents/tabView";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../utils/constants/ga";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { userGetPosologyReferenceImages } from "../../../services/user";
import { isMobile } from "react-device-detect";

const Page = () => {
  const { t } = useTranslation();
  const COMON_WHITE = "common.white";
  const COMMON_BLACK = "common.black";
  const orientation: "horizontal" | "vertical" | undefined = isMobile
    ? "vertical"
    : "horizontal";
  const NUM_2 = 2;

  const { data } = useQuery(
    [REACT_QUERY_KEYS.POSOLOGY_REFERENCE_IMAGE],
    () => {
      return userGetPosologyReferenceImages();
    },
    {
      retry: true,
    }
  );

  const [loading_1Img, pti_1Img, pti_2Img, dAA_1Img, dAA_2Img] = useMemo(() => {
    const loading_1Image = data?.data?.find(
      (e) => e.name === "loading_1"
    )?.imageUrl;
    const pti_1Image = data?.data?.find((e) => e.name === "pti_1")?.imageUrl;
    const pti_2Image = data?.data?.find((e) => e.name === "pti_2")?.imageUrl;
    const dAA_1Image = data?.data?.find((e) => e.name === "daa_1")?.imageUrl;
    const dAA_2Image = data?.data?.find((e) => e.name === "daa_2")?.imageUrl;

    return [loading_1Image, pti_1Image, pti_2Image, dAA_1Image, dAA_2Image];
  }, [data]);

  const tabs: TabItem[] = useMemo(() => {
    return [
      {
        label: t("pTIReferencePage.tab.title1"),
        component: (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: COMON_WHITE }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <img
                src={loading_1Img}
                alt="Initiation_Phase"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        ),
      },
      {
        label: t("pTIReferencePage.tab.title2"),
        component: (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: COMON_WHITE }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderBottomWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={dAA_1Img}
                alt="Disease_Activity_Assessment_1"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderTopWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={dAA_2Img}
                alt="Disease_Activity_Assessment_2"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        ),
      },
      {
        label: t("pTIReferencePage.tab.title3"),
        component: (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: COMON_WHITE }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderBottomWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={pti_1Img}
                alt="Personalized_Treatment_Interval_1"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderTopWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={pti_2Img}
                alt="Personalized_Treatment_Interval_2"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        ),
      },
    ];
  }, [t, loading_1Img, pti_1Img, pti_2Img, dAA_1Img, dAA_2Img]);

  const getCurrentTabIndex = useCallback((val: number) => {
    let action = "";
    if (val === 0) action = NavigationActionType.PTIRef_Tab1;
    if (val === 1) action = NavigationActionType.PTIRef_Tab2;
    if (val === NUM_2) action = NavigationActionType.PTIRef_Tab3;

    ReactGA.event({
      category: CategoryType.Navigation,
      action,
    });
  }, []);

  return (
    <Grid item xs={12}>
      <TabView
        tabs={tabs}
        getCurrentTabIndex={getCurrentTabIndex}
        orientation={orientation}
      />
    </Grid>
  );
};

export default Page;
