import { Divider, Paper, Grid, Typography } from "@mui/material";
import { useCallback, useState, useMemo, useRef, useEffect } from "react";
import {
  EyeDetailsProps,
  TreatmentRecord,
  eyeStatus,
} from "../../../utils/types/services/patients";
import { Eye } from "../../../utils/constants/general";
import CSTAndVAGraphModal from "../CSTAndVAGraphModal";
import TreatmentInfoMobile from "./TreatmentInfoMobile";
import { GraphDataItem } from "../types";
import { useTranslation } from "react-i18next";
import { AEDataProps } from "../../../services/treatmentRecord";
import TabView, { TabItem } from "../../shareComponents/tabView";

interface TreatmentInfoMobileContainerProps {
  leftEyeTreatmentRecords: TreatmentRecord[];
  rightEyeTreatmentRecords: TreatmentRecord[];
  leftEyeDetails: EyeDetailsProps;
  rightEyeDetails: EyeDetailsProps;
  handleAddTreatmentRecordClick: (eye: Eye) => void;
  onEditTreatmentInfoClick: (id: number) => void;
  treatmentMethods: [number, number];
  treatmentStatus: [
    keyof typeof eyeStatus | null,
    keyof typeof eyeStatus | null
  ];
  handleChangeTreatmentMethods: (eye: Eye, value: number) => void;
  handleChangeTreatmentStatus: (
    eye: Eye,
    value: keyof typeof eyeStatus | null
  ) => void;
  handleOpenAEModal: (data: AEDataProps) => void;
  isOwner: boolean;
}

const TreatmentInfoMobileContainer = (
  props: TreatmentInfoMobileContainerProps
) => {
  const {
    leftEyeTreatmentRecords,
    rightEyeTreatmentRecords,
    leftEyeDetails,
    rightEyeDetails,
    handleAddTreatmentRecordClick,
    onEditTreatmentInfoClick,
    treatmentMethods,
    handleChangeTreatmentMethods,
    treatmentStatus,
    handleChangeTreatmentStatus,
    handleOpenAEModal,
    isOwner,
  } = props;

  const { t } = useTranslation();

  const [graphModalOpen, setGraphModalOpen] = useState(false);
  const [graphData, setGraphData] = useState<GraphDataItem[]>([]);
  const [graphEye, setGraphEye] = useState(Eye.LEFT);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const tabs: TabItem[] = useMemo(() => {
    return [
      {
        label: (
          <Typography variant="subtitle1">
            {t("patientDetailPage.rightEye")}
          </Typography>
        ),
        component: (
          <TreatmentInfoMobile
            treatmentRecords={rightEyeTreatmentRecords}
            eyeDetails={rightEyeDetails}
            handleGraphModalOpen={() => handleGraphModalOpen(Eye.RIGHT)}
            handleAddTreatmentRecordClick={() =>
              handleAddTreatmentRecordClick(Eye.RIGHT)
            }
            onEditTreatmentInfoClick={onEditTreatmentInfoClick}
            treatmentMethod={treatmentMethods[Eye.RIGHT]}
            handleChangeTreatmentMethods={(value) =>
              handleChangeTreatmentMethods(Eye.RIGHT, value)
            }
            treatmentStatusValue={treatmentStatus[Eye.RIGHT]}
            handleChangeTreatmentStatus={(value) =>
              handleChangeTreatmentStatus(Eye.RIGHT, value)
            }
            handleOpenAEModal={handleOpenAEModal}
            isOwner={isOwner}
          />
        ),
      },
      {
        label: (
          <Typography variant="subtitle1">
            {t("patientDetailPage.leftEye")}
          </Typography>
        ),
        component: (
          <TreatmentInfoMobile
            treatmentRecords={leftEyeTreatmentRecords}
            eyeDetails={leftEyeDetails}
            handleGraphModalOpen={() => handleGraphModalOpen(Eye.LEFT)}
            handleAddTreatmentRecordClick={() =>
              handleAddTreatmentRecordClick(Eye.LEFT)
            }
            onEditTreatmentInfoClick={onEditTreatmentInfoClick}
            treatmentMethod={treatmentMethods[Eye.LEFT]}
            handleChangeTreatmentMethods={(value) =>
              handleChangeTreatmentMethods(Eye.LEFT, value)
            }
            treatmentStatusValue={treatmentStatus[Eye.LEFT]}
            handleChangeTreatmentStatus={(value) =>
              handleChangeTreatmentStatus(Eye.LEFT, value)
            }
            handleOpenAEModal={handleOpenAEModal}
            isOwner={isOwner}
          />
        ),
      },
    ];
  }, [
    t,
    rightEyeTreatmentRecords,
    rightEyeDetails,
    leftEyeTreatmentRecords,
    leftEyeDetails,
    treatmentMethods,
    treatmentStatus,
  ]);

  const handleGraphModalClose = useCallback(() => {
    setGraphData([]);
    setGraphModalOpen(false);
  }, []);

  const handleGraphModalOpen = useCallback(
    (eye: Eye) => {
      setGraphData(
        (eye === Eye.LEFT ? leftEyeTreatmentRecords : rightEyeTreatmentRecords)
          .filter((e) => e.recordStatus)
          .map((record) => ({
            cst: record.cst,
            va: record.va,
            vaToDisplay: record.va,
            date: record.date,
            drugName: record.drugName,
            drugId: record.drugId,
            cstStatus: record.cstStatus,
            vaStatus: record.vaStatus,
            treatmentInterval: record.treatmentInterval,
          }))
      );

      setGraphEye(eye);
      setGraphModalOpen(true);
    },
    [leftEyeTreatmentRecords, rightEyeTreatmentRecords]
  );

  const getCurrentTabIndex = useCallback((val: number) => {
    setCurrentTab(val);
  }, []);

  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
      }}
    >
      <CSTAndVAGraphModal
        data={graphData}
        open={graphModalOpen}
        handleClose={handleGraphModalClose}
        graphEye={graphEye}
      />
      <Grid item sx={{ mb: "50px" }}>
        <TabView
          tabs={tabs}
          getCurrentTabIndex={getCurrentTabIndex}
          changeCurrentTabIndexTo={currentTab}
          fixedHeader={true}
        />
      </Grid>
    </Paper>
  );
};

export default TreatmentInfoMobileContainer;
