import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Grid } from "@mui/material";
import { ChartItemColor } from "../type";
import { getEyesByStatus } from "../../../services/dashboard";
import { useQueries } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { eyeStatus } from "../../../utils/types/services/patients";
import FilterOptionsPopover, {
  FilterValueProps,
  DiseaseTypeProps,
  DrugTypeProps,
} from "./FilterOptionsPopover";
import FilterPopover from "../FilterPopover";
import _ from "lodash";
import { useTheme } from "@mui/system";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";

const defaultValue = {
  diseaseType: {
    nAMD: true,
    PCV: true,
    DME: true,
    RVO: true,
    mCNV: true,
    others: true,
  },
  drugType: {
    Faricimab: true,
    Ranibizumab: true,
    Brolucizumab: true,
    Aflibercept: true,
  },
} as const;

const PatientStatusChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const EYE_STATUS_COLOR = "rgba(239,243,249,1)";
  const NUM_2 = 2;

  const [value, setValue] = useState<FilterValueProps>(defaultValue);

  const [closePopover, setClosePopover] = useState<number>(0);

  const [{ data: graphData }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.EYESSTATUSCHART],
        queryFn: getEyesByStatus,
        retry: true,
        refetchOnMount: "always",
      },
    ],
  });

  const data = useMemo<ChartData<"bar", any[], unknown>>(() => {
    const graph_data = graphData?.data ?? [];
    const selectedDiseaseTypeList: string[] = [];
    const diseaseTypeKeys = Object.keys(value.diseaseType);
    diseaseTypeKeys.forEach((e) => {
      if (!value.diseaseType[e as keyof DiseaseTypeProps]) return;
      selectedDiseaseTypeList.push(e.toLowerCase());
    });

    const selectedDrugTypeList: string[] = [];
    const drugTypeKeys = Object.keys(value.drugType);
    drugTypeKeys.forEach((e) => {
      if (!value.drugType[e as keyof DrugTypeProps]) return;
      selectedDrugTypeList.push(e.toLowerCase());
    });

    const filteredData = graph_data.filter(
      (e) =>
        selectedDiseaseTypeList.includes(e.diseaseTypeName.toLowerCase()) &&
        selectedDrugTypeList.includes(e.drugName.toLowerCase())
    );

    let statusNew = 0;
    let statusActive = 0;
    let statusInactive = 0;
    let statusCompleted = 0;

    const statusNewFilter = filteredData.filter(
      (e) => e.status.toLowerCase() === eyeStatus.new.toLowerCase()
    );

    statusNewFilter.forEach((e) => {
      statusNew += e.statusCount;
    });

    const statusActiveFilter = filteredData.filter(
      (e) => e.status.toLowerCase() === eyeStatus.active.toLowerCase()
    );

    statusActiveFilter.forEach((e) => {
      statusActive += e.statusCount;
    });

    const statusInactiveFilter = filteredData.filter(
      (e) => e.status.toLowerCase() === eyeStatus.inactive.toLowerCase()
    );

    statusInactiveFilter.forEach((e) => {
      statusInactive += e.statusCount;
    });

    const statusCompletedFilter = filteredData.filter(
      (e) => e.status.toLowerCase() === eyeStatus.completed.toLowerCase()
    );

    statusCompletedFilter.forEach((e) => {
      statusCompleted += e.statusCount;
    });

    const statusTotal =
      statusNew + statusActive + statusInactive + statusCompleted;

    return {
      labels: [
        `${eyeStatus.new.toUpperCase()}`,
        `${eyeStatus.active.toUpperCase()}`,
        `${eyeStatus.inactive.toUpperCase()}`,
        `${eyeStatus.completed.toUpperCase()}`,
      ],
      datasets: [
        {
          label: "Current",
          data: [statusNew, statusActive, statusInactive, statusCompleted],
          borderColor: [ChartItemColor.blue],
          backgroundColor: [ChartItemColor.blue],
        },
        {
          label: "Total Minus Current",
          data: [
            statusTotal - statusNew,
            statusTotal - statusActive,
            statusTotal - statusInactive,
            statusTotal - statusCompleted,
          ],
          borderColor: [EYE_STATUS_COLOR],
          backgroundColor: [EYE_STATUS_COLOR],
          hoverBackgroundColor: [EYE_STATUS_COLOR],
        },
      ],
    };
  }, [t, graphData, value]);

  const options = useMemo<ChartOptions<"bar">>(
    () => ({
      maintainAspectRatio: false,
      aspectRatio: 1,
      layout: {
        padding: 2,
      },
      indexAxis: "y",
      plugins: {
        datalabels: {
          color: theme.palette.common.white,
          font: {
            size: 17,
          },
          formatter: (val, context) => {
            const datasetIndex = context.datasetIndex;
            if (datasetIndex === 1) return "";
            return val;
          },
        },
        tooltip: {
          filter: (tooltipItem) => {
            return tooltipItem.datasetIndex === 0;
          },
          callbacks: {
            label: ({ dataIndex }) => {
              if (dataIndex === 0)
                return t("dashboardPage.eyesStatusChart.new");
              if (dataIndex === 1)
                return t("dashboardPage.eyesStatusChart.active");
              if (dataIndex === NUM_2)
                return t("dashboardPage.eyesStatusChart.inactive");
              return t("dashboardPage.eyesStatusChart.completed");
            },
          },
        },
        legend: {
          onClick: () => null,
          display: false,
        },
        title: {
          font: {
            size: 20,
          },
          color: theme.palette.text.primary,
          display: true,
          text: t("dashboardPage.eyesStatusChart.title"),
        },
      },
      scales: {
        x: {
          stacked: true,
          display: false,
          grid: {
            display: false,
          },
        },
        y: {
          stacked: true,
          grid: {
            display: false,
            drawBorder: false,
          },
          ticks: {
            color: theme.palette.text.primary,
          },
        },
      },
    }),
    [t]
  );

  const applyFilter = useCallback(
    (apply: boolean, filtered_data: FilterValueProps | null) => {
      if (apply) setValue({ ...filtered_data! });
      setClosePopover((val) => {
        ++val;
        return val;
      });
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Dashboard_Apply_Filter,
        label: t("dashboardPage.eyesStatusChart.title"),
      });
    },
    [t]
  );

  const isFilterApplied = useMemo(() => {
    return !_.isEqual(defaultValue, value);
  }, [value]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: "background.paper",
        p: 2,
      }}
      direction="row"
    >
      <Grid
        item
        xs={11}
        sx={{ height: { md: "37vh", sm: "45vh", xs: "45vh" }, minHeight: 300 }}
      >
        <Bar data={data} options={options} />
      </Grid>
      <Grid item xs={1}>
        <FilterPopover
          filtered={isFilterApplied}
          closePopover={closePopover}
          content={
            <FilterOptionsPopover value={value} applyFilter={applyFilter} />
          }
        />
      </Grid>
    </Grid>
  );
};

export default PatientStatusChart;
