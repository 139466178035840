import { Drawer, DrawerProps } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";

const style = (theme: Theme) =>
  ({
    width: 0.4,
    minWidth: 430,
    height: "calc(100% - 75px)",
    top: "unset",
    bottom: 0,
    padding: 2.8,
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  } as const);

interface SideDrawerProps extends DrawerProps {
  headerText: string;
  handleCloseDrawerClick: () => void;
  handleDrawerClick?: () => void;
}

const SideDrawer = (props: SideDrawerProps) => {
  const {
    handleCloseDrawerClick,
    handleDrawerClick,
    headerText,
    children,
    ...otherProps
  } = props;

  return (
    <Drawer
      {...otherProps}
      anchor={otherProps.anchor ?? "right"}
      PaperProps={{
        sx: style,
      }}
      onClick={handleDrawerClick}
    >
      <Grid container alignItems="center" justifyContent="space-between">
        <Typography variant="h6" fontWeight="bold">
          {headerText}
        </Typography>

        <IconButton onClick={handleCloseDrawerClick}>
          <CloseIcon />
        </IconButton>
      </Grid>

      {children}
    </Drawer>
  );
};

export default SideDrawer;
