import {
  Divider,
  Grid,
  Box,
  Typography,
  IconButton,
  Collapse,
} from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import {
  TreatmentRecord,
  EyeDetailsProps,
  eyeStatus,
} from "../../../utils/types/services/patients";
import LastTreatmentInfo from "./LastTreatmentInfo";
import NextTreatmentRecommendation from "./NextTreatmentRecommendation";
import TreatmentInfoHeader from "./TreatmentInfoHeader";
import TreatmentRecordsMobile from "./TreatmentRecordsMobile";
import { AEDataProps } from "../../../services/treatmentRecord";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const box_styles = () =>
  ({
    pt: 3,
    background: "#F5F6FA",
    width: "100%",
  } as const);
const box_container = () =>
  ({
    display: "flex",
    flexDirection: "column-reverse",
    px: 1,
    background: "#FFFFFF",
    borderRadius: "4px",
    width: "100%",
  } as const);

const stickyStyles = {
  sticky: {
    position: "fixed",
    width: "calc(100% - 130px)",
    top: "130px",
    zIndex: "99",
    background: "#FFFFFF",
  },
};

interface TreatmentInfoMobileProps {
  treatmentRecords: TreatmentRecord[];
  eyeDetails: EyeDetailsProps;
  handleGraphModalOpen: () => void;
  handleAddTreatmentRecordClick: () => void;
  onEditTreatmentInfoClick: (id: number) => void;
  treatmentMethod: number;
  handleChangeTreatmentMethods: (value: number) => void;
  treatmentStatusValue: keyof typeof eyeStatus | null;
  handleChangeTreatmentStatus: (value: keyof typeof eyeStatus | null) => void;
  handleOpenAEModal: (data: AEDataProps) => void;
  isOwner: boolean;
}

const TreatmentInfoMobile = (props: TreatmentInfoMobileProps) => {
  const {
    treatmentRecords,
    handleGraphModalOpen,
    handleAddTreatmentRecordClick,
    onEditTreatmentInfoClick,
    eyeDetails,
    treatmentMethod,
    handleChangeTreatmentMethods,
    handleOpenAEModal,
    isOwner,
    treatmentStatusValue,
    handleChangeTreatmentStatus,
  } = props;
  const [showinfo, setShowinfo] = useState<boolean>(false);

  const handleCollapseButtonClick = () => {
    setShowinfo(!showinfo);
  };

  const headerRef = useRef<HTMLDivElement | null>(null);
  const [stickyHeader, setStickyHeader] = useState(false);
  const NUM_130 = 130;
  const NUM_180 = 180;
  const NUM_18 = 18;

  useEffect(() => {
    const handleScroll = () => {
      const sticky = headerRef.current
        ? headerRef.current?.offsetTop - NUM_130
        : 0;
      if (window.scrollY > NUM_180 && window.scrollY > sticky) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const latestTreatment = useMemo(
    () => treatmentRecords.find((e) => e.drugId !== null && e.recordStatus),
    [treatmentRecords]
  );

  return (
    <Grid container item xs={12} sx={{ flexGrow: 1 }}>
      <Box>
        <Box
          ref={headerRef}
          className={stickyHeader === true ? "stickyEyeHeader" : ""}
        >
          <TreatmentInfoHeader
            eyeDetails={eyeDetails}
            handleGraphModalOpen={handleGraphModalOpen}
            isOwner={isOwner}
            treatmentStatusValue={treatmentStatusValue}
            handleChangeTreatmentStatus={handleChangeTreatmentStatus}
          />
          <Divider sx={{ mx: 1, width: "calc(100% - 12px)" }} />
        </Box>
        <Box
          style={{
            paddingTop:
              stickyHeader === true
                ? headerRef.current
                  ? headerRef.current.clientHeight + NUM_18
                  : ""
                : "",
          }}
        >
          <Box
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="subtitle1" fontWeight="bold">
              Last & Next Treatment Recommendations
            </Typography>
            <IconButton onClick={handleCollapseButtonClick}>
              {!showinfo ? (
                <KeyboardArrowDownIcon color={"primary"} />
              ) : (
                <KeyboardArrowUpIcon />
              )}
            </IconButton>
          </Box>
          <Collapse in={showinfo}>
            <Grid item xs={12} sm={12} md={8}>
              <LastTreatmentInfo record={latestTreatment} />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <NextTreatmentRecommendation
                eyeDetails={eyeDetails}
                treatmentMethod={treatmentMethod}
                handleChangeTreatmentMethods={handleChangeTreatmentMethods}
                record={latestTreatment}
                isOwner={isOwner}
              />
            </Grid>
          </Collapse>
        </Box>
        <Box sx={box_styles}>
          <Box sx={box_container}>
            <Grid item>
              <TreatmentRecordsMobile
                eyeDetails={eyeDetails}
                records={treatmentRecords}
                onRecordClick={onEditTreatmentInfoClick}
                handleAddTreatmentRecordClick={handleAddTreatmentRecordClick}
                handleOpenAEModal={handleOpenAEModal}
              />
            </Grid>
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default TreatmentInfoMobile;
