import { Route, Routes, useLocation } from "react-router-dom";
import DashboardPage from "../components/DashboardPage";
import LoginPage from "../components/LoginPage";
import PatientDetailPage from "../components/PatientDetailPage";
import PatientListingPage from "../components/PatientListingPage";
import PTIReferencePage from "../components/PosologyReferencePage";
import DisclaimerPage from "../components/DisclaimerPage";
import UserProfilePage from "../components/UserProfilePage";
import HomeLayer from "./ParentLayer/HomeLayer";
import ProtectedHCPLayer from "./ParentLayer/ProtectedHCPLayer";
import ProtectedHCPLayerNoLayout from "./ParentLayer/ProtectedHCPLayerNoLayout";
import ProtectedITAdminLayer from "./ParentLayer/ProtectedITAdminLayer";
import ProtectedAdminLayer from "./ParentLayer/ProtectedAdminLayer";
import ProtectedLayer from "./ParentLayer/ProtectedLayer";
import { ROUTE_PATHS } from "../utils/constants/routes/webPaths";
import { getAllDBConstants } from "../services/dbConstants";
import { useQueries, useQueryClient } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../utils/constants/reactQueryKeys";
import ReactGA from "react-ga4";
import { useEffect, lazy, Suspense } from "react";
import { userGetData } from "../services/user";
import ConfirmPatientConsetPage from "../components/ConfirmPatientConsentPage";
import ConfirmCareGiverConsetPage from "../components/ConfirmCareGiverConsentPage";
import CenterLoader from "../components/shareComponents/loading/Center";
import PSPConsentPage from "../components/PspIndicator/PSPConsentPage";
// aad lazy for less use feature

const NUM_300 = 300;
const NUM_16 = 16;
const NUM_15 = 15;
const STATUS_401 = 401;

const wait = () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(true);
    }, NUM_300);
  });
};

const PatientPreviewPage = lazy(() =>
  wait().then(() => import("../components/PatientPreviewPage"))
);
const NotFoundPage = lazy(() =>
  wait().then(() => import("../components/NotFoundPage"))
);
const RegistrationPage = lazy(() =>
  wait().then(() => import("../components/RegistrationPage"))
);
const ChangePasswordPage = lazy(() =>
  wait().then(() => import("../components/ChangePasswordPage"))
);
const ForgetPasswordPage = lazy(() =>
  wait().then(() => import("../components/ForgetPasswordPage"))
);
const AdminControlPage = lazy(() =>
  wait().then(() => import("../components/AdminControlPage"))
);
const AdminAuditLogPage = lazy(() =>
  wait().then(() => import("../components/AdminAuditLogPage"))
);

const ITAdminProductEntries = lazy(() =>
  wait().then(() => import("../components/ITAdminProductEntries"))
);

const ITAdminRWDReport = lazy(() =>
  wait().then(() => import("../components/ITAdminRWDReport"))
);

const ITAdminAddHcpEntry = lazy(() =>
  wait().then(() => import("../components/ITAdminHCPEntries"))
);

const AppRoutes = () => {
  const [{ isSuccess }, { data: userData }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.DB_CONSTANTS],
        queryFn: getAllDBConstants,
        retry: true,
      },
      {
        queryKey: [REACT_QUERY_KEYS.USER_DATA],
        queryFn: userGetData,
      },
    ],
  });

  const location = useLocation();
  const queryClient = useQueryClient();

  useEffect(() => {
    if (
      location.pathname.substring(0, NUM_16).toLowerCase() ===
      ROUTE_PATHS.PATIENT_DETAILS.substring(0, NUM_16).toLowerCase()
    ) {
      ReactGA.send({
        hitType: "pageview",
        page: location.pathname.substring(0, NUM_15).toLowerCase(),
      });
      return;
    }
    ReactGA.send("pageview");
  }, [location.pathname]);

  useEffect(() => {
    ReactGA.set({ userId: userData?.data?.userId ?? -1 });
  }, [userData?.data?.userId]);

  useEffect(() => {
    if (userData?.status === STATUS_401) {
      const keys = Object.values(REACT_QUERY_KEYS);
      keys.forEach((e, i) => {
        if (
          e === REACT_QUERY_KEYS.DB_CONSTANTS ||
          e === REACT_QUERY_KEYS.USER_DATA ||
          e === REACT_QUERY_KEYS.PATIENT_ENCRYPT_DATA
        )
          return;
        queryClient.removeQueries([e]);
      });
    }
  }, [userData?.status]);

  if (!isSuccess) return <CenterLoader />;

  return (
    <Suspense fallback={<CenterLoader />}>
      <Routes>
        <Route
          path={ROUTE_PATHS.CONFIRM_PATIENT_CONSENT}
          element={<ConfirmPatientConsetPage />}
        />
        <Route
          path={ROUTE_PATHS.CONFIRM_CAREGIVER_CONSENT}
          element={<ConfirmCareGiverConsetPage />}
        />

        <Route element={<HomeLayer />}>
          <Route path={ROUTE_PATHS.INIT} element={<LoginPage />} />
          <Route path={ROUTE_PATHS.LOGIN} element={<LoginPage />} />
          <Route
            path={ROUTE_PATHS.FORGET_PASSWORD}
            element={<ForgetPasswordPage />}
          />
          <Route
            path={ROUTE_PATHS.REGISTRATION}
            element={<RegistrationPage />}
          />
        </Route>

        <Route element={<ProtectedLayer />}>
          <Route path={ROUTE_PATHS.INDEX} element={<NotFoundPage />} />
        </Route>

        <Route element={<ProtectedHCPLayer />}>
          <Route path={ROUTE_PATHS.DASHBOARD} element={<DashboardPage />} />
          <Route path={ROUTE_PATHS.PATIENTS} element={<PatientListingPage />} />
          <Route path={ROUTE_PATHS.PROFILE} element={<UserProfilePage />} />
          <Route
            path={ROUTE_PATHS.CHANGE_PASSWORD}
            element={<ChangePasswordPage />}
          />
          <Route
            path={ROUTE_PATHS.PATIENT_DETAILS}
            element={<PatientDetailPage />}
          />
          <Route path={ROUTE_PATHS.REFERENCE} element={<PTIReferencePage />} />
          <Route path={ROUTE_PATHS.DISCLAIMER} element={<DisclaimerPage />} />
          <Route
            path={ROUTE_PATHS.PSPCONSENTPAGE}
            element={<PSPConsentPage />}
          />
        </Route>

        <Route element={<ProtectedHCPLayerNoLayout />}>
          <Route
            path={ROUTE_PATHS.PATIENT_PREVIEW_DETAILS}
            element={<PatientPreviewPage />}
          />
        </Route>

        <Route element={<ProtectedAdminLayer />}>
          <Route
            path={ROUTE_PATHS.ADMIN_HCP_TABLE}
            element={<AdminControlPage />}
          />
          <Route
            path={ROUTE_PATHS.ADMIN_AUDIT_LOG}
            element={<AdminAuditLogPage />}
          />
        </Route>

        <Route element={<ProtectedITAdminLayer />}>
          <Route
            path={ROUTE_PATHS.IT_ADMIN_PRODUCT_ENTRIES}
            element={<ITAdminProductEntries />}
          />
          <Route
            path={ROUTE_PATHS.IT_ADMIN_RWD_REPORT}
            element={<ITAdminRWDReport />}
          />
          <Route
            path={ROUTE_PATHS.IT_ADMIN_HCP_ENTRIES}
            element={<ITAdminAddHcpEntry />}
          />
        </Route>
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
