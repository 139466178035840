import CollectionsBookmarkOutlinedIcon from "@mui/icons-material/CollectionsBookmarkOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import TableViewIcon from "@mui/icons-material/TableView";
import FindInPageIcon from "@mui/icons-material/FindInPage";
import DocumentScannerOutlinedIcon from "@mui/icons-material/DocumentScannerOutlined";
import { ReactNode } from "react";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";
import { NavigationActionType } from "../../utils/constants/ga";
import { PlusOne, SupervisedUserCircle } from "@mui/icons-material";
import UserAvatar from "../../components/UserProfilePage/UserAvatar";

export interface ISideBarItems {
  name: string;
  icon: ReactNode;
  to: string;
  navigationActionType: keyof typeof NavigationActionType;
}

export const SIDE_BAR_ITEMS_HCP: ISideBarItems[] = [
  {
    name: "dashboardPage.header",
    icon: <DashboardOutlinedIcon />,
    to: ROUTE_PATHS.DASHBOARD,
    navigationActionType: "SideBar_Dashboard",
  },
  {
    name: "patientListingPage.header",
    icon: <PersonOutlineOutlinedIcon />,
    to: ROUTE_PATHS.PATIENTS,
    navigationActionType: "SideBar_Patients",
  },
  {
    name: "pTIReferencePage.header",
    icon: <CollectionsBookmarkOutlinedIcon />,
    to: ROUTE_PATHS.REFERENCE,
    navigationActionType: "SideBar_PTIReference",
  },
];

export const SIDE_BAR_ITEMS_HCP_MOBILE: ISideBarItems[] = [
  {
    name: "dashboardPage.header",
    icon: <DashboardOutlinedIcon />,
    to: ROUTE_PATHS.DASHBOARD,
    navigationActionType: "SideBar_Dashboard",
  },
  {
    name: "patientListingPage.header",
    icon: <PersonOutlineOutlinedIcon />,
    to: ROUTE_PATHS.PATIENTS,
    navigationActionType: "SideBar_Patients",
  },
  {
    name: "pTIReferencePage.header",
    icon: <CollectionsBookmarkOutlinedIcon />,
    to: ROUTE_PATHS.REFERENCE,
    navigationActionType: "SideBar_PTIReference",
  },
  {
    name: "disclaimerMenu.header",
    icon: <DocumentScannerOutlinedIcon />,
    to: ROUTE_PATHS.DISCLAIMER,
    navigationActionType: "SideBar_Disclaimer",
  },
];

export const SIDE_BAR_ITEMS_ADMIN: ISideBarItems[] = [
  {
    name: "adminControlPage.header",
    icon: <TableViewIcon />,
    to: ROUTE_PATHS.ADMIN_HCP_TABLE,
    navigationActionType: "SideBar_HCPs",
  },
  {
    name: "adminAuditLogPage.header",
    icon: <FindInPageIcon />,
    to: ROUTE_PATHS.ADMIN_AUDIT_LOG,
    navigationActionType: "SideBar_AuditLog",
  },
];

export const SIDE_BAR_ITEMS_IT_ADMIN: ISideBarItems[] = [
  {
    name: "itadminproductentries.header",
    icon: <TableViewIcon />,
    to: ROUTE_PATHS.IT_ADMIN_PRODUCT_ENTRIES,
    navigationActionType: "SideBar_ProductDetails",
  },
  {
    name: "itadminrwdreport.header",
    icon: <FindInPageIcon />,
    to: ROUTE_PATHS.IT_ADMIN_RWD_REPORT,
    navigationActionType: "SideBar_RWDReport",
  },
  {
    name: "itadminhcpentires.header",
    icon: <SupervisedUserCircle />,
    to: ROUTE_PATHS.IT_ADMIN_HCP_ENTRIES,
    navigationActionType: "SideBar_AddHcpEntries",
  },
];
