import _ from "lodash";
import React, { useCallback, useState } from "react";
import { Eye } from "../../../../../utils/constants/general";
import EyeMedicalDetailsForm from "./EyeMedicalDetailsForm";

const MedicalDetailsForm = () => {
  const [collapse, setCollapse] = useState<[boolean, boolean]>([false, false]);

  const toggleCollapse = useCallback((eye: Eye) => {
    setCollapse((val) => {
      const newCollapse = _.cloneDeep(val);
      newCollapse[eye] = !newCollapse[eye];
      return newCollapse;
    });
  }, []);

  return (
    <React.Fragment>
      <EyeMedicalDetailsForm
        eye={Eye.RIGHT}
        collapse={collapse[Eye.RIGHT]}
        toggleCollapse={toggleCollapse}
      />
      <EyeMedicalDetailsForm
        eye={Eye.LEFT}
        collapse={collapse[Eye.LEFT]}
        toggleCollapse={toggleCollapse}
      />
    </React.Fragment>
  );
};

export default MedicalDetailsForm;
