import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Typography } from "@mui/material";

interface TreatmentOtherInfoSymbolProps {
  checked: boolean | null;
}

const TreatmentOtherInfoSymbol = (props: TreatmentOtherInfoSymbolProps) => {
  const { checked } = props;

  return checked === null ? (
    <Typography>N/A</Typography>
  ) : checked ? (
    <CheckCircleIcon fontSize="small" />
  ) : (
    <CancelOutlinedIcon fontSize="small" />
  );
};

export default TreatmentOtherInfoSymbol;
