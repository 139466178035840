import { Divider, Drawer, Toolbar, IconButton, Tooltip } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { userGetData } from "../../services/user";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { DRAWER_WIDTH } from "../constants";
import {
  ISideBarItems,
  SIDE_BAR_ITEMS_ADMIN,
  SIDE_BAR_ITEMS_IT_ADMIN,
  SIDE_BAR_ITEMS_HCP,
} from "./SideBarItems";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { CategoryType, NavigationActionType } from "../../utils/constants/ga";

const SideBar = () => {
  const { t } = useTranslation();

  const { data } = useQuery([REACT_QUERY_KEYS.USER_DATA], userGetData);

  const navigate = useNavigate();
  const location = useLocation();

  const renderSideBarItem = useCallback(
    (props: ISideBarItems, index: number) => {
      const { name, icon, to, navigationActionType } = props;
      const onClickAction = () => {
        navigate(to);
        ReactGA.event({
          category: CategoryType.Navigation,
          action: NavigationActionType[navigationActionType],
        });
      };

      return (
        <Tooltip title={t(name)} key={index} placement="right">
          <IconButton
            onClick={onClickAction}
            color={location.pathname === to ? "primary" : undefined}
          >
            {icon}
          </IconButton>
        </Tooltip>
      );
    },
    [t, location]
  );

  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: DRAWER_WIDTH,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      {data?.data?.role === "HCP"
        ? SIDE_BAR_ITEMS_HCP.map(renderSideBarItem)
        : data?.data?.role === "admin"
        ? SIDE_BAR_ITEMS_ADMIN.map(renderSideBarItem)
        : data?.data?.role === "IT Admin" &&
          SIDE_BAR_ITEMS_IT_ADMIN.map(renderSideBarItem)}
    </Drawer>
  );
};

export default SideBar;
