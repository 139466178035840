import { Grid, Typography } from "@mui/material";
import { useMutation, useQueries } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { postCaregiverEncryptData } from "../../services/patient";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import BaseButton from "../shareComponents/button/BaseButton";
import BaseCheckBox from "../shareComponents/checkbox/BaseCheckBox";

const ConsentPage = () => {
  const { t } = useTranslation();
  const { encryptdata } = useParams();

  /* const [{ data, isLoading }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.PATIENT_ENCRYPT_DATA],
        queryFn: () => getPatientEncryptData({ encryptdata: encryptdata! }),
        refetchOnMount: "always",
      },
    ],
  });*/

  const {
    mutateAsync,
    isLoading: isLoading2,
    data: mutateData,
  } = useMutation(postCaregiverEncryptData);

  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  }, []);

  const onClickConfirm = useCallback(async () => {
    const result = await mutateAsync({ encryptdata: encryptdata! });
    if (result.success) window.scrollTo(0, 0);
  }, [mutateAsync, encryptdata]);

  if (mutateData?.success)
    return (
      <Typography>感謝您的確認！ Thank you for your confirmation!</Typography>
    );

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: "background.paper",
        p: 2,
      }}
      direction="row"
    >
      <Grid container flexDirection={"column"}>
        <Typography variant="subtitle1" paragraph>
          To: Roche Hong Kong Limited ("Roche")
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I am aware, understand, and agree to provide my personal information,
          including my name, age, and information about myself having suffered
          from retinal diseases in the past or present, for the following
          purposes.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I acknowledge and understand:
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Personal information provided directly to Roche or indirectly through
          my healthcare providers to Roche (information about my disease
          management, including but not limited to treatment records, treatment
          outcomes, name, gender, and contact information) will be used for
          account management of the online platform called "Care For Vision,"
          and creating a profile for me on Care For Vision. I understand and
          confirm that, for the purposes of account management, Roche will
          process my personal information according to Roche's Privacy
          Statement.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Roche may use my data (only in aggregated form) to optimize the
          current management plan for retinal diseases through intravitreal
          injections and related purposes. These purposes include analyzing
          aggregated treatment results (excluding personal information) by
          Roche.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I understand and agree that if I choose to reach out to a healthcare
          provider through the Care For Vision platform, my contact information
          may be transferred to that healthcare provider. Any healthcare
          provider, as an independent data user, will use my personal
          information for purposes, including providing professional medical
          advice, identification, and record-keeping on the Care For Vision
          platform. For reporting adverse events, my healthcare provider may
          also share my personal information with Roche. I acknowledge that the
          collection and use of my personal data by healthcare providers will be
          subject to their own data policy, which is separate from Roche's
          Privacy Statement. It is my responsibility to review and understand
          the healthcare provider's data policy.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          The personal information I provide will be stored in a cloud storage
          solution used by Roche from time to time, which may be located in or
          out of Hong Kong. Roche will ensure that appropriate technical and
          contractual measures, such as encryption or contractual obligations on
          cloud storage providers are in place and maintain compliance with
          relevant data protection laws and regulations.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          For any questions or concerns regarding this Patient Consent Form,
          please contact Roche using the contact information provided in our
          Privacy Statement.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I am aware, understand, and agree:
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I voluntarily provide my personal information upon enrollment into
          Care For Vision, and I can notify Roche in writing to withdraw this
          consent and cease participation in the Care For Vision platform at any
          time.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Roche will strictly maintain the confidentiality of my personal
          information in accordance with applicable laws and regulations.
        </Typography>

        <Typography variant="subtitle1" paragraph>
          If I am providing information about my family member or otherwise on
          behalf of the patient as their carer, I confirm that I have the
          necessary authority and consent from my family members or the patient
          (as the case may be) to provide their personal information to Roche
          for the purposes mentioned above. I understand that it is my
          responsibility to ensure that the patient is aware of and agrees to
          the terms of this consent form.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          The personal information I provide will be kept for such period of
          time as required for the purposes mentioned above and will be
          destroyed in accordance with applicable laws and regulations.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I understand that I have the right to request access to and correction
          of my personal information held by Roche. For such requests, I can
          contact Roche using the contact information provided in Roche's
          Privacy Statement.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I am encouraged to review the data policy of any healthcare
          professional I choose to interact with through the Care For Vision
          platform. If I have any questions or concerns about how my personal
          data will be handled by the healthcare professional, I should contact
          them directly.
        </Typography>
        <Typography variant="subtitle1" paragraph>
          I understand that Roche's provision of healthcare providers'
          information on the Care For Vision platform does not constitute an
          endorsement of their practice or a recommendation for any specific
          healthcare provider or treatment. It is my responsibility to consult
          with the healthcare professional to determine the most appropriate
          treatment for my condition.
        </Typography>
      </Grid>

      <Grid container flexDirection={"row"} alignItems="center">
        <Grid>
          <BaseCheckBox onChange={handleChange} />
        </Grid>
        <Grid xs item>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            I hereby agree to the above terms.
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <BaseButton onClick={onClickConfirm} disabled={isLoading2 || !checked}>
          {t("singlePharse.confirm")}
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default ConsentPage;
