import { CSSProperties, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MAX_UPLOAD_FILE_SIZE } from "../../../utils/constants/general";
import { Box, Typography } from "@mui/material";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";

const baseStyle: CSSProperties = {} as const;

const focusedStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

interface StyledDropzoneMobileProps {
  onDrop: (files: File[]) => void;
  onChange: React.InputHTMLAttributes<HTMLInputElement>["onChange"];
  multiple?: boolean;
}

const StyledDropzoneMobile = (props: StyledDropzoneMobileProps) => {
  const { onDrop, onChange, multiple = true } = props;

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop,
      multiple,
      maxSize: MAX_UPLOAD_FILE_SIZE,
    });

  const style = {
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps({ onChange })} />
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ pr: 1 }}>Upload File</Typography>
          <InsertDriveFileOutlinedIcon />
        </Box>
      </div>
    </div>
  );
};

export default StyledDropzoneMobile;
