import { Pie } from "react-chartjs-2";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { ChartData, ChartOptions } from "chart.js";
import FilterPopover from "../FilterPopover";
import FilterOptionsPopover, {
  FilterValueProps,
  DiseaseTypeProps,
} from "./FilterOptionsPopover";
import { ChartItemColor } from "../type";
import _ from "lodash";
import { getVisualActivityOutcome } from "../../../services/dashboard";
import { useQueries } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { useTheme } from "@mui/system";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";

const defaultValue = {
  diseaseType: {
    nAMD: true,
    PCV: true,
    DME: true,
    RVO: true,
    mCNV: true,
    others: true,
  },
} as const;

const VisualActivityOutcomeChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const NUM_100 = 100;

  const [value, setValue] = useState<FilterValueProps>(defaultValue);

  const [closePopover, setClosePopover] = useState<number>(0);

  const [{ data: graphData }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.VISUALACTIVITYOUTCOMECHART],
        queryFn: getVisualActivityOutcome,
        retry: true,
        refetchOnMount: "always",
      },
    ],
  });

  const [filteredLabels, filteredData] = useMemo(() => {
    const data = graphData?.data ?? [];
    const labels: string[] = [];
    const numbers: number[] = [];
    const newData: { name: string; number: number }[] = [];
    const diseaseTypeKeys = Object.keys(value.diseaseType);
    diseaseTypeKeys.forEach((e) => {
      if (!value.diseaseType[e as keyof DiseaseTypeProps]) return;
      const filtered = data.filter(
        (ee) => ee.diseaseTypeName?.toLowerCase() === e.toLowerCase()
      );
      filtered.forEach((a) => {
        newData.push({ name: a.vaOutcomeDesc, number: a.vaOutcomeCount });
      });
    });

    const uniqData = _.uniqBy(newData, "name");
    uniqData.forEach((e, i) => {
      const totalArray = newData.filter((ee) => ee.name === e.name);
      let total = 0;
      totalArray.forEach((a) => {
        total += a.number;
      });
      labels.push(e.name);
      numbers.push(total);
    });

    return [labels, numbers];
  }, [value.diseaseType, graphData]);

  const chart_data = useMemo<ChartData<"pie", any[], unknown>>(
    () => ({
      labels: filteredLabels,
      datasets: [
        {
          label: "VisualActivityOutcomeChart",
          data: filteredData,
          backgroundColor: Object.values(ChartItemColor),
          hoverOffset: 4,
        },
      ],
    }),
    [filteredLabels, filteredData]
  );

  const options = useMemo<ChartOptions<"pie">>(
    () => ({
      maintainAspectRatio: false,
      layout: {
        padding: 2,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      plugins: {
        legend: {
          onClick: () => null,
          labels: {
            color: theme.palette.text.primary,
          },
        },
        datalabels: {
          display: "auto",
          color: theme.palette.common.white,
          font: {
            size: 17,
          },
          formatter: (val, context) => {
            const data = context.dataset.data as number[];
            const total = data.reduce((partialSum, a) => partialSum + a, 0);
            return `${val}\n(${Math.round((val / total) * NUM_100)}%)`;
          },
          textAlign: "center",
        },
        tooltip: {
          callbacks: {
            label: ({ dataset: { data }, label, formattedValue, parsed }) => {
              const total = data.reduce((partialSum, a) => partialSum + a, 0);
              return `${label}: ${formattedValue} (${Math.round(
                (parsed / total) * NUM_100
              )}%)`;
            },
          },
        },
        title: {
          font: {
            size: 20,
          },
          color: theme.palette.text.primary,
          display: true,
          text: t("dashboardPage.visualActivityOutcomeChart.title"),
        },
        subtitle: {
          font: {
            size: 15,
          },
          color: theme.palette.text.primary,
          display: true,
          text: [
            t("dashboardPage.visualActivityOutcomeChart.20/40"),
            t("dashboardPage.visualActivityOutcomeChart.20/200"),
          ],
          align: "start",
        },
      },
    }),
    [t]
  );

  const applyFilter = useCallback(
    (apply: boolean, data: FilterValueProps | null) => {
      if (apply) setValue({ ...data! });
      setClosePopover((val) => {
        ++val;
        return val;
      });
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Dashboard_Apply_Filter,
        label: t("dashboardPage.visualActivityOutcomeChart.title"),
      });
    },
    []
  );

  const isFilterApplied = useMemo(() => {
    return !_.isEqual(defaultValue, value);
  }, [value]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: "background.paper",
        p: 2,
      }}
      direction="row"
    >
      <Grid
        item
        xs={11}
        sx={{ height: { md: "37vh", sm: "45vh", xs: "45vh" }, minHeight: 300 }}
      >
        <Pie data={chart_data} options={options} />
      </Grid>
      <Grid item xs={1}>
        <FilterPopover
          filtered={isFilterApplied}
          closePopover={closePopover}
          content={
            <FilterOptionsPopover value={value} applyFilter={applyFilter} />
          }
        />
      </Grid>
    </Grid>
  );
};

export default VisualActivityOutcomeChart;
