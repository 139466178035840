import { Box, Typography, Grid, Tooltip, Divider, Button } from "@mui/material";
import dayjs from "dayjs";
import React, { useState, useCallback, useMemo } from "react";
import {
  EyeDetailsProps,
  TreatmentRecord,
} from "../../../utils/types/services/patients";
import TreatmentOtherInfoSymbol from "./TreatmentOtherInfoSymbol";
import TreatmentRecordHeaderMobile from "./TreatmentRecordHeaderMobile";
import OctImageModalMobile from "../OctImagesModalMobile";
import OctImageModal from "../OctImagesModal";
import useModal from "../../../hooks/useModal";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  AEDataProps,
  getOctImages,
  toggleTreatmentStatus,
} from "../../../services/treatmentRecord";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import SimpleModal from "../../shareComponents/modal/SimpleModal";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";
import ImageIcon from "@mui/icons-material/Image";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";

const SPACE_BETWEEN = "space-between";

const itemStyle = {
  display: "flex",
  justifyContent: SPACE_BETWEEN,
  alignItems: "center",
  mb: 2,
} as const;

const item2Style1 = {
  display: "flex",
  justifyContent: SPACE_BETWEEN,
  alignItems: "center",
  width: "100%",
  pr: 1,
} as const;

const item2Style2 = {
  display: "flex",
  justifyContent: SPACE_BETWEEN,
  alignItems: "center",
  width: "100%",
  pl: 1,
} as const;

const listHeader = {
  color: "#737373",
  fontSize: "14px",
  fontWeight: "400",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
  textAlign: "right",
} as const;

const buttonStyle = {
  color: "#000000",
  borderRadius: "8px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "400",
  border: "none",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
} as const;

interface TreatmentRecordsMobileProps {
  eyeDetails: EyeDetailsProps;
  records: TreatmentRecord[];
  handleAddTreatmentRecordClick: () => void;
  onRecordClick: (id: number) => void;
  handleOpenAEModal: (data: AEDataProps) => void;
}

const TreatmentRecordsMobile = (props: TreatmentRecordsMobileProps) => {
  const {
    eyeDetails,
    records,
    handleAddTreatmentRecordClick,
    onRecordClick,
    handleOpenAEModal,
  } = props;

  const NUM_4 = 4;
  const NUM_6 = 6;

  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const { data: octImgData, mutateAsync } = useMutation(getOctImages);

  const { mutateAsync: mutateAsync2, isLoading } = useMutation(
    toggleTreatmentStatus
  );

  const {
    isOpen: isOctImgModalOpen,
    openModal: openOctImgModal,
    closeModal: closeOCtImageModal,
  } = useModal();

  const {
    isOpen: isObsoleteModalOpen,
    openModal: openObsoleteModal,
    closeModal: closeObsoleteModal,
  } = useModal();

  const [obsoleteDetail, setObsoleteDetail] = useState<{
    id: number;
    header: string;
    body: string;
  }>({
    id: 0,
    header: "",
    body: "",
  });

  const handleDeleteRowClick = useCallback(
    (
      event: React.MouseEvent<unknown>,
      id: number,
      recordStatus: boolean,
      dateDisplay: string
    ) => {
      event.stopPropagation();
      const header = t(
        recordStatus
          ? "patientDetailPage.obsoletePrompt.obsoleteItemTitle"
          : "patientDetailPage.obsoletePrompt.reactiveItemTitle"
      ).replace("@Date", dateDisplay);
      const body = t(
        recordStatus
          ? "patientDetailPage.obsoletePrompt.obsoleteItemSubject"
          : "patientDetailPage.obsoletePrompt.reactiveItemSubject"
      ).replace("@Date", dateDisplay);

      setObsoleteDetail({
        id,
        header,
        body,
      });

      openObsoleteModal();
    },
    [openObsoleteModal, t]
  );

  const confirmObsoleteReactive = useCallback(async () => {
    const status = records.find(
      (e) => e.id === obsoleteDetail.id
    )!.recordStatus;
    const refetchResult = await mutateAsync2({
      id: obsoleteDetail.id,
      status: !status,
    });
    if (refetchResult.success) {
      if (refetchResult.data?.aeResult.exists) {
        handleOpenAEModal(refetchResult.data?.aeResult.data!);
      }

      queryClient.refetchQueries([
        REACT_QUERY_KEYS.PATIENT,
        refetchResult.data?.patientId,
      ]);
      closeObsoleteModal();
    }

    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Toggle_Treatment_Status,
      label: status.toString(),
    });
  }, [
    obsoleteDetail.id,
    mutateAsync2,
    records,
    closeObsoleteModal,
    handleOpenAEModal,
  ]);

  const [firstInjectionDate, totalInjections] = useMemo(() => {
    const activeRecords = records.filter((e) => e.recordStatus);
    const activeRecordsWithDrug = activeRecords.filter(
      (e) => e.drugId !== null
    );
    const activeRecordsWithDrugLength = activeRecordsWithDrug.length;
    const date =
      eyeDetails.firstInjectionDate === null
        ? activeRecordsWithDrugLength > 0
          ? activeRecordsWithDrug[activeRecordsWithDrugLength - 1].date
          : null
        : activeRecordsWithDrugLength > 0
        ? dayjs(eyeDetails.firstInjectionDate).diff(
            activeRecordsWithDrug[activeRecordsWithDrugLength - 1].date,
            "s"
          ) < 0
          ? eyeDetails.firstInjectionDate
          : activeRecordsWithDrug[activeRecordsWithDrugLength - 1].date
        : eyeDetails.firstInjectionDate;
    return [date, activeRecordsWithDrugLength + eyeDetails.pastInjectionCount];
  }, [records, eyeDetails.pastInjectionCount, eyeDetails.firstInjectionDate]);

  const handleShowImageClick = useCallback(
    async (id: number) => {
      const refetchResult = await mutateAsync([id]);
      if (refetchResult.success) openOctImgModal();
    },
    [mutateAsync, openOctImgModal]
  );

  const toolTipMessage = (x: string, y: string, z: string) => {
    return (
      <React.Fragment>
        <Typography variant="subtitle1" color="inherit">
          Serial No: {x}
        </Typography>
        <Typography variant="subtitle1" color="inherit">
          Lot No: {z}
        </Typography>
        <Typography variant="subtitle1" color="inherit">
          Expiry: {y.substring(NUM_4, NUM_6) + y.substring(0, NUM_4)}
        </Typography>
      </React.Fragment>
    );
  };

  const renderDataRow = useCallback(
    (row: TreatmentRecord, index: number) => {
      const {
        id,
        date,
        drugName,
        cst,
        va,
        heme,
        ped,
        irf,
        srf,
        editImage,
        serialNumber,
        expiryDate,
        lotNumber,
        treatedInstitute,
      } = row;
      const dateDisplay = dayjs(date).format("DD MMM YYYY");
      return (
        <Box
          key={id}
          sx={{
            padding: "16px",
            borderBottom: "1px solid #E0E0E0",
            width: "100%",
          }}
        >
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>OCT Image</Typography>
            <Button
              disabled={!editImage}
              variant="text"
              onClick={(e) => {
                handleShowImageClick(id);
              }}
              startIcon={<ImageIcon />}
            >
              View
            </Button>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>Date</Typography>
            <Typography sx={listValue}>{dateDisplay}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>Drug</Typography>
            {serialNumber ? (
              <Tooltip
                title={toolTipMessage(serialNumber, expiryDate, lotNumber)}
                key={index}
                enterTouchDelay={0}
                leaveTouchDelay={3000}
                placement="left"
              >
                <Typography style={{ fontStyle: "italic" }} sx={listValue}>
                  {drugName}
                </Typography>
              </Tooltip>
            ) : (
              <Typography sx={listValue}>
                {drugName ? drugName : "-"}
              </Typography>
            )}
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>Institute</Typography>
            <Typography sx={listValue}>{treatedInstitute}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Box sx={item2Style1}>
              <Typography sx={listHeader}>CST</Typography>
              <Typography sx={listValue}>{cst ? cst : "-"}</Typography>
            </Box>
            <Box sx={item2Style2}>
              <Typography sx={listHeader}>VA</Typography>
              <Typography sx={listValue}>{va ? va : "-"}</Typography>
            </Box>
          </Box>
          <Box sx={itemStyle}>
            <Box sx={item2Style1}>
              <Typography sx={listHeader}>HEME</Typography>
              <TreatmentOtherInfoSymbol checked={heme} />
            </Box>
            <Box sx={item2Style2}>
              <Typography sx={listHeader}>PED</Typography>
              <TreatmentOtherInfoSymbol checked={ped} />
            </Box>
          </Box>
          <Box sx={itemStyle}>
            <Box sx={item2Style1}>
              <Typography sx={listHeader}>IRF</Typography>
              <TreatmentOtherInfoSymbol checked={irf} />
            </Box>
            <Box sx={item2Style2}>
              <Typography sx={listHeader}>SRF</Typography>
              <TreatmentOtherInfoSymbol checked={srf} />
            </Box>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
            <SecondaryButton
              onClick={() => onRecordClick(id)}
              size="small"
              sx={buttonStyle}
            >
              Edit Details
            </SecondaryButton>
          </Box>
        </Box>
      );
    },
    [t, handleDeleteRowClick, onRecordClick]
  );

  return (
    <Box sx={{ width: "100%", flexGrow: 1, p: 1 }}>
      <SimpleModal
        header={obsoleteDetail.header}
        body={obsoleteDetail.body}
        open={isObsoleteModalOpen}
        handleClose={closeObsoleteModal}
        buttons={{
          positiveButtonIsLoading: isLoading,
          positiveAction: confirmObsoleteReactive,
        }}
      />
      <OctImageModalMobile
        open={isOctImgModalOpen}
        toggleModal={closeOCtImageModal}
        data={octImgData?.data ?? []}
      />

      <Grid>
        <TreatmentRecordHeaderMobile
          firstInjectionDate={firstInjectionDate}
          totalInjections={totalInjections}
          handleAddTreatmentRecordClick={handleAddTreatmentRecordClick}
        />
      </Grid>
      <Divider></Divider>
      <Box>{records.map(renderDataRow)}</Box>
    </Box>
  );
};

export default TreatmentRecordsMobile;
