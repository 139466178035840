import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { IconButton, Stack } from "@mui/material";

interface ExpandCollapseIconProps {
  orientation?: "vertical" | "horizontal";
  onUpLeftClick?: () => void;
  onDownRightClick?: () => void;
  showUpLeftIcon?: boolean;
  showDownRightIcon?: boolean;
}

const ExpandCollapseIcon = (props: ExpandCollapseIconProps) => {
  const {
    orientation = "vertical",
    onUpLeftClick = () => null,
    onDownRightClick = () => null,
    showUpLeftIcon = true,
    showDownRightIcon = true,
  } = props;

  const TEXT_SECONDARY = "text.secondary";
  const MAIN_PRIMARY = "primary.main";
  if (orientation === "horizontal") {
    return (
      <Stack direction="row" justifyContent="center" alignItems="center">
        <IconButton
          onClick={onUpLeftClick}
          disabled={!showUpLeftIcon}
          sx={{ padding: 0 }}
        >
          <KeyboardArrowLeftIcon
            fontSize="small"
            sx={{
              color:
                showUpLeftIcon && showDownRightIcon
                  ? TEXT_SECONDARY
                  : showUpLeftIcon
                  ? MAIN_PRIMARY
                  : "transparent",
            }}
          />
        </IconButton>
        <IconButton
          onClick={onDownRightClick}
          disabled={!showDownRightIcon}
          sx={{ padding: 0 }}
        >
          <KeyboardArrowRightIcon
            fontSize="small"
            sx={{
              color:
                showDownRightIcon && showUpLeftIcon
                  ? TEXT_SECONDARY
                  : showDownRightIcon
                  ? MAIN_PRIMARY
                  : "transparent",
            }}
          />
        </IconButton>
      </Stack>
    );
  }

  return (
    <Stack direction="column" justifyContent="center" alignItems="center">
      <IconButton
        onClick={onUpLeftClick}
        disabled={!showUpLeftIcon}
        sx={{ padding: 0 }}
      >
        <KeyboardArrowUpIcon
          sx={{ color: showUpLeftIcon ? TEXT_SECONDARY : "transparent" }}
        />
      </IconButton>
      <IconButton
        onClick={onDownRightClick}
        disabled={!showDownRightIcon}
        sx={{ padding: 0 }}
      >
        <KeyboardArrowDownIcon
          sx={{ color: showDownRightIcon ? MAIN_PRIMARY : "transparent" }}
        />
      </IconButton>
    </Stack>
  );
};

export default ExpandCollapseIcon;
