import * as yup from "yup";

const emailRegex =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const NUM_5 = 5;
const NUM_320 = 320;
const emailYup = yup
  .string()
  .min(NUM_5, "required min input 5 characters")
  .max(NUM_320, "required max input 320 characters")
  .email("required email format")
  .matches(emailRegex, "required email format")
  .required("required");

export default emailYup;
