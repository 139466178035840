import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/system";

type BaseButtonProps = ButtonProps;

const BaseButton = styled(Button)<BaseButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  fontWeight: "medium",
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  "&:disabled": {
    border: "0px solid #999999",
    backgroundColor: "#cccccc",
    color: "#666666",
  },
}));

export default BaseButton;
