import * as React from "react";
import { Popover, IconButton } from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { FilterPopoverProps } from "../type";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../utils/constants/ga";

const FilterPopover = (props: FilterPopoverProps) => {
  const { content, closePopover, filtered } = props;

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  React.useEffect(() => {
    if (closePopover !== 0) handleClose();
  }, [closePopover]);

  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
      ReactGA.event({
        category: CategoryType.Navigation,
        action: NavigationActionType.Dashboard_FilterPopover,
      });
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setAnchorEl(null);
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <React.Fragment>
      <IconButton onClick={handleClick}>
        {filtered ? (
          <FilterAltIcon sx={{ color: "primary.main" }} />
        ) : (
          <FilterAltOffIcon />
        )}
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "center",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {content}
      </Popover>
    </React.Fragment>
  );
};

export default FilterPopover;
