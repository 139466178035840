import { Typography, Grid, Box, Divider } from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import { isMobile } from "react-device-detect";

interface ConsentPageProps {
  isAlreadyJoined: boolean | null | undefined;
  handleDecline: () => void;
  participate: () => void;
}

const ConsentPage = (props: ConsentPageProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isAlreadyJoined, handleDecline, participate } = props;

  return (
    <Grid item xs={12}>
      <Typography
        variant="h4"
        sx={{
          color: "#FFF",
          fontWeight: "bold",
          mt: "4vh",
          textAlign: "center",
        }}
      >
        Care for Vision Subsidy Program
      </Typography>

      <Grid item sx={{ backgroundColor: "background.paper", mt: "4vh", mb: 2 }}>
        <Grid sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Care for Vision Subsidy Program Info
          </Typography>
          <Divider></Divider>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Program Introduction:
          </Typography>
          <Typography variant="body1">
            Neovascular age-related macular degeneration(nAMD) and Diabetic
            Macular Edema (DME) are the main causes of visual impairment in Hong
            Kong. However, patients often overlook the urgency of treatment.
            Delaying treatment can lead to severe consequences, including
            irreversible visual impairment or even permanent blindness.
            Unfortunately, patients often face a long wait in public hospitals
            to complete the initial phase of injections. Opting for private
            treatment may be financially challenging for patients. The purpose
            of this program is to provide financial assistance, enabling
            patients to receive early treatment from private doctors, and
            safeguarding eye health.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Program Objectives:
          </Typography>
          <Typography variant="body1">
            To subsidize patients under the Hospital Authority (HA) Hospitals to
            receive intravitreal injection treatment from private
            ophthalmologists. This aims to reduce patients' medical expenses and
            help them receive treatment promptly.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Eligibility Criteria:{" "}
          </Typography>
          <Box>
            <ol style={{ paddingLeft: "18px" }}>
              <li>Patients must be the Resident in Hong Kong.</li>
              <li>
                Patients from HA Eye Specialist Out-patient Clinics or under its
                jurisdiction and provide an appointment slip for Eye Specialist
                Out-patient Clinics follow-up issued by the HA Hospital.
              </li>
              <li>
                Patients with neovascular age-related macular degeneration(nAMD)
                or Diabetic Macular Edema (DME).
              </li>
              <li>
                Required to download the Care mobile application as the channel
                for application.
              </li>
              <li>
                Applying patients have to choose "The new generation of Dual
                Pathway Inhibition Intravitreal Injection Drug" for treatment.
              </li>
              <li>
                Patients have to choose the private ophthalmologists who
                participated in the program otherwise the subsidy will not be
                reimbursed.
              </li>
            </ol>
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Arrangements:
          </Typography>
          <Box>
            <ol style={{ paddingLeft: "18px" }}>
              <li>Program period: September 1, 2024 to August 31, 2025.</li>
              <li>
                Successful applicants can receive a subsidy of HKD $1,500 per
                injection, with a maximum of 3 injections per eye.
              </li>
              <li>
                Patients can apply each subsidy for one eye only. If the patient
                has a disease in both eyes, the applicant must submit two
                separate applications.
              </li>
              <li>
                Limited slots available on a first-come, first-served basis.{" "}
              </li>
              <li>
                Subsidized amounts cannot be claimed concurrently through
                medical insurance.
              </li>
            </ol>
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            How to Apply:
          </Typography>
          <Typography variant="body1">
            You can apply through the Care For Vision application platform. For
            any inquiries, please call <b>Lok Sin Tong at 9843 8404</b>.
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            What is Care For Vision?:{" "}
          </Typography>
          <Typography variant="body1">
            Care For Vision is an application platform developed by Roche Hong
            Kong Limited. Patients can use this platform to connect with their
            ophthalmologists, access their intravitreal injection records, gain
            a clearer understanding of injection dates and intervals, and learn
            more about eye health. Additionally, patients can participate in the
            treatment assistance program prepared to achieve early treatment
            effects and alleviate financial burdens.
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={"flex-end"}
        direction={"row"}
        alignItems={"center"}
        sx={{ backgroundColor: "background.paper", mb: 2, p: 3 }}
      >
        <SecondaryButton sx={{ mr: 2 }} onClick={handleDecline}>
          Decline
        </SecondaryButton>
        <BaseButton disabled={isAlreadyJoined === true} onClick={participate}>
          Participate
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default ConsentPage;
