import { Grid, Typography, Paper } from "@mui/material";
import pageImage from "../../assets/images/LoginPage/left-hand-side.svg";
import LoginFormMobile from "./LoginFormMobile";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

const LoginMobilePage = () => {
  const { t } = useTranslation();

  return (
    <Grid container sx={{ height: "100vh", width: "100vw" }}>
      <Grid
        container
        item
        justifyContent="center"
        alignItems="center"
        sx={{ backgroundColor: "background.paper" }}
      >
        <LoginFormMobile />
        <Paper
          sx={{
            position: "fixed",
            bottom: 10,
            right: 10,
          }}
          elevation={0}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
            }}
          >
            {t("loginPage.help")}
          </Typography>
        </Paper>
        <Paper
          sx={{
            position: "fixed",
            bottom: 40,
            left: 10,
            right: 10,
            backgroundColor: "primary.light",
            px: 1,
          }}
          elevation={0}
        >
          <Typography
            variant="body2"
            sx={{
              fontWeight: "bold",
              textAlign: "left",
            }}
          >
            {t("loginPage.regulatoryStatement")}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default LoginMobilePage;
