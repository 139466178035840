import { Typography, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import PatientDiseaseTypeChart from "./Patient-DiseaseType-Chart";
import PatientTreatmentIntervalChart from "./Eyes-Treatment-Interval-Chart";
import VisualActivityOutcomeChart from "./Visual-Activity-Outcome-Chart";
import PatientStatusChart from "./Eyes-Status-Chart";
import DrugComparisonChart from "./Drug-Comparison-Chart";
import PspIndicator from "../PspIndicator";
import { useEffect } from "react";
import { userGetData } from "../../services/user";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";

const DashboardPage = () => {
  const { t } = useTranslation();

  const { data: userData } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const pspIndicator = userData?.data?.joinedPsp;

  useEffect(() => {
    document.title = "InnoVision - Dashboard";
    window.scroll(0, 0);
  }, []);

  return (
    <Grid
      container
      item
      xs={12}
      columnSpacing={{ xs: 0, sm: 0, md: 1.5 }}
      rowSpacing={{ xs: 1, sm: 1, md: 1.5 }}
      sx={{
        padding: "1%",
      }}
      flexDirection="row"
      justifyContent="flex-start"
      alignItems="stretch"
    >
      {(pspIndicator === null || pspIndicator === false) && (
        <Grid item xs={12}>
          <PspIndicator pspIndicator={pspIndicator}></PspIndicator>
        </Grid>
      )}

      <Grid item xs={12}>
        <Typography
          variant="h5"
          textAlign={"left"}
          sx={{
            fontWeight: "bold",
          }}
        >
          {t("dashboardPage.header")}
        </Typography>
      </Grid>

      <Grid item md={4} sm={12}>
        <PatientDiseaseTypeChart />
      </Grid>
      <Grid item md={4} sm={12}>
        <PatientTreatmentIntervalChart />
      </Grid>
      <Grid item md={4} sm={12}>
        <VisualActivityOutcomeChart />
      </Grid>
      <Grid item md={8} sm={12}>
        <DrugComparisonChart />
      </Grid>
      <Grid item md={4} sm={12}>
        <PatientStatusChart />
      </Grid>
    </Grid>
  );
};

export default DashboardPage;
