import { Grid, Typography, Box, Divider, Avatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../button/SecondaryButton";
import BaseButton from "../../button/BaseButton";
import { RequestAccessProps } from "../../../../utils/types/services/patients";

const itemStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "2px 0",
  mb: 1,
} as const;

const listHeader = {
  color: "#737373",
  fontSize: "16px",
  fontWeight: "400",
} as const;

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
} as const;

interface RequestAccessScreenProps {
  accessDetails: RequestAccessProps[] | undefined;
  handleCancel: () => void;
  handleDone: () => void;
}

const RequestAccessScreen = (props: RequestAccessScreenProps) => {
  const { accessDetails, handleCancel, handleDone } = props;

  const initialData = {
    id: -1,
    pFirstName: null,
    pLastName: null,
    mobile: "",
    pRecogId: "",
    hcp_id: -1,
    first_name: "",
    last_name: "",
    preferred_name: "",
    avatar_url: null,
    institute_name: "",
  };

  const detailsToDisplay = accessDetails ? accessDetails[0] : initialData;

  const { t } = useTranslation();

  return (
    <Grid
      container
      flexDirection="row"
      sx={{ minHeight: "95%", mt: 2, width: 1 }}
    >
      <Grid sx={{ width: 1 }}>
        <Grid>
          <Typography sx={{ pb: 2 }}>Patient Information</Typography>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>Patient ID</Typography>
            <Typography sx={listValue}>{detailsToDisplay.id}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography sx={listHeader}>Mobile No.</Typography>
            <Typography sx={listValue}>{detailsToDisplay.mobile}</Typography>
          </Box>
        </Grid>
        <Grid item sx={{ width: 1, py: 3 }}>
          <Divider />
        </Grid>
        <Grid sx={{ width: 1 }}>
          <Typography sx={{ pb: 2 }}>Primary Treating Physician</Typography>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {" "}
            <Avatar
              alt="Profile-Image"
              src={detailsToDisplay?.avatar_url ?? undefined}
            >
              {(
                detailsToDisplay?.preferred_name?.charAt(0) ?? ""
              ).toUpperCase() +
                (detailsToDisplay?.last_name?.charAt(0) ?? "").toUpperCase()}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {detailsToDisplay.preferred_name} {detailsToDisplay.last_name}
              </Typography>
              <Typography variant="subtitle2" sx={{ color: "#544F4F" }}>
                {detailsToDisplay.institute_name}
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid
        container
        item
        sx={{ width: 1 }}
        direction="row"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Box sx={{ width: 1, display: "flex", justifyContent: "end" }}>
          <SecondaryButton onClick={handleCancel} sx={{ mr: 2 }}>
            {t("singlePharse.cancel")}
          </SecondaryButton>
          <BaseButton type="submit" onClick={handleDone}>
            Done
          </BaseButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default RequestAccessScreen;
