import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import api from "./api";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";

const APPLY_RETRY = "Retry Will Apply";
const STATUS_200 = 200;
type TYPE_2 = 2;

export const getPatientByDiseaseType = async ({
  fromDate,
  toDate,
  type,
}: {
  fromDate?: string | null;
  toDate?: string | null;
  type: 1 | TYPE_2;
}): Promise<{
  success: boolean;
  status?: number;
  data?: {
    diseaseTypeName?: string;
    osDiseaseTypeName?: string;
    odDiseaseTypeName?: string;
    count: number;
  }[];
}> => {
  let result = null;
  try {
    result = await api.get(
      API_ROUTE_FULL_PATHS.dashboardGetPatientByDiseaseType,
      {
        params: {
          type,
          fromDate,
          toDate,
        },
      },
    );
    if (result.status === STATUS_200) {
      return {
        success: true,
        data: result.data.data,
      };
    }

    throw new Error(APPLY_RETRY);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result });
  }
};

export const getEyesByTreatmentInterval = async (): Promise<{
  success: boolean;
  status?: number;
  data?: {
    weekDiffDesc: string;
    diseaseTypeName: string | null;
    weekDiffCount: number;
  }[];
}> => {
  let result = null;
  try {
    result = await api.get(
      API_ROUTE_FULL_PATHS.dashboardGetEyesByTreatmentInterval,
    );
    if (result.status === STATUS_200) {
      return {
        success: true,
        data: result.data.data,
      };
    }

    throw new Error(APPLY_RETRY);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result });
  }
};

export const getVisualActivityOutcome = async (): Promise<{
  success: boolean;
  status?: number;
  data?: {
    vaOutcomeDesc: string;
    diseaseTypeName: string | null;
    vaOutcomeCount: number;
  }[];
}> => {
  let result = null;
  try {
    result = await api.get(
      API_ROUTE_FULL_PATHS.dashboardGetVisualActivityOutcome,
    );
    if (result.status === STATUS_200) {
      return {
        success: true,
        data: result.data.data,
      };
    }

    throw new Error(APPLY_RETRY);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result });
  }
};

export const getDrugComparisonByVA = async (): Promise<{
  success: boolean;
  status?: number;
  data?: {
    diseaseTypesName: string;
    drugName: string;
    treatmentPhasesAdjName: "Loading" | "Maintenance";
    status: "Improved" | "Stable" | "Worsen";
    count: number;
  }[];
}> => {
  let result = null;
  try {
    result = await api.get(API_ROUTE_FULL_PATHS.dashboardGetDrugComparisonByVA);
    if (result.status === STATUS_200) {
      return {
        success: true,
        data: result.data.data,
      };
    }

    throw new Error(APPLY_RETRY);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result });
  }
};

export const getEyesByStatus = async (): Promise<{
  success: boolean;
  status?: number;
  data?: {
    diseaseTypeName: string;
    drugName: string;
    status: string;
    statusCount: number;
  }[];
}> => {
  let result = null;
  try {
    result = await api.get(API_ROUTE_FULL_PATHS.dashboardGetEyesByStatus);
    if (result.status === STATUS_200) {
      return {
        success: true,
        data: result.data.data,
      };
    }

    throw new Error(APPLY_RETRY);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result });
  }
};
