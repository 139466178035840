import { Avatar as MAvatar, IconButton } from "@mui/material";
import { useCallback, useRef } from "react";
import ProfilePopover from "./ProfilePopover";
import PopoverState from "../../../../utils/types/ui/PopoverState";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../../utils/constants/ga";
import { UserProps } from "../../../../utils/types/services/user";
import { PickType } from "../../../../utils/types/services/patients";

interface AvatarProps {
  profileImgUrl: string | undefined;
  profileImgAltName: PickType<UserProps, "lastName">;
  setPopoverState: (state: PopoverState) => void;
  handlePopoverClose: () => void;
}

const Avatar = (props: AvatarProps) => {
  const {
    profileImgUrl,
    profileImgAltName,
    setPopoverState,
    handlePopoverClose,
  } = props;

  const iconRef = useRef<HTMLButtonElement>(null);

  const handleClick = useCallback(() => {
    setPopoverState({
      elementRef: iconRef.current,
      content: <ProfilePopover handlePopoverClose={handlePopoverClose} />,
      anchorOrigin: {
        vertical: "bottom",
        horizontal: "left",
      },
      maxWidth: 250,
    });

    ReactGA.event({
      category: CategoryType.Navigation,
      action: NavigationActionType.AppBar_AvatarAction,
    });
  }, [handlePopoverClose]);

  return (
    <IconButton
      ref={iconRef}
      size="large"
      aria-label="account of current user"
      aria-controls="menu-appbar"
      aria-haspopup="true"
      onClick={handleClick}
      color="inherit"
    >
      <MAvatar alt="Profile-Image" src={profileImgUrl}>
        {profileImgAltName}
      </MAvatar>
    </IconButton>
  );
};

export default Avatar;
