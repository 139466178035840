const NUM_10 = 10;
const NUM_60 = 60;

export const timeLimits = {
  twoFactorAuthResendSeconds:
    process.env.NODE_ENV === "production" ? NUM_60 : NUM_10,
  maxHumanAge: 119,
} as const;

export const MONTHS = [
  { index: 1, displayText: "Jan" },
  { index: 2, displayText: "Feb" },
  { index: 3, displayText: "Mar" },
  { index: 4, displayText: "Apr" },
  { index: 5, displayText: "May" },
  { index: 6, displayText: "Jun" },
  { index: 7, displayText: "Jul" },
  { index: 8, displayText: "Aug" },
  { index: 9, displayText: "Sep" },
  { index: 10, displayText: "Oct" },
  { index: 11, displayText: "Nov" },
  { index: 12, displayText: "Dec" },
] as const;

export const TIMES = [
  "1:00 AM",
  "2:00 AM",
  "3:00 AM",
  "4:00 AM",
  "5:00 AM",
  "6:00 AM",
  "7:00 AM",
  "8:00 AM",
  "9:00 AM",
  "10:00 AM",
  "11:00 AM",
  "12:00 PM",
  "1:00 PM",
  "2:00 PM",
  "3:00 PM",
  "4:00 PM",
  "5:00 PM",
  "6:00 PM",
  "7:00 PM",
  "8:00 PM",
  "9:00 PM",
  "10:00 PM",
  "11:00 PM",
  "12:00 AM",
] as const;
