import { Modal, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useFormContext } from "react-hook-form";
import FormInput from "../../shareComponents/form/FormInput";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { ModalViewType } from "../../../utils/constants/ga";
import ModalBoldTitle from "../../shareComponents/modal/BoldTitle";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { isDisabled } from "@testing-library/user-event/dist/utils";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
} as const;

interface ConfirmPasswordModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  submitWithPassword: () => Promise<void>;
}

const ConfirmPasswordModal = (props: ConfirmPasswordModalProps) => {
  const { isOpen, toggleModal, submitWithPassword } = props;
  const { t } = useTranslation();
  const [isEmptyPW, setIsEmptyPW] = useState(true);

  const { control, formState, getValues } = useFormContext();

  useEffect(() => {
    if (isOpen) {
      setIsEmptyPW(true);
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.ConfirmPasswordModal}`,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <ModalBoldTitle title={t("userProfilePage.updateMobilePassword")} />
        <form style={{ width: "100%" }}>
          <Grid item xs={12}>
            <FormInput
              control={control}
              name="password"
              label={t("singlePharse.password")}
              fullWidth
              type="password"
              autoComplete="current-password"
              onKeyUp={(e) => {
                setIsEmptyPW(
                  (e.target as HTMLInputElement).value === "" ? true : false
                );
                if (e.key === "Enter") {
                  submitWithPassword();
                }
              }}
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            alignItems="center"
            direction={"row"}
            justifyContent="flex-end"
            sx={{ mt: 1 }}
          >
            <SecondaryButton
              sx={{ mr: 1 }}
              onClick={toggleModal}
              disabled={formState.isSubmitting}
            >
              {t("singlePharse.cancel")}
            </SecondaryButton>
            <BaseButton
              onClick={submitWithPassword}
              disabled={
                !formState.dirtyFields["password"] ||
                formState.isSubmitting ||
                isEmptyPW
              }
            >
              {t("singlePharse.confirm")}
            </BaseButton>
          </Grid>
        </form>
      </Grid>
    </Modal>
  );
};

export default ConfirmPasswordModal;
