import { Control } from "react-hook-form";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import FormDropzone from "../../../shareComponents/form/FormDropzone";
import TreatmentOtherInfoRadioInput from "./TreatmentOtherInfoRadioInput";
import ClearIcon from "@mui/icons-material/Clear";
import { Grid, IconButton, Stack, Typography, Box } from "@mui/material";
import { useCallback } from "react";

interface AdditionalDetailsFormProps {
  imageFiles: File[];
  imageEditFile: string | null;
  removeImage: (id: string) => void;
  onDropImage: (files: File[]) => void;
  control: Control<TreatmentRecordFormState>;
}

const AdditionalDetailsForm = (props: AdditionalDetailsFormProps) => {
  const { control, imageFiles, removeImage, onDropImage, imageEditFile } =
    props;

  const NUM_1024 = 1024;
  const NUM_10 = 10;

  const onDropImagefiles = (files: File[]) => {
    onDropImage(files);
  };

  const filePreview = useCallback(
    ({ name, size }: { name: string; size: number | null }, index: number) => (
      <Grid
        key={index}
        container
        sx={{
          width: 1,
          background:
            "linear-gradient(0deg, rgba(0, 102, 204, 0.05), rgba(0, 102, 204, 0.05)), #FFFFFF;",
          p: 2,
        }}
      >
        <Grid container item justifyContent="flex-end">
          <IconButton onClick={() => removeImage(name)}>
            <ClearIcon />
          </IconButton>
        </Grid>
        <Stack direction="row" spacing={2}>
          <Typography variant="subtitle1">{name}</Typography>
          <Typography variant="subtitle1" color="text.secondary">
            {size !== null && `${(size / (NUM_1024 * NUM_1024)).toFixed(1)}MB`}
          </Typography>
        </Stack>
      </Grid>
    ),
    [removeImage]
  );

  return (
    <Stack>
      <TreatmentOtherInfoRadioInput
        label="HEME"
        name="heme"
        control={control}
      />
      <TreatmentOtherInfoRadioInput label="PED" name="ped" control={control} />
      <TreatmentOtherInfoRadioInput
        label="IRF/IRC"
        name="irf"
        control={control}
      />
      <TreatmentOtherInfoRadioInput label="SRF" name="srf" control={control} />

      <FormDropzone
        control={control}
        onDrop={onDropImagefiles}
        name="images"
        multiple={false}
      />

      <Stack spacing={1} sx={{ marginTop: 1 }}>
        {imageFiles.map(filePreview)}
        {imageEditFile &&
          filePreview({ name: imageEditFile, size: null }, NUM_10)}
      </Stack>
    </Stack>
  );
};

export default AdditionalDetailsForm;
