import { Typography, Grid, Link } from "@mui/material";
import BaseButton from "../../../shareComponents/button/BaseButton";
import { useTranslation } from "react-i18next";
import { useCallback, useState, useMemo } from "react";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../../utils/constants/ga";
import { useMutation } from "@tanstack/react-query";
import { AEDataProps, reportAE } from "../../../../services/treatmentRecord";
import BaseInput from "../../../shareComponents/input/BaseInput";
import BaseCheckBox from "../../../shareComponents/checkbox/BaseCheckBox";
import SecondaryButton from "../../../shareComponents/button/SecondaryButton";

type PAGE_0 = 0;
type PAGE_1 = 1;
type PAGE_2 = 2;
type PAGE_3 = 3;

type PAGES = PAGE_0 | PAGE_1 | PAGE_2 | PAGE_3;
interface InputDataProps {
  page: PAGES;
  freeText: string;
  furtherContact: boolean;
}

interface AEModalProps {
  modalData: AEDataProps | null;
  handleClose: () => void;
}

const AEModal = (props: AEModalProps) => {
  const { modalData, handleClose } = props;
  const { t } = useTranslation();
  const NUM_3 = 3;
  const NUM_2 = 2;

  const { data, isLoading, mutateAsync } = useMutation(reportAE);

  const [inputData, setInputData] = useState<InputDataProps>({
    page: 0,
    freeText: "",
    furtherContact: false,
  });

  const goToPage = useCallback((page: typeof inputData.page) => {
    setInputData((val) => ({ ...val, page }));
  }, []);

  const onClickPositiveAction = useCallback(async () => {
    if (inputData.page === 0) {
      goToPage(1);
      return;
    }

    if (inputData.page === 1) {
      const result = await mutateAsync({
        freeText: inputData.freeText,
        furtherContact: !inputData.furtherContact,
        treatmentId: modalData?.treatmentId!,
      });
      if (result.success) {
        goToPage(NUM_3);
      }
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Patient_Report_AE,
        value: modalData?.treatmentId!,
      });
      return;
    }

    handleClose();
  }, [inputData, modalData, handleClose, goToPage]);

  const onClickNegativeAction = useCallback(async () => {
    if (inputData.page === 0) return goToPage(NUM_2);

    if (inputData.page === 1) return goToPage(0);

    if (inputData.page === NUM_2) return goToPage(0);

    alert("action not available now");
  }, [inputData.page, handleClose, goToPage]);

  const [positiveButtonText, negativeButtonText] = useMemo(() => {
    if (inputData.page === 0) return ["singlePharse.yes", "singlePharse.no"];

    if (inputData.page === 1)
      return ["singlePharse.submit", "singlePharse.back"];

    if (inputData.page === NUM_2)
      return ["singlePharse.ok", "singlePharse.back"];

    return ["singlePharse.ok", null];
  }, [t, inputData.page]);

  const Page0 = useCallback(() => {
    return (
      <Grid container alignItems="center" justifyContent="center">
        <Typography
          sx={{ textAlign: "center", marginBottom: 3 }}
          variant="subtitle1"
        >
          {t("aeReportModal.page0.text1")}
          <Typography sx={{ textAlign: "center", fontWeight: "bold" }}>
            {t("aeReportModal.page0.text1Reason").replace(
              "@messageToHCP",
              modalData?.remark ?? ""
            )}
          </Typography>
        </Typography>
        <Typography
          sx={{ textAlign: "center", marginBottom: 3 }}
          variant="subtitle1"
        >
          {t("aeReportModal.page0.text2")}
        </Typography>
        <Typography
          sx={{ textAlign: "center", fontWeight: "bold" }}
          variant="h6"
        >
          {t("aeReportModal.page0.text3")}
        </Typography>
      </Grid>
    );
  }, [t]);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputData((val) => ({
        ...val,
        freeText: event.target.value,
      }));
    },
    []
  );

  const handleCheckBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputData((val) => ({
        ...val,
        furtherContact: event.target.checked,
      }));
    },
    []
  );

  const Page1 = useCallback(() => {
    return (
      <Grid>
        <Typography sx={{ textAlign: "center" }} variant="subtitle1">
          {t("aeReportModal.page1.text1")}
        </Typography>
        <Typography sx={{ textAlign: "center" }} variant="subtitle2">
          {t("aeReportModal.page1.text1_1")}
        </Typography>

        <BaseInput
          placeholder={t("aeReportModal.page1.supplementaryText")}
          fullWidth
          onChange={handleInputChange}
          multiline
          inputProps={{ maxLength: 1000 }}
        />

        <Grid container flexDirection={"row"}>
          <Grid>
            <BaseCheckBox onChange={handleCheckBoxChange} />
          </Grid>
          <Grid xs item>
            <Typography sx={{ textAlign: "left" }} variant="subtitle1">
              {t("aeReportModal.page1.text2")}
              <b>{t("aeReportModal.page1.text3")}</b>
              {t("aeReportModal.page1.text4")}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [t, handleInputChange, handleCheckBoxChange]);

  const Page2 = useCallback(() => {
    return (
      <Grid>
        <Typography sx={{ textAlign: "left" }} variant="subtitle1">
          {t("aeReportModal.page2.text1")}
          <Link href="#">{t("aeReportModal.page2.email")}</Link>
          {t("aeReportModal.page2.text2")}
        </Typography>
      </Grid>
    );
  }, [t]);

  const Page3 = useCallback(() => {
    return (
      <Grid>
        <Typography sx={{ textAlign: "center" }} variant="subtitle1">
          {t("aeReportModal.page3.text1")}
          <b>[{data?.data?.aeResult?.pvReferenceNo ?? "N/A"}]</b>.
        </Typography>
      </Grid>
    );
  }, [t, data]);

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          p: 1,
          maxHeight: "50vh",
          overflow: "auto",
        }}
      >
        {inputData.page === 0 && <Page0 />}
        {inputData.page === 1 && <Page1 />}
        {inputData.page === NUM_2 && <Page2 />}
        {inputData.page === NUM_3 && <Page3 />}
      </Grid>

      <Grid
        container
        item
        xs={12}
        alignItems="center"
        direction={"row"}
        justifyContent="space-around"
        sx={{ mt: 1 }}
      >
        {positiveButtonText && (
          <BaseButton
            sx={{ px: 10, mb: 2 }}
            onClick={onClickPositiveAction}
            disabled={isLoading}
          >
            {t(positiveButtonText)}
          </BaseButton>
        )}

        {negativeButtonText && (
          <SecondaryButton
            sx={{ px: 10, mb: 2 }}
            onClick={onClickNegativeAction}
            disabled={isLoading}
          >
            {t(negativeButtonText)}
          </SecondaryButton>
        )}
      </Grid>
    </Grid>
  );
};

export default AEModal;
