import { Modal, Grid, IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import ModalBoldTitle from "../../../shareComponents/modal/BoldTitle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ScannerComponent from "../../../shareComponents/ScannerComponent/ScannerComponent";
import ViewSampleModal from "./ViewSampleModal";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
} as const;

interface ProductScanModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  onUpdate: (arg0: unknown, arg1?: unknown) => void;
}

const ProductScanModal = (props: ProductScanModalProps) => {
  const { isOpen, toggleModal, onUpdate } = props;
  const { t } = useTranslation();
  const [viewSample, setViewSample] = useState(false);
  const handleSampleClick = () => {
    setViewSample(true);
  };

  const handleViewToggle = () => {
    setViewSample(!viewSample);
  };

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <ViewSampleModal isOpen={viewSample} toggleModal={handleViewToggle} />
        <Grid sx={{ display: "flex", height: "35px", width: "100%" }}>
          <Grid item xs={2} sm={2}>
            <IconButton aria-label="back" size="small" onClick={toggleModal}>
              <ArrowBackIcon sx={{ color: "black" }} />
            </IconButton>
          </Grid>
          <Grid item xs={8} sm={8} sx={{ textAlign: "center" }}>
            <ModalBoldTitle title={t("productScan.scanProductTitle")} />
          </Grid>
          <Grid item xs={2} sm={2}></Grid>
        </Grid>
        <Grid sx={{height: "42%",  mb: "45px", width: "100%" }}>
        <ScannerComponent
            onUpdate={onUpdate}
            handleSampleClick={handleSampleClick}
          />
        </Grid>
      </Grid>
    </Modal>
  );
};

export default ProductScanModal;
