import {
  Control,
  FieldPath,
  FieldValues,
  Path,
  useController,
  UseControllerReturn,
} from "react-hook-form";

type ControlledFormElementProps<T extends FieldValues = FieldValues> = {
  children: (
    controllerReturnValue: UseControllerReturn<
      T,
      Path<T> & (string | undefined)
    >
  ) => React.JSX.Element;
  name: FieldPath<T>;
  control: Control<T>;
};

const ControlledFormElement = <T extends FieldValues>(
  props: ControlledFormElementProps<T>
) => {
  const { children, control, name } = props;

  const useControllerReturnValues = useController({
    control,
    name,
  });

  return children(useControllerReturnValues);
};

export default ControlledFormElement;
