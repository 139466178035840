import { Modal, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useState } from "react";
import { deletePatient } from "../../../services/patient";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import ModalBoldTitle from "../../shareComponents/modal/BoldTitle";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { Theme } from "@mui/material/styles";

const style = (theme: Theme) =>
  ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  } as const);

interface CancelConfirmModalProps {
  isOpen: boolean;
  toggleCancelConfirmModal: () => void;
  handleConfirmReject: () => void;
}

const CancelConfirmModal = (props: CancelConfirmModalProps) => {
  const { isOpen, toggleCancelConfirmModal, handleConfirmReject } = props;

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleReject = async () => {
    setIsLoading(true);
    await handleConfirmReject();
    setIsLoading(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={toggleCancelConfirmModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <ModalBoldTitle title="Are you sure you want to decline the PSP?" />

        <Grid
          item
          xs={12}
          sx={{
            pt: 1,
            pb: 1,
            maxHeight: "50vh",
            overflow: "auto",
          }}
        >
          <Grid item xs={12}>
            <Typography id="modal-modal-description">
              You will still able to participate it later via the profile
              setting page.
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction={"row"}
          sx={{ mt: 1, gap: 2 }}
        >
          <BaseButton disabled={isLoading} onClick={handleReject}>
            Yes
          </BaseButton>
          <SecondaryButton onClick={toggleCancelConfirmModal}>
            No
          </SecondaryButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default CancelConfirmModal;
