import Webcam from "react-webcam";

import { useCallback, useRef, useState, useEffect } from "react"; // import useCallback
import { useTranslation } from "react-i18next";
import BaseButton from "../button/BaseButton";
import { Box, Grid, Select, MenuItem } from "@mui/material";

interface CustomWebcamProps {
  onCapture: (image: any) => void;
  width?: number | string;
  height?: number | string;
}

const CustomWebcam = (props: CustomWebcamProps) => {
  const { onCapture, width, height } = props;
  const { t } = useTranslation();

  const webcamRef = useRef<Webcam | null>(null);
  const canvasRef = useRef<any>(null);
  const NUM_1_25 = 1.25;
  const NUM_1_5 = 1.5;
  const NUM_2 = 2;
  const NUM_1_75 = 1.75;
  const NUM_2000 = 2000;
  const NUM_33 = 33;
  const zoomArray: number[] = [1, NUM_1_25, NUM_1_5, NUM_1_75, NUM_2];

  const [imgSrc, setImgSrc] = useState(null);
  const [scale, setScale] = useState<number>(1);
  const [scaleStr, setScaleStr] = useState<string>("1");
  const captureRef = useRef<null | HTMLDivElement>(null);

  setTimeout(() => {
    captureRef.current?.scrollIntoView({ block: "end", behavior: "smooth" });
  }, NUM_2000);

  const drawIb = (ss: number) => {
    const video = webcamRef.current;
    const canvas = canvasRef.current;
    if (video && canvas) {
      const ctx = canvas.getContext("2d");
      const v_video = video.video;

      canvas.width = v_video ? v_video.videoWidth : 0;
      canvas.height = v_video ? v_video.videoHeight : 0;
      ctx.scale(ss, ss);

      ctx.drawImage(video.video, 0, 0, canvas.width, canvas.height);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      drawIb(scale ? scale : 1);
    }, NUM_33);

    return () => clearInterval(interval);
  }, [scale]);
  // create a capture function
  const capture = useCallback(() => {
    const imageSrc = canvasRef.current.toDataURL("image/png");
    setImgSrc(imageSrc);
    onCapture(imageSrc);
  }, [canvasRef]);

  const handleZoom = (val: string) => {
    const valNum = parseFloat(val);
    setScale(valNum);
    setScaleStr(val);
  };

  const videoConstraints = {
    facingMode: "environment",
  };

  const retake = () => {
    setImgSrc(null);
    onCapture(null);
  };

  return (
    <Box className="container">
      {imgSrc ? (
        <img src={imgSrc} alt="webcam" style={{ width, height }} />
      ) : (
        <Grid>
          <Box sx={{}}>
            <Webcam
              height="0%"
              width="0%"
              ref={webcamRef}
              muted={false}
              videoConstraints={videoConstraints}
            />
          </Box>
          <canvas ref={canvasRef} style={{ width, height }}></canvas>
        </Grid>
      )}
      <Box
        className="btn-container"
        ref={captureRef}
        sx={{
          width: "100%",
          mb: 2,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <p>Zoom : </p>
          <Select
            labelId="zoom"
            value={scaleStr}
            sx={{ width: 100, ml: 1 }}
            onChange={(e) => {
              handleZoom(e.target.value);
            }}
          >
            {zoomArray.map((val) => (
              <MenuItem key={val} value={val}>
                {val}
              </MenuItem>
            ))}
          </Select>
        </Box>
        <Box>
          {imgSrc ? (
            <BaseButton onClick={retake}>{t("captureImage.retake")}</BaseButton>
          ) : (
            <BaseButton onClick={capture}>
              {t("captureImage.capture")}
            </BaseButton>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomWebcam;
