import { Modal, Grid, IconButton, Typography, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import qrExample from "../../../../assets/images/PatientDetailPage/qrExample.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  minHeight: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
} as const;

interface ViewSampleModalProps {
  isOpen: boolean;
  toggleModal: () => void;
}

const ViewSampleModal = (props: ViewSampleModalProps) => {
  const { isOpen, toggleModal } = props;
  const { t } = useTranslation();

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <Typography variant="subtitle1">
            {" "}
            {t("productScan.sampleQrNote")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
          <img
            src={qrExample}
            alt="sample image"
            style={{ width: "100%", objectFit: "contain" }}
          />
        </Box>
      </Grid>
    </Modal>
  );
};

export default ViewSampleModal;
