import { Grid, Box, Typography, Button, Avatar } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ClearIcon from "@mui/icons-material/Clear";
import { OwnerHcpsProps } from "../../../../utils/types/services/hcp";

interface UsersWithAccessProps {
  usersList: OwnerHcpsProps[] | [];
  userId: number | undefined;
  handleRemoveAccess: (id: number) => void;
}

const UsersWithAccess = (props: UsersWithAccessProps) => {
  const { usersList, userId, handleRemoveAccess } = props;

  let ownerDetails: OwnerHcpsProps = {
    ownerId: -1,
    first_name: "",
    last_name: "",
    preferred_name: "",
    avatar_url: null,
    institute_name: [],
  };

  let usersListInOrder = usersList.filter((items: OwnerHcpsProps) => {
    if (items.ownerId === userId) {
      ownerDetails = items;
    }
    return items.ownerId !== userId;
  });

  usersListInOrder = [ownerDetails, ...usersListInOrder];

  const { t } = useTranslation();

  const renderlistItems = useCallback(
    (row: OwnerHcpsProps, index: number) => {
      const { ownerId, first_name, last_name, avatar_url, institute_name } =
        row;
      const access = userId === ownerId ? "Owner" : "Editor";
      return (
        <Box
          key={ownerId}
          sx={{
            mt: 4,
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar alt="Profile-Image" src={avatar_url ?? undefined}>
              {(first_name?.charAt(0) ?? "").toUpperCase() +
                (last_name.charAt(0) ?? "").toUpperCase()}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {first_name + " " + last_name}
              </Typography>
              <Typography sx={{ color: "#544F4F" }}>
                {institute_name}
              </Typography>
            </Box>
          </Box>
          <Box>
            {access === "Owner" && (
              <Typography
                sx={{
                  fontStyle: "italic",
                  mr: 1,
                  color: "#9D948E",
                  textAlign: "right",
                }}
              >
                Primary Treating Physician
              </Typography>
            )}
            {access !== "Owner" && (
              <Button
                variant="text"
                sx={{ color: "#000000" }}
                startIcon={<ClearIcon />}
                onClick={() => handleRemoveAccess(ownerId)}
              >
                Remove
              </Button>
            )}
          </Box>
        </Box>
      );
    },
    [t]
  );

  return (
    <Grid sx={{ width: 1, maxHeight: "68vh", overflow: "auto" }}>
      {usersListInOrder.length && usersListInOrder.map(renderlistItems)}
    </Grid>
  );
};

export default UsersWithAccess;
