const toastIds = {
  NETWORK_ERROR: "NETWORK_ERROR",
  UNAUTHORIZED: "UNAUTHORIZED",
  UNAUTHORIZED2: "UNAUTHORIZED2",
  FORBIDDEN: "FORBIDDEN",
  FORBIDDEN2: "FORBIDDEN2",
  BADREQUEST: "BADREQUEST",
  NOTALLOWED: "NOTALLOWED",
  CONFLICTS: "CONFLICTS",
  CONFLICTS2: "CONFLICTS2",
  GONE: "GONE",
  GONE2: "GONE2",
  TOOMANYREQ: "TOOMANYREQ",
  TOOMANYREQ2: "TOOMANYREQ2",
  INTERNAL_SERVER_ERROR: "Internal Server Error",
  UNEXPECTED_ERROR: "UNEXPECTED ERROR",
  OCTIMGMAX2: "OCTIMGMAX2",
  ADD_TREATMENT: "ADD_TREATMENT",
  UNDEFINED: "UNDEFINED",
} as const;

export default toastIds;
