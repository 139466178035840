import { Avatar, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import {
  ChangeEvent,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { deleteAvatar, uploadAvatar } from "../../../services/user";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import BaseButton from "../../shareComponents/button/BaseButton";
import { MAX_UPLOAD_FILE_SIZE } from "../../../utils/constants/general";

interface ActionTypeProps {
  type: "upload" | "delete";
  value: number;
}

const UserAvatar = () => {
  const { t } = useTranslation();
  const uploadRef = createRef<HTMLInputElement>();
  const FLEX_START = "flex-start";
  const STATUS_401 = 401;

  const [uploadFormData, setUploadFormData] = useState<FormData | null>(null);
  const [actionType, setActionType] = useState<ActionTypeProps>({
    type: "upload",
    value: 0,
  });

  const refetchData = useCallback(() => {
    if (actionType.type === "upload") {
      return uploadAvatar(uploadFormData);
    }
    if (actionType.type === "delete") {
      return deleteAvatar();
    }
  }, [actionType.type, uploadFormData]);

  const { data, refetch, isRefetching } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    refetchData,
    {
      structuralSharing: (oldData, newData) => {
        if (newData?.success || newData?.status === STATUS_401) return newData;
        return oldData;
      },
    }
  );

  const onAvatarUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement> | undefined) => {
      const file = event?.target.files;

      if (file === undefined || file === null || file?.length === 0) return;
      if (file[0].size >= MAX_UPLOAD_FILE_SIZE) {
        alert(t("userProfilePage.uploadNewAvatarExceedMaxSize"));
        return;
      }
      const formData = new FormData();
      formData.append("userAvatar", file[0]);
      setUploadFormData(formData);
      setActionType((val) => ({ type: "upload", value: ++val.value }));
    },
    [t]
  );

  const onAvatarDelete = useCallback(() => {
    setActionType((val) => ({ type: "delete", value: ++val.value }));
  }, []);

  useEffect(() => {
    if (actionType.value === 0) return;
    refetch();
    if (actionType.type === "upload") {
      setUploadFormData(null);
    }
  }, [actionType.value]);

  return (
    <Grid
      container
      justifyContent={"center"}
      direction={"row"}
      alignItems={"center"}
      sx={{
        backgroundColor: "background.paper",
        padding: "3%",
        marginBottom: "2%",
      }}
      spacing={{ xs: 1, sm: 1 }}
    >
      <Grid
        container
        item
        xs={12}
        sm={12}
        md={6}
        justifyContent={FLEX_START}
        direction={"row"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid item>
          <Avatar
            alt="Profile-Image"
            src={data?.data?.image ?? undefined}
            sx={{ height: "12vh", width: "12vh" }}
          >
            {(data?.data?.nickName?.charAt(0) ?? "").toUpperCase() +
              (data?.data?.lastName.charAt(0) ?? "").toUpperCase()}
          </Avatar>
        </Grid>

        <Grid item>
          <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
            {`${data?.data?.nickName} ${data?.data?.lastName}`}
          </Typography>
          <Typography
            variant="body2"
            align="left"
            sx={{ lineBreak: "anywhere" }}
          >
            {data?.data?.email}
          </Typography>
          {data?.data?.joinedPsp !== true && (
            <Typography
              variant="body2"
              align="left"
              sx={{
                lineBreak: "anywhere",
                backgroundColor: "#FFF2D9",
                color: "#FF7D29",
                p: 1,
                mt: 1,
              }}
            >
              Not Joined Patient Support Program
            </Typography>
          )}
          {data?.data?.joinedPsp === true && (
            <Typography
              variant="body2"
              align="left"
              sx={{
                lineBreak: "anywhere",
                backgroundColor: "#D4FFE3",
                color: "#00B458",
                p: 1,
                mt: 1,
              }}
            >
              Joined Patient Suppport Program
            </Typography>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        xs={12}
        sm={12}
        md={6}
        justifyContent={{ xs: FLEX_START, sm: FLEX_START, md: "flex-end" }}
        direction={"row"}
        alignItems={"center"}
        spacing={1}
      >
        <Grid item>
          <BaseButton
            onClick={onAvatarDelete}
            disabled={data?.data?.image === null || isRefetching}
          >
            {t("userProfilePage.deleteAvatar")}
          </BaseButton>
        </Grid>
        <Grid item>
          <BaseButton
            onClick={() => uploadRef.current?.click()}
            disabled={isRefetching}
          >
            {t("userProfilePage.uploadNewAvatar")}
          </BaseButton>
        </Grid>
        <input
          key={actionType.value}
          ref={uploadRef}
          accept="image/*"
          style={{ display: "none" }}
          id="raised-button-file"
          multiple={false}
          type="file"
          onChange={onAvatarUpload}
        />
      </Grid>
    </Grid>
  );
};

export default UserAvatar;
