import { Typography, Grid } from "@mui/material";

interface ModalBoldTitleProps {
  title: string;
}

const ModalBoldTitle = (props: ModalBoldTitleProps) => {
  const { title } = props;
  return (
    <Grid item xs={12}>
      <Typography
        id="modal-modal-title"
        variant="h6"
        component="h2"
        sx={{ fontWeight: "bold" }}
      >
        {title}
      </Typography>
    </Grid>
  );
};

export default ModalBoldTitle;
