import { Typography, Modal, Grid, Box, IconButton } from "@mui/material";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { ModalViewType } from "../../../utils/constants/ga";
import dayjs from "dayjs";
import ModalBoldTitle from "../../shareComponents/modal/BoldTitle";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  minHeight: "40%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
} as const;

interface modalData {
  img: string;
  header: string;
  body: string;
}

const OctImagesModalMobile = ({
  data,
  open,
  toggleModal,
}: {
  data: modalData[];
  open: boolean;
  toggleModal: () => void;
}) => {
  useEffect(() => {
    if (open)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.OctImagesModal}`,
      });
  }, [open]);

  if (data.length === 0) return null;

  const [{ img: img1, header: header1, body: body1 }] = data;
  const dateBody = dayjs(body1).isValid()
    ? dayjs(body1).format("DD MMM YYYY")
    : body1;

  return (
    <Modal
      open={open}
      onClose={toggleModal}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
    >
      <Grid container sx={style} className="hahah">
        <Grid
          sx={{
            display: "flex",
            height: "35px",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Grid item xs={10} sm={10}>
            <ModalBoldTitle title="OCT Image" />
          </Grid>
          <Grid item xs={2} sm={2}></Grid>
          <IconButton aria-label="back" size="small" onClick={toggleModal}>
            <CloseIcon sx={{ color: "black" }} />
          </IconButton>
        </Grid>
        <Grid>
          <Box sx={{ backgroundColor: "#FFF", p: 2 }}>
            <p>{header1}</p>

            <img
              alt="oct-image1"
              src={img1}
              loading="lazy"
              style={{ width: "100%" }}
            />
            <Typography
              id="modal-modal-description"
              variant="body2"
              sx={{ mt: 2 }}
            >
              {dateBody}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default OctImagesModalMobile;
