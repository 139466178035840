import { Modal, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../../../button/BaseButton";
import BaseInput from "../../../input/BaseInput";
import {
  useCallback,
  useEffect,
  useState,
  createRef,
  ChangeEvent,
} from "react";
import { deletePatient } from "../../../../../services/patient";
import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../../utils/constants/routes/webPaths";
import {
  KEY_TO_DELETE,
  MAX_UPLOAD_FILE_SIZE,
} from "../../../../../utils/constants/general";
import ReactGA from "react-ga4";
import { ModalViewType } from "../../../../../utils/constants/ga";
import ModalBoldTitle from "../../BoldTitle";
import SecondaryButton from "../../../button/SecondaryButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
} as const;

interface DeletePatientModalProps {
  isOpen: boolean;
  togglePatientDeleteModal: () => void;
  patientId: number;
}

const DeletePatientModal = (props: DeletePatientModalProps) => {
  const { isOpen, togglePatientDeleteModal, patientId } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const uploadRef = createRef<HTMLInputElement>();

  const { mutateAsync, isLoading } = useMutation(deletePatient);

  const [deleteConfirm, setDeleteConfirm] = useState<{
    text: string;
    document: null | FileList;
    uploadTime: number;
  }>({
    text: "",
    document: null,
    uploadTime: 0,
  });

  const onConsentUpload = useCallback(
    async (event: ChangeEvent<HTMLInputElement> | undefined) => {
      const file = event?.target.files;

      if (file === undefined || file === null || file?.length === 0) return;
      if (file[0].size >= MAX_UPLOAD_FILE_SIZE) {
        alert(t("userProfilePage.uploadNewAvatarExceedMaxSize"));
        return;
      }

      setDeleteConfirm((val) => ({
        ...val,
        document: file,
        uploadTime: ++val.uploadTime,
      }));
    },
    [t]
  );

  const handleDeletePatient = useCallback(async () => {
    const formData = new FormData();
    formData.append("patientConsent", deleteConfirm.document![0]);
    formData.append("patientId", patientId.toString());
    const deleteResulult = await mutateAsync(formData);
    if (deleteResulult.success) {
      navigate(ROUTE_PATHS.PATIENTS);
    }
  }, [mutateAsync, deleteConfirm.document, patientId]);

  const handleChangeText = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setDeleteConfirm((val) => ({
        ...val,
        text: event.target.value,
      }));
    },
    []
  );

  useEffect(() => {
    if (isOpen) {
      setDeleteConfirm({
        text: "",
        document: null,
        uploadTime: 0,
      });

      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.DeletePatientModal}`,
      });
    }
  }, [isOpen]);

  return (
    <Modal
      open={isOpen}
      onClose={togglePatientDeleteModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <ModalBoldTitle
          title={t("patientModal.deletePatient.title").replace(
            "@ID",
            patientId?.toString()
          )}
        />

        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            maxHeight: "50vh",
            overflow: "auto",
          }}
        >
          <Grid item xs={12}>
            <Typography id="modal-modal-description">
              {t("patientModal.deletePatient.body").replace(
                "@KEY_TO_DELETE",
                KEY_TO_DELETE
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <BaseInput
              placeholder={KEY_TO_DELETE}
              size="small"
              onChange={handleChangeText}
              fullWidth
              error={KEY_TO_DELETE !== deleteConfirm.text}
              type="text"
            />
          </Grid>

          <Grid
            container
            item
            xs={12}
            direction="row"
            alignItems={"center"}
            sx={{ mt: 2 }}
          >
            <BaseButton
              onClick={() => uploadRef.current?.click()}
              sx={{ mr: 1 }}
            >
              {t("patientModal.deletePatient.uploadPatientConsent")} *
            </BaseButton>
            {deleteConfirm.document?.length === 1 && (
              <Typography id="modal-modal-file-name">
                {deleteConfirm.document[0].name}
              </Typography>
            )}
          </Grid>

          <input
            key={deleteConfirm.uploadTime}
            ref={uploadRef}
            accept="image/*,application/pdf,application/msword"
            style={{ display: "none" }}
            id="raised-button-file"
            multiple={false}
            type="file"
            onChange={onConsentUpload}
          />
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction={"row"}
          justifyContent="flex-end"
          sx={{ mt: 1 }}
        >
          <SecondaryButton
            sx={{ mr: 1 }}
            onClick={togglePatientDeleteModal}
            disabled={isLoading}
          >
            {t("singlePharse.cancel")}
          </SecondaryButton>
          <BaseButton
            onClick={handleDeletePatient}
            disabled={
              isLoading ||
              deleteConfirm.text !== KEY_TO_DELETE ||
              deleteConfirm.document === null ||
              deleteConfirm.document.length === 0
            }
          >
            {t("singlePharse.confirm")}
          </BaseButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default DeletePatientModal;
