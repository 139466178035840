import { Paper } from "@mui/material";
import { useEffect, useCallback, useState } from "react";
import useModal from "../../hooks/useModal";
import PatientModal from "../shareComponents/modal/PatientModal";
import ShareAccessModal from "../shareComponents/modal/ShareAccessModal";
import PatientsTable from "./PatientsTable";
import { useMutation } from "@tanstack/react-query";
import { getShareHcpData } from "../../services/hcp";
import { HcpsByInstituteProps } from "../../utils/types/services/hcp";

const PatientListingPage = () => {
  const { mutateAsync } = useMutation(getShareHcpData);
  const [shareDetails, setShareDetails] = useState<HcpsByInstituteProps>({
    patientId: -1,
    institute: [],
    owners: [],
  });
  const [details, setDetails] = useState<{ pId: number; hcpId: number }>();

  const {
    isOpen: isAddPatientDrawerOpen,
    closeModal: closePatientDrawer,
    openModal: openPatientDrawer,
  } = useModal();

  const {
    isOpen: isShareAccessDrawerOpen,
    closeModal: closeShareAccessDrawer,
    openModal: openShareDrawer,
  } = useModal();

  useEffect(() => {
    document.title = "InnoVision - Patients Overall";
  }, []);

  const handleShareClick = useCallback(async (pId: number, hcpId: number) => {
    setDetails({ pId, hcpId });
    const checkResult = await mutateAsync({
      pId,
    });
    if (checkResult?.data) {
      setShareDetails({ ...checkResult.data, patientId: pId });
      openShareDrawer();
    }
  }, []);

  const refetchData = async () => {
    const checkResult = await mutateAsync({
      pId: details ? details.pId : undefined,
    });
    if (checkResult?.data) {
      setShareDetails({
        ...checkResult.data,
        patientId: details ? details.pId : undefined,
      });
    }
  };

  return (
    <Paper elevation={0}>
      <PatientModal
        isOpen={isAddPatientDrawerOpen}
        handleClose={closePatientDrawer}
      />
      <ShareAccessModal
        isOpen={isShareAccessDrawerOpen}
        handleClose={closeShareAccessDrawer}
        shareDetails={shareDetails}
        refetchData={refetchData}
      />
      <PatientsTable
        handleAddPatientClick={openPatientDrawer}
        handleShareClick={handleShareClick}
      />
    </Paper>
  );
};

export default PatientListingPage;
