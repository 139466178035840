import { RadioGroup, RadioGroupProps } from "@mui/material";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import ControlledFormElement from "./ControlledFormElement";

type FormRadioGroupProps<T extends FieldValues = FieldValues> = {
  children: React.ReactNode;
  name: FieldPath<T>;
  control: Control<T>;
} & Omit<RadioGroupProps, "name">;

const FormRadioGroup = <T extends FieldValues>(
  props: FormRadioGroupProps<T>,
) => {
  const { children, control, name, ...radioGroupProps } = props;

  return (
    <ControlledFormElement control={control} name={name}>
      {({ field: { value, onChange, ref } }) => (
        <RadioGroup
          {...radioGroupProps}
          value={value}
          onChange={onChange}
          ref={ref}
        >
          {children}
        </RadioGroup>
      )}
    </ControlledFormElement>
  );
};

export default FormRadioGroup;
