import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Collapse,
  Divider,
  IconButton,
  MenuItem,
  Typography,
  Grid,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { getAllDBConstants } from "../../../../../services/dbConstants";
import { Eye } from "../../../../../utils/constants/general";
import { REACT_QUERY_KEYS } from "../../../../../utils/constants/reactQueryKeys";
import FormDatePicker from "../../../form/FormDatePicker";
import FormInput from "../../../form/FormInput";

interface EyeMedicalDetailsFormProps {
  eye: Eye;
  collapse: boolean;
  toggleCollapse: (eye: Eye) => void;
}

const defaultSelectItems = [
  {
    id: -1,
    displayText: "-",
  },
];

const EyeMedicalDetailsForm = (props: EyeMedicalDetailsFormProps) => {
  const { eye, collapse, toggleCollapse } = props;

  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const diseaseTypes = dbConstants?.data?.diseaseTypes ?? [];

  const { t } = useTranslation();

  const { control } = useFormContext();

  const handleCollapseButtonClick = useCallback(() => {
    toggleCollapse(eye);
  }, [toggleCollapse, eye]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <IconButton onClick={handleCollapseButtonClick}>
          {collapse ? (
            <KeyboardArrowDownIcon color={"primary"} />
          ) : (
            <KeyboardArrowUpIcon />
          )}
        </IconButton>
        <Typography variant="subtitle1" sx={{ display: "inline" }}>
          {eye === Eye.LEFT
            ? t("patientModal.pastInjections.left")
            : t("patientModal.pastInjections.right")}
        </Typography>
      </Grid>

      <Collapse in={!collapse}>
        <Grid container sx={{ pl: 6 }}>
          <Grid item xs={12}>
            <FormDatePicker
              control={control}
              name={
                eye === Eye.LEFT
                  ? "leftEyeFirstInjectionDate"
                  : "rightEyeFirstInjectionDate"
              }
              label={t("patientModal.pastInjections.first")}
              inputFormat="DD/MM/YYYY"
              maxDate={dayjs()}
            />
          </Grid>

          <Grid item sm={12} md={6}>
            <FormInput
              control={control}
              name={
                eye === Eye.LEFT
                  ? "leftEyePastInjectionCount"
                  : "rightEyePastInjectionCount"
              }
              label={t("patientModal.pastInjections.count")}
              variant="outlined"
              type="number"
              inputProps={{
                min: 0,
                max: 1000,
              }}
              helperText={t("patientModal.pastInjections.description")}
              sx={{ width: "97%" }}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <FormInput
              select
              control={control}
              name={
                eye === Eye.LEFT
                  ? "leftEyeDiseaseTypeId"
                  : "rightEyeDiseaseTypeId"
              }
              label={t("singlePharse.diseaseType")}
              sx={{ width: "97%" }}
            >
              {defaultSelectItems
                .concat(diseaseTypes)
                .map(({ id, displayText }) => (
                  <MenuItem key={id} value={id}>
                    {displayText}
                  </MenuItem>
                ))}
            </FormInput>
          </Grid>
        </Grid>
      </Collapse>

      <Grid item xs={12}>
        <Divider sx={{ mt: 1, mb: 1 }} />
      </Grid>
    </Grid>
  );
};

export default EyeMedicalDetailsForm;
