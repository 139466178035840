import { useState } from "react";

const useModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleModal = () => setIsOpen((val) => !val);
  const closeModal = () => setIsOpen(false);
  const openModal = () => setIsOpen(true);
  return { isOpen, toggleModal, closeModal, openModal };
};

export default useModal;
