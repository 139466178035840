import axios from "axios";

const NUM_15000 = 15000;
const NUM_60000 = 60000;

const api = axios.create({
  withCredentials: process.env.NODE_ENV === "production" ? undefined : true,
  baseURL:
    process.env.NODE_ENV === "production"
      ? undefined
      : process.env.REACT_APP_API_URL,
  timeout: process.env.NODE_ENV === "production" ? NUM_15000 : NUM_60000,
});

export default api;
