import CloseIcon from "@mui/icons-material/Close";
import {
  useTheme,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Stack,
  Typography,
  SelectChangeEvent,
} from "@mui/material";
import { ChartData, ChartOptions } from "chart.js";
import dayjs from "dayjs";
import { useEffect, useCallback, useMemo, useState } from "react";
import { Line } from "react-chartjs-2";
import { GraphDataItem } from "../types";
import {
  ViewPeriodOptionId,
  VIEW_PERIOD_OPTIONS,
  DatasetIndex,
} from "../../../utils/constants/cst-va-constants";
import { getGraphData } from "./graph-data";
import ToggleChip from "./ToggleChip";
import { useTranslation } from "react-i18next";
import { STATUS_STRING } from "../../../utils/constants/cst-va-status-string";
import ReactGA from "react-ga4";
import {
  CategoryType,
  ModalViewType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";
import _ from "lodash";
import { Eye } from "../../../utils/constants/general";
import { isMobile } from "react-device-detect";
import { Theme } from "@mui/material/styles";

const cst_modal_styles = (theme: Theme) =>
  ({
    width: "70%",
    minWidth: 800,
    maxWidth: "90%",
    minHeight: "50vh",
    maxHeight: "80vh",
    overflow: "auto",
    px: 3,
    py: 2,
    [theme.breakpoints.down("sm")]: {
      minWidth: "90%",
    },
  } as const);

export interface DatasetDisplayState {
  cst: boolean;
  va: boolean;
}

interface CSTAndVAGraphModalProps {
  open: boolean;
  handleClose: () => void;
  data: GraphDataItem[];
  graphEye: Eye;
}

const CSTAndVAGraphModal = (props: CSTAndVAGraphModalProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const NUM_2 = 2;
  const NUM_3 = 3;
  const NUM_6 = 6;
  const NUM_100 = 100;
  const NUM_8 = 8;
  const NUM_40 = 40;
  const NUM_18 = 18;
  const NUM_30 = 30;
  const NUM_0_0_5 = 0.05;

  const { open, handleClose, data, graphEye } = props;

  const [selectedViewPeriodId, setSelectedViewPeriodId] =
    useState<ViewPeriodOptionId>(ViewPeriodOptionId.ALL);

  const [datasetDisplayState, setDatasetDisplayState] =
    useState<DatasetDisplayState>({
      cst: true,
      va: true,
    });

  useEffect(() => {
    if (!open) return;
    setSelectedViewPeriodId(ViewPeriodOptionId.ALL);
    setDatasetDisplayState({
      cst: true,
      va: true,
    });

    ReactGA.send({
      hitType: "pageview",
      page: `${ModalViewType.CSTAndVAGraphModal}`,
    });
  }, [open]);

  const periodFilteredInputData = useMemo(() => {
    const { value: numberOfMonths } = VIEW_PERIOD_OPTIONS[selectedViewPeriodId];

    const filteredData = data.filter((record) => {
      const { date } = record;
      return (
        selectedViewPeriodId === ViewPeriodOptionId.ALL ||
        dayjs().diff(date, "month") <= numberOfMonths
      );
    });

    const uniqFilteredDate = _.uniqBy(filteredData, "date");

    const filteredData2 = uniqFilteredDate.map((e) => {
      e.va = e.va === "CF" || e.va === "HM" || e.va === "LP" ? null : e.va;
      const filterResult = filteredData.filter((ee) => ee.date === e.date);
      if (filterResult.length === 1) return e;
      const firstResult = filterResult[0];
      for (const i of filterResult) {
        if (firstResult.cst === null && i.cst !== null) firstResult.cst = i.cst;
        if (firstResult.cstStatus === null && i.cstStatus !== null)
          firstResult.cstStatus = i.cstStatus;
        if (firstResult.va === null && i.va !== null) firstResult.va = i.va;
        if (firstResult.vaStatus === null && i.vaStatus !== null)
          firstResult.vaStatus = i.vaStatus;
        if (firstResult.drugId === null && i.drugId !== null)
          firstResult.drugId = i.drugId;
        if (firstResult.drugName === null && i.drugName !== null)
          firstResult.drugName = i.drugName;
        if (firstResult.treatmentInterval === "" && i.treatmentInterval !== "")
          firstResult.treatmentInterval = i.treatmentInterval;
      }
      return firstResult;
    });

    if (filteredData2.length >= NUM_2) {
      const daysInBetween = dayjs(filteredData2[0].date).diff(
        filteredData2[filteredData2.length - 1].date,
        "days"
      );

      filteredData2.unshift({
        cst: null,
        va: null,
        drugName: null,
        drugId: null,
        cstStatus: null,
        vaStatus: null,
        date: dayjs(filteredData2[0].date)
          .add(daysInBetween * NUM_0_0_5, "day")
          .toISOString(),
        treatmentInterval: "",
      });

      filteredData2.push({
        cst: null,
        va: null,
        drugName: null,
        drugId: null,
        cstStatus: null,
        vaStatus: null,
        date: dayjs(filteredData2[filteredData2.length - 1].date)
          .add(-daysInBetween * NUM_0_0_5, "day")
          .toISOString(),
        treatmentInterval: "",
      });
    }

    return filteredData2;
  }, [data, selectedViewPeriodId]);

  const handleViewPeriodChange = useCallback(
    (e: SelectChangeEvent<ViewPeriodOptionId>) => {
      const newPeriodId = +e.target.value as ViewPeriodOptionId;
      setSelectedViewPeriodId(newPeriodId);
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Patient_CST_VA_GRAPH_FILTER_PERIOD,
        value: newPeriodId,
      });
    },
    []
  );

  const toggleDatasetDisplay = useCallback((key: keyof DatasetDisplayState) => {
    setDatasetDisplayState((state) => ({
      ...state,
      [key]: !state[key],
    }));
    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_CST_VA_GRAPH_FILTER_DATA,
      label: key,
    });
  }, []);

  const axisOptions = useMemo<ChartOptions<"line">["scales"]>(() => {
    return {
      x: {
        type: "time" as const,
        time: {
          tooltipFormat: "DD",
          stepSize:
            selectedViewPeriodId === ViewPeriodOptionId.SIX_MONTHS
              ? 1
              : selectedViewPeriodId === ViewPeriodOptionId.ONE_YEAR
              ? NUM_2
              : selectedViewPeriodId === ViewPeriodOptionId.TWO_YEARS
              ? NUM_3
              : NUM_6,
          minUnit: "day",
        },
        ticks: {
          color: theme.palette.text.primary,
        },
      },
      cst: {
        title: {
          text: t("patientDetailPage.cstvaChart.cstyAxis"),
          display: true,
          color: theme.palette.text.primary,
        },
        suggestedMax: 400,
        position: "left",
        display: datasetDisplayState.cst,
        min: 0,
        ticks: {
          color: theme.palette.text.primary,
        },
      },
      va: {
        title: {
          text: t("patientDetailPage.cstvaChart.vayAxi"),
          display: true,
          color: theme.palette.text.primary,
        },
        suggestedMax: 0.3,
        position: datasetDisplayState.cst ? "right" : "left",
        display: datasetDisplayState.va,
        min: 0,
        ticks: {
          color: theme.palette.text.primary,
        },
      },
    };
  }, [datasetDisplayState, selectedViewPeriodId, t]);

  const tooltipOptions = useMemo<
    NonNullable<ChartOptions<"line">["plugins"]>["tooltip"]
  >(
    () => ({
      position: "average",
      usePointStyle: true,
      callbacks: {
        afterTitle: (tooltipItems) => {
          const { drugName } = tooltipItems[0].raw as GraphDataItem;
          if (!drugName) return "";
          return `💉 ${drugName}`;
        },
        label: (tooltipItem: any) => {
          const { datasetIndex, dataset, formattedValue } = tooltipItem;
          return datasetIndex === DatasetIndex.DRUG
            ? ""
            : datasetIndex === DatasetIndex.VA
            ? `${dataset.label}: ${tooltipItem.raw.vaToDisplay}`
            : `${dataset.label}: ${formattedValue} um`;
        },
        afterLabel: (tooltipItem) => {
          const { datasetIndex, dataset, dataIndex } = tooltipItem;

          if (datasetIndex === DatasetIndex.DRUG) return "";
          const labels: string[] = [];

          const { cstStatus, vaStatus, va, cst } =
            tooltipItem.raw as GraphDataItem;

          if (datasetIndex === DatasetIndex.VA) {
            if (va !== null) {
              for (let i = dataIndex + 1; i < dataset.data.length; i++) {
                const graph_data = dataset.data[i] as unknown as GraphDataItem;
                if (graph_data.va === null) continue;
                const delta = (va - graph_data.va) / graph_data.va;
                const percentageVariation = Number.isFinite(delta)
                  ? (delta * NUM_100).toFixed(NUM_2) + "%"
                  : "N/A";

                labels.push(
                  `${t(
                    "patientDetailPage.cstvaChart.vaVar"
                  )} ${percentageVariation}`
                );
                break;
              }
            }

            if (vaStatus !== null) labels.push(STATUS_STRING[vaStatus]);
            return labels;
          }

          if (datasetIndex === DatasetIndex.CST) {
            if (cst !== null) {
              for (let i = dataIndex + 1; i < dataset.data.length; i++) {
                const graph_data = dataset.data[i] as unknown as GraphDataItem;
                if (graph_data.cst === null) continue;
                const percentageVariation =
                  (((cst - graph_data.cst) / graph_data.cst) * NUM_100).toFixed(
                    NUM_2
                  ) + "%";
                labels.push(
                  `${t(
                    "patientDetailPage.cstvaChart.cstVar"
                  )} ${percentageVariation}`
                );
                break;
              }
            }

            if (cstStatus !== null) labels.push(STATUS_STRING[cstStatus]);
            return labels;
          }

          return labels;
        },
        footer: (tooltipItems) => {
          const { drugName, treatmentInterval } = tooltipItems[0]
            .raw as GraphDataItem;
          if (!drugName || !treatmentInterval) return "";
          return `${treatmentInterval} vs last injection`;
        },
      },
    }),
    [t]
  );

  const formattedData = useMemo<ChartData<"line", GraphDataItem[], unknown>>(
    () => getGraphData({ datasetDisplayState, periodFilteredInputData }),
    [datasetDisplayState, periodFilteredInputData]
  );

  const options = useMemo<ChartOptions<"line">>(
    () => ({
      aspectRatio: isMobile ? 1 : NUM_2,
      interaction: {
        intersect: false,
        mode: "nearest",
        axis: "x",
      },
      scales: axisOptions,
      plugins: {
        tooltip: tooltipOptions,
        datalabels: {
          display: false,
        },
        legend: {
          labels: {
            usePointStyle: true,
            boxWidth: isMobile ? NUM_8 : NUM_40,
            boxHeight: isMobile ? NUM_8 : NUM_40,
            generateLabels: () => [
              {
                text: t("patientDetailPage.cstFull"),
                fillStyle: "#0C41CD",
                strokeStyle: "#0C41CD",
              },
              {
                text: t("patientDetailPage.vaFull"),
                fillStyle: "#F23733",
                strokeStyle: "#F23733",
              },
            ],
            color: theme.palette.text.secondary,
          },
        },
        title: {
          display: true,
          text: [
            graphEye === Eye.LEFT
              ? t("patientModal.pastInjections.left")
              : t("patientModal.pastInjections.right"),
            t("patientDetailPage.cstvaChart.title"),
          ],
          font: {
            size: isMobile ? NUM_18 : NUM_30,
            weight: "normal",
          },
          color: theme.palette.text.primary,
        },
      },
    }),
    [t, tooltipOptions, axisOptions, graphEye]
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper elevation={0} sx={cst_modal_styles}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography variant="h6" component="h2" fontWeight="bold">
            {t("patientDetailPage.cstvaChart.title")}
          </Typography>

          <IconButton onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="subtitle2" color="text.secondary">
              {t("patientDetailPage.show")}
            </Typography>

            <ToggleChip
              label="CST"
              checked={datasetDisplayState.cst}
              handleClick={() => toggleDatasetDisplay("cst")}
            />
            <ToggleChip
              label="VA"
              checked={datasetDisplayState.va}
              handleClick={() => toggleDatasetDisplay("va")}
            />
          </Stack>

          <FormControl size="small">
            <InputLabel id="demo-simple-select-label">Period</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedViewPeriodId}
              label="Period"
              onChange={handleViewPeriodChange}
              sx={{ minWidth: "10vw" }}
            >
              {Object.entries(VIEW_PERIOD_OPTIONS).map(
                ([id, { displayText }]) => (
                  <MenuItem key={id} value={id}>
                    {t(displayText)}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
        </Stack>

        <Line options={options} data={formattedData} />
      </Paper>
    </Modal>
  );
};

export default CSTAndVAGraphModal;
