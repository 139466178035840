import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import {
  Box,
  Divider,
  IconButton,
  Paper,
  Typography,
  Grid,
  Link,
} from "@mui/material";
import dayjs from "dayjs";
import { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { PatientDetails } from "../../../utils/types/services/patients";
import { isMobile } from "react-device-detect";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { Theme } from "@mui/material/styles";

const SPACE_BETWEEN = "space-between";
const SPACE_AROUND = "space-around";

const header_main = () =>
  ({
    flexGrow: 1,
    p: 2.8,
    position: "relative",
  } as const);

const main_container = (theme: Theme) =>
  ({
    display: "flex",
    gap: 2,
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      width: "100%",
    },
  } as const);
const box_style = (theme: Theme) =>
  ({
    display: "flex",
    justifyContent: SPACE_BETWEEN,
    gap: 10,
    [theme.breakpoints.down("sm")]: {
      gap: 5,
      flexWrap: "wrap",
    },
  } as const);

const buttonStyle = {
  color: "#000000",
  borderRadius: "8px",
  padding: "8px 20px",
  fontSize: "14px",
  fontWeight: "400",
  border: "none",
  boxShadow: "0px 2px 6px 0px rgba(0, 0, 0, 0.15)",
} as const;

interface PatientDetailPersonalInfoProps {
  handleEditPatientClick: () => void;
  handleViewContacts: () => void;
  handleShareClick: () => void;
  patient: PatientDetails;
  isOwner: boolean;
}

const PatientDetailPersonalInfo = (props: PatientDetailPersonalInfoProps) => {
  const {
    handleEditPatientClick,
    handleViewContacts,
    handleShareClick,
    patient,
    isOwner,
  } = props;
  const {
    id,
    firstName,
    lastName,
    gender,
    year,
    month,
    day,
    recognitionId,
    cgMob1,
    cgMob2,
    hasConsent,
  } = patient;

  const { t } = useTranslation();

  const age = useMemo(() => {
    return dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD").isValid()
      ? dayjs().diff(dayjs(`${year}-${month}-${day}`, "YYYY-MM-DD"), "years")
      : dayjs().year() - year;
  }, [year, month, day]);

  const onClickPreview = useCallback(() => {
    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Preview_Report,
      value: patient.id,
    });
  }, [patient.id]);

  return (
    <Paper elevation={0} sx={header_main}>
      <Box sx={{ display: "flex", justifyContent: SPACE_BETWEEN }}>
        <Box sx={main_container}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 0.3 }}>
            <Typography variant="h5" fontWeight="bold">
              {firstName} {lastName}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: SPACE_BETWEEN,
                gap: 10,
              }}
            >
              <Typography variant="subtitle2" color="text.secondary">
                {`${t("singlePharse.patientId")}: ${id}`}
              </Typography>
            </Box>
          </Box>

          <Grid
            item
            md
            sm
            sx={{
              display: { xs: "none", sm: "block", md: "block" },
              px: 6,
            }}
          >
            {" "}
            <Divider orientation="vertical" sx={{ mx: 1 }} />
          </Grid>
          <Box sx={box_style}>
            <Box sx={box_style}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: SPACE_AROUND,
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {t("singlePharse.gender")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  {t(`singlePharse.${gender}`)}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: SPACE_AROUND,
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {t("singlePharse.age")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  {age}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: SPACE_AROUND,
                  wordBreak: "break-all",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {t("singlePharse.recognitionId")}
                </Typography>
                <Typography variant="subtitle1" fontWeight="bold">
                  {recognitionId}&nbsp;
                </Typography>
              </Box>
            </Box>
            <Box sx={box_style}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: SPACE_AROUND,
                  wordBreak: "break-all",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {t("singlePharse.careGiver?")}
                </Typography>
                {(cgMob1 !== null || cgMob2 !== null) && (
                  <Box>
                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: { xs: "none", sm: "block", md: "block" },
                        fontWeight: "bold",
                      }}
                    >
                      Yes{" "}
                      <Link
                        onClick={handleViewContacts}
                        sx={{ cursor: "pointer", fontWeight: "initial", pl: 1 }}
                      >
                        View Contacts
                      </Link>
                    </Typography>

                    <Typography
                      variant="subtitle1"
                      sx={{
                        display: { xs: "block", sm: "none", md: "none" },
                        fontWeight: "bold",
                      }}
                    >
                      Yes{" "}
                      <Link
                        sx={{ cursor: "pointer", fontWeight: "initial", pl: 1 }}
                        onClick={handleViewContacts}
                      >
                        View
                      </Link>
                    </Typography>
                  </Box>
                )}
                {cgMob1 === null && cgMob2 === null && (
                  <Typography
                    variant="subtitle1"
                    sx={{
                      display: { xs: "none", sm: "block", md: "block" },
                      fontWeight: "bold",
                    }}
                  >
                    No
                  </Typography>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",

                  flexDirection: "column",

                  justifyContent: SPACE_AROUND,

                  wordBreak: "break-all",
                }}
              >
                <Typography variant="subtitle2" color="text.secondary">
                  {t("singlePharse.signedAppConsent?")}
                </Typography>

                <Typography variant="subtitle1" fontWeight="bold">
                  {hasConsent ? "Yes" : "No"}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>

        {isOwner && (
          <Grid>
            <Box
              sx={{
                display: { xs: "none", sm: "block", md: "block" },
              }}
            >
              <Box sx={{ display: "flex" }}>
                <IconButton onClick={handleEditPatientClick}>
                  <EditOutlinedIcon />
                </IconButton>
                <Grid item md sm>
                  <SecondaryButton onClick={handleShareClick} sx={buttonStyle}>
                    Share Profile
                  </SecondaryButton>
                </Grid>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "block", sm: "none", md: "none" },
              }}
            >
              <IconButton
                onClick={handleEditPatientClick}
                sx={{ position: "absolute", top: 5, right: 10 }}
              >
                <EditOutlinedIcon />
              </IconButton>
            </Box>
          </Grid>
        )}
      </Box>
      <Grid
        item
        xs
        sx={{ display: { xs: "block", sm: "none", md: "none" }, pt: 2.8 }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {isOwner && (
            <SecondaryButton
              onClick={handleShareClick}
              sx={{ ...buttonStyle, mr: 4 }}
            >
              Share Profile
            </SecondaryButton>
          )}
          <Link
            href={ROUTE_PATHS.PATIENT_PREVIEW_DETAILS.replace(
              ":id",
              id!.toString()
            )}
            target="_blank"
          >
            <Typography
              variant="subtitle2"
              color="primary.main"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={onClickPreview}
            >
              {t("patientDetailPage.previewReport")}
            </Typography>
          </Link>
        </Box>
      </Grid>
    </Paper>
  );
};

export default PatientDetailPersonalInfo;
