import AddIcon from "@mui/icons-material/Add";
import ImageIcon from "@mui/icons-material/Image";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Typography, Grid } from "@mui/material";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import * as React from "react";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { Theme } from "@mui/material/styles";

const HEADER_INFO_SX = {
  backgroundColor: "primary.light",
  px: 1.5,
  borderRadius: 10,
  ml: 1,
} as const;

const MAIN_HEADER_SX = (theme: Theme) =>
  ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  } as const);

interface TreatmentRecordHeaderProps {
  firstInjectionDate: string | null;
  totalInjections: number;
  handleAddTreatmentRecordClick: () => void;
  handleShowImageClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  isImageButtonDisabled: boolean;
}

const TreatmentRecordHeader = (props: TreatmentRecordHeaderProps) => {
  const { t } = useTranslation();
  const {
    firstInjectionDate,
    totalInjections,
    handleAddTreatmentRecordClick,
    handleShowImageClick,
    isImageButtonDisabled,
  } = props;

  return (
    <Box sx={MAIN_HEADER_SX}>
      <Grid item xs={12} sm={12} md={8} sx={{ paddingBottom: "10px" }}>
        <Typography variant="subtitle1" fontWeight="bold">
          {t("patientDetailPage.treatmentRecords")}
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="subtitle2" display="inline" color="primary.main">
            {t("patientDetailPage.firstInjection")}
          </Typography>
          <Typography
            variant="subtitle2"
            display="inline"
            color="primary.main"
            fontWeight="bold"
            sx={HEADER_INFO_SX}
          >
            {firstInjectionDate
              ? dayjs(firstInjectionDate).format("DD MMM YYYY")
              : "N/A"}
          </Typography>
          <FiberManualRecordIcon
            sx={{ fontSize: 7, color: "text.secondary", mx: 1 }}
          />
          <Typography variant="subtitle2" display="inline" color="primary.main">
            {t("patientDetailPage.totalInjections")}
          </Typography>
          <Typography
            variant="subtitle2"
            display="inline"
            color="primary.main"
            fontWeight="bold"
            sx={HEADER_INFO_SX}
          >
            {totalInjections}
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={4}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        <SecondaryButton
          size="small"
          endIcon={<ImageIcon />}
          onClick={handleShowImageClick}
          disabled={isImageButtonDisabled}
          sx={{ mr: 2 }}
        >
          {t("patientDetailPage.octImage")}
        </SecondaryButton>

        <SecondaryButton
          size="small"
          endIcon={<AddIcon />}
          onClick={handleAddTreatmentRecordClick}
        >
          {t("singlePharse.add")}
        </SecondaryButton>
      </Grid>
    </Box>
  );
};

export default TreatmentRecordHeader;
