import {
  PatientsProps,
  PatientDetails,
  PatientFormState,
  PickRename,
  EyeDetailsProps,
  RequestPatientAccessProps,
} from "../utils/types/services/patients";
import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import notificationToUser from "../utils/helperFunction/notificationToUser";
import api from "./api";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";
import i18n from "../i18n";
import { toast } from "react-toastify";
const STATUS_200 = 200;
const STATUS_201 = 201;

export const getPatientDetailsData = async (
  id: number
): Promise<{
  success: boolean;
  status?: number;
  data?: PatientDetails;
}> => {
  let getPatientDetailsResult = null;
  try {
    getPatientDetailsResult = await api.get(
      API_ROUTE_FULL_PATHS.patientGetPatientDetails,
      { params: { id } }
    );
    if (getPatientDetailsResult.status === STATUS_200) {
      return {
        success: true,
        data: getPatientDetailsResult.data.data,
      };
    }

    throw new Error("Retry Will Apply");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: getPatientDetailsResult });
  }
};

export const getPatientsData = async (): Promise<{
  success: boolean;
  status?: number;
  data?: PatientsProps[];
}> => {
  let getPatientResult = null;
  try {
    getPatientResult = await api.get(API_ROUTE_FULL_PATHS.patientGetPatients);
    if (getPatientResult.status === STATUS_200) {
      return {
        success: true,
        data: getPatientResult.data.data,
      };
    }

    throw new Error("Retry Will Apply");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: getPatientResult });
  }
};

export const patientAddOne = async (
  patientState: PatientFormState & { confirmed: boolean }
): Promise<{
  success: boolean;
  status?: number;
  data?: {
    patientId: number;
  };
}> => {
  let addPatientResult = null;
  try {
    addPatientResult = await api.post(API_ROUTE_FULL_PATHS.patientAddPatient, {
      ...patientState,
      rightEyeFirstInjectionDate:
        patientState.rightEyeFirstInjectionDate?.toISOString() ?? null,
      leftEyeFirstInjectionDate:
        patientState.leftEyeFirstInjectionDate?.toISOString() ?? null,
      mobileNumber: patientState.mobilePrefix + patientState.mobileLocal,
      mobilePrefix: undefined,
      mobileLocal: undefined,
      cgMob1:
        patientState.caregiver1mobile.length > 0
          ? patientState.caregiver1Prefix + patientState.caregiver1mobile
          : null,
      cgMob2:
        patientState.caregiver2mobile.length > 0
          ? patientState.caregiver2Prefix + patientState.caregiver2mobile
          : null,
      caregiver1mobile: undefined,
      caregiver2mobile: undefined,
      caregiver1Prefix: undefined,
      caregiver2Prefix: undefined,
    });

    if (addPatientResult.status === STATUS_200) {
      toast.success(i18n.t("message.toast.addSuccess") as string);
      return {
        success: true,
        status: STATUS_200,
        data: addPatientResult.data.data,
      };
    }

    if (addPatientResult.status === STATUS_201) {
      return {
        success: true,
        status: STATUS_201,
      };
    }

    notificationToUser({ msg: addPatientResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: addPatientResult });
  }
};

export const updatePatient = async (
  patientState: PatientFormState & { id: number }
): Promise<{
  success: boolean;
  status?: number;
}> => {
  let updatePatientResult = null;

  try {
    updatePatientResult = await api.post(
      API_ROUTE_FULL_PATHS.patientUpdatePatient,
      {
        ...patientState,
        rightEyeFirstInjectionDate:
          patientState.rightEyeFirstInjectionDate?.toISOString() ?? null,
        leftEyeFirstInjectionDate:
          patientState.leftEyeFirstInjectionDate?.toISOString() ?? null,
        mobileNumber: patientState.mobilePrefix + patientState.mobileLocal,
        mobilePrefix: undefined,
        mobileLocal: undefined,

        cgMob1:
          patientState.caregiver1mobile.length > 0
            ? patientState.caregiver1Prefix + patientState.caregiver1mobile
            : null,
        cgMob2:
          patientState.caregiver2mobile.length > 0
            ? patientState.caregiver2Prefix + patientState.caregiver2mobile
            : null,
        caregiver1mobile: undefined,
        caregiver2mobile: undefined,
        caregiver1Prefix: undefined,
        caregiver2Prefix: undefined,
      }
    );

    if (updatePatientResult.status === STATUS_200) {
      const msg = i18n.t(updatePatientResult.data.msg);
      if (typeof msg === "string" && msg !== "") toast.info(msg);
      toast.success(i18n.t("message.toast.updateSuccess") as string);

      return {
        success: true,
      };
    }

    notificationToUser({ msg: updatePatientResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updatePatientResult });
  }
};

export const deletePatient = async (
  consentFormData: FormData | null
): Promise<{
  success: boolean;
  status?: number;
}> => {
  let deletePatientResult = null;
  try {
    deletePatientResult = await api.post(
      API_ROUTE_FULL_PATHS.patientDeletePatient,
      consentFormData
    );

    if (deletePatientResult.status === STATUS_200) {
      toast.success(
        i18n.t("patientModal.deletePatient.deleteSuccess") as string
      );
      return {
        success: true,
      };
    }

    notificationToUser({ msg: deletePatientResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: deletePatientResult });
  }
};

export const updateFixedTreatmentWeeks = async ({
  eyeId,
  fixedWeeks,
}: PickRename<EyeDetailsProps, "id", "eyeId"> & {
  fixedWeeks: number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let updateResult = null;
  try {
    updateResult = await api.post(
      API_ROUTE_FULL_PATHS.patientUpdateFixedTreatmentWeeks,
      { eyeId, fixedWeeks }
    );

    if (updateResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: updateResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updateResult });
  }
};

export const updateTreatmentPhase = async ({
  eyeId,
  treatmentPhaseId,
}: PickRename<EyeDetailsProps, "id", "eyeId"> & {
  treatmentPhaseId: number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let updateResult = null;
  try {
    updateResult = await api.post(
      API_ROUTE_FULL_PATHS.patientUpdateTreatmentPhaseId,
      { eyeId, treatmentPhaseId }
    );

    if (updateResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: updateResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updateResult });
  }
};

export const toggleEyeTreatmentStatus = async ({
  eyeId,
  status,
}: PickRename<EyeDetailsProps, "id", "eyeId"> & {
  status: string;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let updateResult = null;
  try {
    updateResult = await api.post(
      API_ROUTE_FULL_PATHS.patientToggleEyeTreatmentStatus,
      { eyeId, status }
    );

    if (updateResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: updateResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updateResult });
  }
};

export const sendSMSToCG = async ({
  mobileNumber,
  patientId,
}: {
  mobileNumber: string;
  patientId?: null | number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let updateResult = null;
  try {
    updateResult = await api.post(API_ROUTE_FULL_PATHS.caregiverSMS, {
      mobileNumber,
      patientId,
    });

    if (updateResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: updateResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updateResult });
  }
};
export const sendAuthSMS = async ({
  firstName,
  lastName,
  mobileNumber,
  patientId,
}: {
  firstName: string;
  lastName: string;
  mobileNumber: string;
  patientId?: null | number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let updateResult = null;
  try {
    updateResult = await api.post(API_ROUTE_FULL_PATHS.patientSendAuthSMS, {
      firstName,
      lastName,
      mobileNumber,
      patientId,
    });

    if (updateResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: updateResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updateResult });
  }
};

export const getPatientEncryptData = async ({
  encryptdata,
}: {
  encryptdata: string;
}): Promise<{
  success: boolean;
  status?: number;
  data?: {
    firstName: string;
    lastName: string;
    mobileNumber: string;
  };
}> => {
  let getResult = null;
  try {
    getResult = await api.get(
      API_ROUTE_FULL_PATHS.patientGetPatientEncryptData,
      {
        params: { encryptdata },
      }
    );

    if (getResult.status === STATUS_200)
      return {
        success: true,
        data: getResult.data.data,
      };

    notificationToUser({ msg: getResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: getResult });
  }
};

export const checkCGConsent = async ({
  mobileNumber,
  patientId,
}: {
  mobileNumber: string;
  patientId?: null | number;
}): Promise<{
  success: boolean;
  status?: number;
  data?: {
    consent: boolean;
  };
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.patientCheckCgConsent, {
      mobileNumber,
      patientId,
    });

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
        data: confirmResult.data.data,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};
export const checkPatientConsent = async ({
  mobileNumber,
  patientId,
}: {
  mobileNumber: string;
  patientId?: null | number;
}): Promise<{
  success: boolean;
  status?: number;
  data?: {
    consent: boolean;
  };
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(
      API_ROUTE_FULL_PATHS.patientCheckPatientConsent,
      {
        mobileNumber,
        patientId,
      }
    );

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
        data: confirmResult.data.data,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const postPatientEncryptData = async ({
  encryptdata,
}: {
  encryptdata: string;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(
      API_ROUTE_FULL_PATHS.patientPostPatientEncryptData,
      {
        encryptdata,
      }
    );

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const postCaregiverEncryptData = async ({
  encryptdata,
}: {
  encryptdata: string;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(
      API_ROUTE_FULL_PATHS.patientPostCaregiverEncryptData,
      {
        encryptdata,
      }
    );

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const checkPatientExist = async ({
  mobileNumber,
}: {
  mobileNumber: string;
}): Promise<{
  success: boolean;
  status?: number;
  data?: RequestPatientAccessProps;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(
      API_ROUTE_FULL_PATHS.patientCheckPatientExist,
      {
        mobileNumber,
      }
    );

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
        data: confirmResult.data.data,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const requestPatientAccess = async ({
  patientId,
  ownerId,
}: {
  patientId: number;
  ownerId: number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.requestPatientAccess, {
      patientId,
      hcpId: ownerId,
    });

    if (confirmResult.status === STATUS_200) {
      toast.success(
        i18n.t("message.toast.requestPatientAccessSuccess") as string
      );
      return {
        success: true,
      };
    }

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const patientEditCheck = async ({
  patientId,
}: {
  patientId: number;
}): Promise<{
  success: boolean;
  status?: number;
  data?: boolean;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.patientEditCheck, {
      patientId,
    });

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
        data: confirmResult.data.data,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const updateEditCheck = async ({
  patientId,
}: {
  patientId: number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.updateEditCheck, {
      patientId,
    });

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};
