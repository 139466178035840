const base64TofileConvertor = (imagURI:string) => {
    const baseHeader = "base64,";
    const properImgURI = imagURI.slice(imagURI.indexOf(baseHeader) + (baseHeader.length));
    const byteCharacters = atob(properImgURI);
    const sliceSize = 512;
    const byteArrays = [];

    for (
      let offset = 0;
      offset < byteCharacters.length;
      offset += sliceSize
    ) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, "image", { type: "image/jpeg" });
  
};

export default base64TofileConvertor;
