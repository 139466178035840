import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";
import { useForm, SubmitErrorHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { userLogin } from "../../services/user";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";
import BaseButton from "../shareComponents/button/BaseButton";
import FormInput from "../shareComponents/form/FormInput";
import emailYup from "../../utils/helperFunction/yupValidations/email";
import { passwordSimpleYup } from "../../utils/helperFunction/yupValidations/password";
import { UserProps } from "../../utils/types/services/user";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
  FormType,
} from "../../utils/constants/ga";
import InnovisionNameWithIcon from "../../assets/images/Innovision/NameWithIcon.png";

const schema = yup.object().shape({
  email: emailYup,
  password: passwordSimpleYup,
});

type LoginFormFields = Pick<UserProps, "email" | "password">;

const LoginFormMobile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const useFormMethods = useForm<LoginFormFields>({
    defaultValues: {
      email: "",
      password: "",
    },
    resolver: yupResolver(schema),
  });

  const { control, handleSubmit, getValues, formState } = useFormMethods;

  const { refetch } = useQuery([REACT_QUERY_KEYS.USER_DATA], () => {
    const [email, password] = getValues(["email", "password"]);
    return userLogin(email, password);
  });

  const onSubmit = useCallback(async () => {
    const loginResult = await refetch();
    ReactGA.event({
      category: CategoryType.FormSubmit,
      action: FormType.LoginForm,
      label: (loginResult.data?.data?.userId ?? -1).toString(),
      value: 200,
    });
  }, [refetch]);

  const handleSubmitError: SubmitErrorHandler<LoginFormFields> = useCallback(
    (error) => {
      const fields = Object.keys(error);
      ReactGA.event({
        category: `${CategoryType.FormValidationError}_Other_${FormType.LoginForm}`,
        action: FormType.LoginForm,
        label: `${fields.flatMap((e) => e)}`,
        nonInteraction: false,
        value: 400,
      });
    },
    []
  );

  const onClickForgotPassword = useCallback(() => {
    navigate(ROUTE_PATHS.FORGET_PASSWORD);
    ReactGA.event({
      category: CategoryType.Navigation,
      action: NavigationActionType.Login_ForgotPassword,
    });
  }, []);

  const onClickRegister = useCallback(() => {
    navigate(ROUTE_PATHS.REGISTRATION);
    ReactGA.event({
      category: CategoryType.Navigation,
      action: NavigationActionType.Login_Registration,
    });
  }, []);

  return (
    <Grid container item xs={8} flexDirection="column" alignItems="flex-start">
      <img
        src={InnovisionNameWithIcon}
        style={{
          maxHeight: "auto",
          maxWidth: "30vmax",
          objectFit: "contain",
          marginBottom: 2,
        }}
        alt="app-icon"
        loading="lazy"
      />

      <form
        style={{ width: "100%" }}
        onSubmit={handleSubmit(onSubmit, handleSubmitError)}
      >
        <FormInput
          control={control}
          name="email"
          label={t("singlePharse.email")}
          variant="outlined"
          fullWidth
          disabled={formState.isSubmitting}
          autoComplete="username"
          type="email"
        />

        <FormInput
          control={control}
          name="password"
          label={t("singlePharse.password")}
          variant="outlined"
          type="password"
          fullWidth
          autoComplete="current-password"
          disabled={formState.isSubmitting}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit(onSubmit, handleSubmitError)();
            }
          }}
        />

        <BaseButton
          sx={{ width: "100%", marginBottom: 2 }}
          type="submit"
          disabled={formState.isSubmitting}
          // onClick={handleSubmit(onSubmit, handleSubmitError)}
        >
          {t("singlePharse.login")}
        </BaseButton>
      </form>

      <Typography
        onClick={onClickForgotPassword}
        fontWeight="medium"
        color={"primary.main"}
        sx={{ alignSelf: "center", cursor: "pointer" }}
      >
        {t("loginPage.forgotPassword")}
      </Typography>

      <Divider sx={{ width: "100%", marginTop: 5, marginBottom: 2 }} />

      <Grid container flexDirection={"row"}>
        <Typography
          variant="subtitle1"
          color={"text.secondary"}
          sx={{
            display: "inline",
            alignSelf: "center",
          }}
        >
          {t("loginPage.dontHaveAccount")}&nbsp;&nbsp;
        </Typography>
        <Typography
          onClick={onClickRegister}
          variant="subtitle1"
          color={"primary.main"}
          sx={{ display: "inline", cursor: "pointer" }}
        >
          {t("singlePharse.register")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default LoginFormMobile;
