import { CSSProperties, useMemo } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { MAX_UPLOAD_FILE_SIZE } from "../../../utils/constants/general";
import { Grid } from "@mui/material";

const baseStyle: CSSProperties = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
} as const;

const focusedStyle = {
  borderColor: "#2196f3",
} as const;

const acceptStyle = {
  borderColor: "#00e676",
} as const;

const rejectStyle = {
  borderColor: "#ff1744",
} as const;

interface StyledDropzoneProps {
  onDrop: (files: File[]) => void;
  onChange: React.InputHTMLAttributes<HTMLInputElement>["onChange"];
  multiple?: boolean;
}

const StyledDropzone = (props: StyledDropzoneProps) => {
  const { onDrop, onChange, multiple = true } = props;
  const { t } = useTranslation();

  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      onDrop,
      multiple,
      maxSize: MAX_UPLOAD_FILE_SIZE,
    });

  const style = {
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {}),
  };

  return (
    <div className="container">
      <div {...getRootProps({ style })}>
        <input {...getInputProps({ onChange })} />
        <Grid
          item
          display={{
            xs: "none",
            sm: "none",
            md: "block",
          }}
        >
          <p>{t(multiple ? "dropZone.multipleItem" : "dropZone.singleItem")}</p>
        </Grid>
        <Grid
          item
          display={{
            xs: "block",
            sm: "block",
            md: "none",
          }}
        >
          <p>{t("dropZone.selectFromMobile")}</p>
        </Grid>
      </div>
    </div>
  );
};

export default StyledDropzone;
