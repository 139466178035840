import { Grid, Menu, Box, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { userGetData } from "../../services/user";
import {
  ISideBarItems,
  SIDE_BAR_ITEMS_ADMIN,
  SIDE_BAR_ITEMS_HCP_MOBILE,
  SIDE_BAR_ITEMS_IT_ADMIN,
} from "../SideBar/SideBarItems";

import ReactGA from "react-ga4";
import { CategoryType, NavigationActionType } from "../../utils/constants/ga";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MobileMenu = () => {
  const { data } = useQuery([REACT_QUERY_KEYS.USER_DATA], userGetData);

  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const renderSideBarItem = useCallback(
    (props: ISideBarItems, index: number) => {
      const { name, icon, to, navigationActionType } = props;
      const onClickAction = () => {
        navigate(to);
        handleCloseNavMenu();
        ReactGA.event({
          category: CategoryType.Navigation,
          action: NavigationActionType[navigationActionType],
        });
      };

      return (
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            padding: "5px 10px",
          }}
          onClick={onClickAction}
          key={index}
        >
          <IconButton color={location.pathname === to ? "primary" : undefined}>
            {icon}
          </IconButton>
          <Typography variant="subtitle2">{t(name)}</Typography>
        </Box>
      );
    },
    [t, location]
  );

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Grid container direction={"row"} alignItems={"center"}>
      <IconButton
        size="large"
        aria-label="account of current user"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="inherit"
      >
        <MenuIcon />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {data?.data?.role === "HCP"
          ? SIDE_BAR_ITEMS_HCP_MOBILE.map(renderSideBarItem)
          : data?.data?.role === "admin"
          ? SIDE_BAR_ITEMS_ADMIN.map(renderSideBarItem)
          : data?.data?.role === "IT Admin" &&
            SIDE_BAR_ITEMS_IT_ADMIN.map(renderSideBarItem)}
      </Menu>
    </Grid>
  );
};

export default MobileMenu;
