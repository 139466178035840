import { TextFieldProps } from "@mui/material";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import BaseInput from "../input/BaseInput";
import ControlledFormElement from "./ControlledFormElement";

type FormInputProps<T extends FieldValues = FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  isCustomError?: boolean;
  customErrorMessage?: string;
} & TextFieldProps;

const FormInput = <T extends FieldValues>(props: FormInputProps<T>) => {
  const {
    control,
    name,
    isCustomError,
    customErrorMessage,
    children,
    ...inputProps
  } = props;

  return (
    <ControlledFormElement control={control} name={name}>
      {({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <BaseInput
          inputRef={ref}
          name={name}
          value={value}
          onChange={onChange}
          error={!!error || isCustomError}
          helperText={
            error?.message
              ? error?.message
              : isCustomError
              ? customErrorMessage
              : " "
          }
          {...inputProps}
        >
          {children}
        </BaseInput>
      )}
    </ControlledFormElement>
  );
};

export default FormInput;
