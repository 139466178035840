import * as React from "react";

export interface FilterPopoverProps {
  content: React.ReactNode;
  closePopover: number;
  filtered: boolean;
}

export const ChartItemColor = {
  blue: "rgba(0,102,201,1)",
  red: "rgba(239,102,142,1)",
  orange: "rgba(245,185,107,1)",
  purple: "rgba(120,71,134,1)",
  grey: "grey",
  green: "green",
} as const;

export const DiseaseTypeColor = {
  nAMD: ChartItemColor.blue,
  PCV: ChartItemColor.red,
  DME: ChartItemColor.orange,
  RVO: ChartItemColor.purple,
  mCNV: ChartItemColor.grey,
  Others: ChartItemColor.green,
  Combined: "black",
} as const;
