import { Doughnut } from "react-chartjs-2";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ChartData, ChartOptions, Plugin } from "chart.js";
import FilterPopover from "../FilterPopover";
import FilterOptionsPopover, {
  FilterValueProps,
  DiseaseTypeProps,
} from "./FilterOptionsPopover";
import { DiseaseTypeColor } from "../type";
import { getPatientByDiseaseType } from "../../../services/dashboard";
import { useQueries } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import _ from "lodash";
import { useTheme } from "@mui/system";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";

const defaultValue = {
  countBy: 1,
  diseaseType: {
    nAMD: true,
    PCV: true,
    DME: true,
    RVO: true,
    mCNV: true,
    others: true,
  },
  timeReleated: {
    allTime: true,
    fromDate: null,
    toDate: null,
  },
} as const;

const PatientDiseaseTypeChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const NUM_100 = 100;
  const NUM_300 = 300;
  const NUM_2 = 2;
  const NUM_160 = 160;

  const [value, setValue] = useState<FilterValueProps>(defaultValue);

  const [closePopover, setClosePopover] = useState<number>(0);

  const [{ data: graphData, refetch, isFetching }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.PATIENTDISEASETYPECHART],
        queryFn: () =>
          getPatientByDiseaseType({
            fromDate: value.timeReleated.allTime
              ? null
              : value.timeReleated.fromDate?.toISOString() ?? null,
            toDate: value.timeReleated.allTime
              ? null
              : value.timeReleated.toDate?.toISOString() ?? null,
            type: value.countBy,
          }),
        retry: true,
        refetchOnMount: "always",
      },
    ],
  });

  const [filteredLabels, filteredData] = useMemo(() => {
    const data = graphData?.data ?? [];
    const labels: string[] = [];
    const numbers: number[] = [];
    const diseaseTypeKeys = Object.keys(value.diseaseType);
    const NUM_1000 = 1000;

    if (value.countBy === 1) {
      const selectedList: string[] = [];
      diseaseTypeKeys.forEach((e) => {
        if (!value.diseaseType[e as keyof DiseaseTypeProps]) return;
        selectedList.push(e.toLowerCase());
      });

      const newData = data.map((e) => ({
        count: e.count,
        adjOSDiseaseType: selectedList.includes(
          e.osDiseaseTypeName?.toLowerCase() ?? ""
        )
          ? e.osDiseaseTypeName
          : null,
        adjOdDiseaseType: selectedList.includes(
          e.odDiseaseTypeName?.toLowerCase() ?? ""
        )
          ? e.odDiseaseTypeName
          : null,
      }));

      const newData2 = newData.filter(
        (e) => e.adjOSDiseaseType !== null || e.adjOdDiseaseType !== null
      );

      const newData3 = newData2.map((e) => ({
        finalizedName:
          e.adjOSDiseaseType === null
            ? e.adjOdDiseaseType
            : e.adjOdDiseaseType === null
            ? e.adjOSDiseaseType
            : e.adjOSDiseaseType === e.adjOdDiseaseType
            ? e.adjOSDiseaseType
            : "Combined",
        sortValue:
          e.adjOSDiseaseType === null
            ? selectedList.findIndex(
                (ee) => ee === e.adjOdDiseaseType?.toLowerCase() ?? ""
              )
            : e.adjOdDiseaseType === null
            ? selectedList.findIndex(
                (ee) => ee === e.adjOSDiseaseType?.toLowerCase() ?? ""
              )
            : e.adjOSDiseaseType === e.adjOdDiseaseType
            ? selectedList.findIndex(
                (ee) => ee === e.adjOSDiseaseType?.toLowerCase() ?? ""
              )
            : NUM_1000,
        count: e.count,
      }));

      const uniqData = _.sortBy(
        _.uniqBy(newData3, "finalizedName"),
        "sortValue"
      );

      uniqData.forEach((e, i) => {
        const totalArray = newData3.filter(
          (ee) => ee.finalizedName === e.finalizedName
        );
        let total = 0;
        totalArray.forEach((a) => {
          total += a.count;
        });
        labels.push(e.finalizedName!);
        numbers.push(total);
      });

      return [labels, numbers];
    }

    diseaseTypeKeys.forEach((e) => {
      if (!value.diseaseType[e as keyof DiseaseTypeProps]) return;
      const findResult = data.find(
        (ee) => ee.diseaseTypeName?.toLowerCase() === e.toLowerCase()
      );
      if (findResult) {
        labels.push(findResult.diseaseTypeName!);
        numbers.push(findResult.count);
      }
    });

    return [labels, numbers];
  }, [value.diseaseType, value.countBy, graphData]);

  const chart_data = useMemo<ChartData<"doughnut", any[], unknown>>(
    () => ({
      labels: filteredLabels,
      datasets: [
        {
          label: "PatientDiseaseTypeChart",
          data: filteredData,
          backgroundColor: _.map(
            filteredLabels,
            (e) => DiseaseTypeColor[e as keyof typeof DiseaseTypeColor]
          ),
          hoverOffset: 4,
        },
      ],
    }),
    [filteredLabels, filteredData]
  );

  const options = useMemo<ChartOptions<"doughnut">>(
    () => ({
      maintainAspectRatio: false,
      layout: {
        padding: 2,
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      plugins: {
        legend: {
          onClick: () => null,
          labels: {
            color: theme.palette.text.primary,
          },
        },
        datalabels: {
          display: "auto",
          color: theme.palette.common.white,
          font: {
            size: 17,
          },
          formatter: (val, context) => {
            const data = context.dataset.data as number[];
            const total = data.reduce((partialSum, a) => partialSum + a, 0);
            return [val, `(${Math.round((val / total) * NUM_100)}%)`];
          },
          textAlign: "center",
        },
        tooltip: {
          callbacks: {
            label: ({ dataset: { data }, label, formattedValue, parsed }) => {
              const total = data.reduce((partialSum, a) => partialSum + a, 0);
              return `${label}: ${formattedValue} (${Math.round(
                (parsed / total) * NUM_100
              )}%)`;
            },
          },
        },
        title: {
          font: {
            size: 20,
          },
          color: theme.palette.text.primary,
          display: true,
          text:
            value.countBy === 1
              ? t("dashboardPage.patientDiseaseTypeChart.title")
              : t("dashboardPage.patientDiseaseTypeChart.titleOptional"),
        },
        subtitle: {
          font: {
            size: 15,
          },
          color: theme.palette.text.primary,
          display: true,
          text:
            value.countBy === 1
              ? t("dashboardPage.patientDiseaseTypeChart.subTitle")
              : t("dashboardPage.patientDiseaseTypeChart.subTitleOptional"),
        },
      },
    }),
    [t, value.countBy]
  );

  const plugins: Plugin<"doughnut", { [key in string]: any }>[] = [
    {
      id: "middleText",
      beforeDraw: function (chart) {
        const data = chart.data.datasets[0].data as number[];
        const total = data.reduce((partialSum, a) => partialSum + a, 0);
        if (total === 0) return;

        const width = chart.width;
        const height = chart.height;
        const chartAreaHeight = chart.chartArea.height;

        chart.ctx.fillStyle = theme.palette.text.primary;
        const ctx = chart.ctx;
        ctx.restore();
        const fontSize = (height / NUM_300).toFixed(NUM_2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "bottom";
        const text = t("singlePharse.total");
        const textX = Math.round((width - ctx.measureText(text).width) / NUM_2);
        const textY = height - chartAreaHeight / NUM_2;
        ctx.fillText(text, textX, textY);
        ctx.save();

        const ctx2 = chart.ctx;
        ctx2.restore();
        const fontSize2 = (height / NUM_160).toFixed(NUM_2);
        ctx2.font = fontSize2 + "em sans-serif";
        ctx2.textBaseline = "top";
        const text2 = total.toString();
        const textX2 = Math.round(
          (width - ctx2.measureText(text2).width) / NUM_2
        );
        const textY2 = textY;

        ctx2.fillText(text2, textX2, textY2);
        ctx2.save();
      },
    },
  ];

  const applyFilter = useCallback(
    (apply: boolean, data: FilterValueProps | null) => {
      if (apply) setValue({ ...data! });
      setClosePopover((val) => {
        ++val;
        return val;
      });
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Dashboard_Apply_Filter,
        label: t("dashboardPage.patientDiseaseTypeChart.title"),
      });
    },
    [t]
  );

  useEffect(() => {
    if (isFetching) return;
    refetch();
  }, [value.countBy, value.timeReleated]);

  const isFilterApplied = useMemo(() => {
    return !_.isEqual(defaultValue, value);
  }, [value]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: "background.paper",
        p: 2,
      }}
      direction="row"
    >
      <Grid
        item
        xs={11}
        sx={{ height: { md: "37vh", sm: "45vh", xs: "45vh" }, minHeight: 300 }}
      >
        <Doughnut data={chart_data} options={options} plugins={plugins} />
      </Grid>
      <Grid item xs={1}>
        <FilterPopover
          filtered={isFilterApplied}
          closePopover={closePopover}
          content={
            <FilterOptionsPopover value={value} applyFilter={applyFilter} />
          }
        />
      </Grid>
    </Grid>
  );
};

export default PatientDiseaseTypeChart;
