import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import { Divider, Grid, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { userLogout } from "../../../../services/user";
import { REACT_QUERY_KEYS } from "../../../../utils/constants/reactQueryKeys";
import { ROUTE_PATHS } from "../../../../utils/constants/routes/webPaths";
import BaseButton from "../../button/BaseButton";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../../utils/constants/ga";

interface ProfilePopoverProps {
  handlePopoverClose: () => void;
}

const ProfilePopover = (props: ProfilePopoverProps) => {
  const { handlePopoverClose } = props;

  const { t } = useTranslation();
  const navigate = useNavigate();

  const { data, refetch, isRefetching } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userLogout
  );

  const onClickLogout = useCallback(async () => {
    const logoutResut = await refetch();
    setTimeout(() => {
      localStorage.setItem("initPath", "");
    }, 0);

    ReactGA.event({
      category: CategoryType.Navigation,
      action: NavigationActionType.Logout,
      value: logoutResut.data?.data?.userId ?? -1,
    });
  }, [refetch]);

  const onClickUpdateProfile = useCallback(() => {
    handlePopoverClose();
    navigate(ROUTE_PATHS.PROFILE);
    ReactGA.event({
      category: CategoryType.Navigation,
      action: NavigationActionType.Popover_UpdateProfile,
    });
  }, [handlePopoverClose]);

  return (
    <Grid
      sx={{ padding: 3 }}
      container
      flexDirection="column"
      justifyContent="center"
    >
      <Typography variant="h6" align="center">
        {data?.data?.nickName}
      </Typography>
      <Typography
        variant="caption"
        align="center"
        sx={{ color: "text.secondary", marginBottom: 2, lineBreak: "anywhere" }}
      >
        {data?.data?.email}
      </Typography>
      {data?.data?.role === "HCP" && (
        <React.Fragment>
          <BaseButton
            onClick={onClickUpdateProfile}
            disabled={isRefetching}
            endIcon={<ArrowForwardIosIcon fontSize="small" />}
          >
            {t("userProfilePage.update")}
          </BaseButton>
          <Divider sx={{ margin: "15px 0" }} />
        </React.Fragment>
      )}
      <BaseButton
        startIcon={<PowerSettingsNewIcon />}
        onClick={onClickLogout}
        disabled={isRefetching}
      >
        {t("singlePharse.logout")}
      </BaseButton>
    </Grid>
  );
};

export default ProfilePopover;
