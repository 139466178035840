import { useTranslation } from "react-i18next";
import { Grid, Typography, Link } from "@mui/material";

interface TermsconditionsProps {
  closeCurrentModalOpenPrivatePolicy: () => void;
}

const Termsconditions = (props: TermsconditionsProps) => {
  const { closeCurrentModalOpenPrivatePolicy } = props;
  const { t } = useTranslation();
  return (
    <Grid item xs={12}>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.mainParagraph")}
      </Typography>

      <Typography variant="subtitle1" fontWeight={"bold"}>
        {t("policyText.termsconditions.subTitle1")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph1_1")}
        <Link onClick={closeCurrentModalOpenPrivatePolicy}>
          {t("policyText.termsconditions.subParagraph1_2")}
        </Link>
        {t("policyText.termsconditions.subParagraph1_3")}
      </Typography>
      <Typography variant="subtitle2">
        {t("policyText.termsconditions.subParagraph1_4")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph1_5")}

        {t("policyText.termsconditions.subParagraph1_6")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph1_7")}
      </Typography>

      <Typography variant="subtitle1" fontWeight={"bold"}>
        {t("policyText.termsconditions.subTitle2")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph2_1")}
      </Typography>

      <Typography variant="subtitle1" fontWeight={"bold"}>
        {t("policyText.termsconditions.subTitle3")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph3_1")}
      </Typography>

      <Typography variant="subtitle1" fontWeight={"bold"}>
        {t("policyText.termsconditions.subTitle4")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph4_1")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph4_2")}
      </Typography>

      <Typography variant="subtitle1" fontWeight={"bold"}>
        {t("policyText.termsconditions.subTitle5")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph5_1")}
      </Typography>

      <Typography variant="subtitle1" fontWeight={"bold"}>
        {t("policyText.termsconditions.subTitle6")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.termsconditions.subParagraph6_1")}
      </Typography>
    </Grid>
  );
};

export default Termsconditions;
