import { Box, Tab, Tabs } from "@mui/material";
import React, {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
  useRef,
} from "react";
import "./index.css";

const nonSticky = {
  position: "initial",
  width: "initial",
} as const;

export interface TabItem {
  label: ReactNode;
  component: ReactNode;
}

interface TabPanelProps {
  children: ReactNode;
  index: number;
  currentTabIndex: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, index, currentTabIndex } = props;
  return (
    <React.Fragment>
      {currentTabIndex === index ? children : null}
    </React.Fragment>
  );
};

type ORIENTATION = "horizontal" | "vertical" | undefined;

interface TabViewProps {
  tabs: TabItem[];
  getCurrentTabIndex?: (index: number) => void;
  changeCurrentTabIndexTo?: number;
  orientation?: ORIENTATION;
  fixedHeader?: boolean;
  handleTabClick?: () => void;
}

const TabView = (props: TabViewProps) => {
  const {
    tabs,
    orientation,
    fixedHeader,
    getCurrentTabIndex,
    changeCurrentTabIndexTo,
    handleTabClick,
  } = props;

  const NUM_60 = 60;
  const NUM_180 = 180;

  const [currentTabIndex, setCurrentTabIndex] = useState<number>(0);

  const inputRef = useRef<HTMLDivElement | null>(null);

  const [stickyHeader, setStickyHeader] = useState(false);

  const handleTabChange = useCallback(
    (_: SyntheticEvent<Element, Event>, index: number) => {
      setCurrentTabIndex(index);
    },
    []
  );

  useEffect(() => {
    if (getCurrentTabIndex !== undefined) getCurrentTabIndex(currentTabIndex);
  }, [currentTabIndex]);

  useEffect(() => {
    if (
      changeCurrentTabIndexTo !== undefined &&
      changeCurrentTabIndexTo !== currentTabIndex
    )
      setCurrentTabIndex(changeCurrentTabIndexTo);
  }, [changeCurrentTabIndexTo]);

  React.useEffect(() => {
    const handleScroll = () => {
      const sticky = inputRef.current
        ? inputRef.current?.offsetTop - NUM_60
        : 0;
      if (window.pageYOffset > NUM_180 && window.pageYOffset > sticky) {
        setStickyHeader(true);
      } else {
        setStickyHeader(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <React.Fragment>
      <Box
        ref={inputRef}
        className={fixedHeader && stickyHeader === true ? "stickyH" : ""}
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          orientation={orientation ? orientation : "horizontal"}
          value={currentTabIndex}
          onChange={handleTabChange}
          onClick={handleTabClick}
        >
          {tabs.map(({ label }, i) => (
            <Tab
              key={i}
              label={label}
              sx={{
                textTransform: "none",
                alignItems:
                  orientation && orientation === "vertical"
                    ? "start"
                    : "center",
                textAlign:
                  orientation && orientation === "vertical"
                    ? "start"
                    : "center",
              }}
            />
          ))}
        </Tabs>
      </Box>
      <Box
        sx={{
          pt: 3,
        }}
        style={{
          paddingTop:
            stickyHeader === true && inputRef.current
              ? inputRef.current.clientHeight
              : "",
        }}
      >
        {tabs.map(({ component }, i) => (
          <TabPanel index={i} currentTabIndex={currentTabIndex} key={i}>
            {component}
          </TabPanel>
        ))}
      </Box>
    </React.Fragment>
  );
};

export default TabView;
