import { Box, Toolbar } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Navigate, useOutlet } from "react-router-dom";
import { userGetData } from "../../services/user";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";
import Footer from "../Footer";
import Appbar from "../Appbar";
import SideBar from "../SideBar";

const ProtectedAdminLayer = () => {
  const outlet = useOutlet();
  const { data, isSuccess, isLoading } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const STATUS_401 = 401;

  if (isLoading) return <div></div>;

  if (isSuccess) {
    if (data.status === STATUS_401)
      return <Navigate to={ROUTE_PATHS.LOGIN} replace />;
    if (data.data?.role === "HCP")
      return <Navigate to={ROUTE_PATHS.HCPINIT} replace />;
    if (data.data?.role === "IT Admin")
      return <Navigate to={ROUTE_PATHS.ADMINITINIT} replace />;
  }

  return (
    <Box sx={{ minHeight: "100%", backgroundColor: "background" }}>
      <Box
        sx={{
          display: "flex",
          minHeight: "100%",
          pb: "7vh",
        }}
      >
        <Appbar />
        <SideBar />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            minHeight: "100%",
            height: "fit-content",
          }}
        >
          <Toolbar />
          {outlet}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};

export default ProtectedAdminLayer;
