import { Typography, Grid, Box, Divider } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import { isMobile } from "react-device-detect";
import BaseCheckBox from "../../shareComponents/checkbox/BaseCheckBox";

interface TermsAndConditionsProps {
  backFromTerms: () => void;
  handleAccept: () => void;
}

const TermsAndConditions = (props: TermsAndConditionsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { backFromTerms, handleAccept } = props;
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckBoxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsChecked(event.target.checked);
    },
    []
  );

  return (
    <Grid item xs={12}>
      <Typography
        variant="h4"
        sx={{
          color: "#FFF",
          fontWeight: "bold",
          mt: "4vh",
          textAlign: "center",
        }}
      >
        Terms & Conditions
      </Typography>

      <Grid item sx={{ backgroundColor: "background.paper", mt: "4vh", mb: 2 }}>
        <Grid sx={{ p: 3 }}>
          <Typography variant="h5" sx={{ fontWeight: "bold", mb: 2 }}>
            Care For Vision Subsidy Program Terms & Conditions{" "}
          </Typography>
          <Divider></Divider>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Agreement to join the Patient Support Programs
          </Typography>
          <Typography variant="body1">
            As always, Roche is committed to serve our patients in order to
            achieve the best treatment outcomes. Currently, the high treatment
            burden on clinical capacity in the public sector and high treatment
            cost for long term disease management are major pain points in
            retinal diseases patient journey. Roche is pleased to introduce two
            co-created solutions with the partnership of our stakeholders, Lok
            Sin Tong, to drive the patients for early and timely Vabysmo
            injection.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please find the details of the patient support program for Vabysmo
            treated patients. 
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Care for Vision Subsidy Program: {" "}
          </Typography>
          <Typography variant="body1">
            Program Period: September 1, 2024 to August 31, 2025
          </Typography>
          <Typography variant="body1">
            Program quotas: 500 eye applications{" "}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Patient enrollment criteria: 
          </Typography>
          <Box>
            <ol style={{ paddingLeft: "18px" }}>
              <li>
                Patient is currently following up in ophthalmology clinic in HA
                Hospital with the HA appointment slip as documented proof.
              </li>
              <li>
                Patient has been diagnosed by the ophthalmologist with approved
                indications for Vabysmo (Faricimab) in HK. i.e. nAMD and DME.
              </li>
              <li>
                Patient is waiting for Vabysmo injection in HA Hospital with
                referral letter or HA IVT form as documented proof.
              </li>
            </ol>
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Program benefits for patient:
          </Typography>
          <Typography variant="body1">
            Successful applicants can receive a subsidy of HKD $1,500 per
            injection, with a maximum of 3 injections per eye in one year
            period.{" "}
          </Typography>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Role of Lok Sin Tong{" "}
          </Typography>
          <Box>
            <Typography variant="body1">
              The pharmacist in Lok Sin Tong will provide disease awareness
              information for patient’s reference and also responsible to
            </Typography>
            <ol style={{ paddingLeft: "18px" }}>
              <li>
                Verify the eligibility of the applicant during the enrollment
                process
              </li>
              <li>Reimburse the subsidy amount to the patient directly</li>
            </ol>
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Role of Private Ophthalmologist
          </Typography>
          <Box>
            <Typography variant="body1">
              3 simple steps are required the private ophthalmologists to
              support in order to verify the Vabysmo injection being completed
              in the private clinic.
            </Typography>
            <ol style={{ paddingLeft: "18px" }}>
              <li>
                Create the patient profile in InnoVision and send the patient
                consent request
              </li>
              <li>
                Record the Vabysmo injection in InnoVision by scanning the 2D
                matrix code from Vabysmo outer box. The injection record from
                InnoVision will be shared to your patient in the Care For Vision
                App.
              </li>
              <li>
                Provide Vabysmo injection receipt for patient as one of the
                program enrollment documents
              </li>
            </ol>
          </Box>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Remarks:
          </Typography>
          <Box>
            <ol style={{ paddingLeft: "18px" }}>
              <li>
                Lok Sin Tong does not favor the rights of any chosen doctor and
                does not recommend any doctor to patients. Patients can choose
                private ophthalmologists participating or not participating in
                the subsidy program through the Care for Vision mobile app.
              </li>
              <li>
                This subsidy program does not engage in any market price control
                (including drug prices and doctor fees). Lok Sin Tong will not
                intervene in any fee-related matters. For details regarding
                doctor fees, Lok Sin Tong will recommend the patient to ask the
                selected ophthalmologist.
              </li>
              <li>
                Lok Sin Tong does not favor or promote any specific medications
                for patients.
              </li>
            </ol>
          </Box>

          <Typography variant="subtitle1" sx={{ fontWeight: "bold", mt: 2 }}>
            Call for Safety Reporting:
          </Typography>
          <Typography variant="body1">
            If you have a suspected side effect or problem to report regarding
            one of our products, please discuss with your treating Healthcare
            Professional and provide the information to the Roche Patient Safety
            Team via: a) Email: <u>hong_kong_safety@roche.com</u>, or b) Phone:
            +852 2733 4711 
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent={"flex-end"}
        direction={"row"}
        alignItems={"center"}
        sx={{ backgroundColor: "background.paper", mb: 2, p: 3 }}
      >
        <Grid container flexDirection={"row"}>
          <Grid>
            <BaseCheckBox onChange={handleCheckBoxChange} />
          </Grid>
          <Grid xs item>
            <Typography
              variant="subtitle1"
              sx={{ fontWeight: "bold", pt: 1.2 }}
            >
              Consent with Participating Ophthalmologist
            </Typography>
            <Typography sx={{ textAlign: "left" }} variant="body1">
              I have read and understood the details of the Care for Vision
              Subsidy Program and am willing to participate in these promotional
              programs.
            </Typography>
          </Grid>
        </Grid>
        <SecondaryButton sx={{ mr: 2 }} onClick={backFromTerms}>
          Back
        </SecondaryButton>
        <BaseButton onClick={handleAccept} disabled={!isChecked}>
          Accept
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default TermsAndConditions;
