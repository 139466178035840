import {
  Autocomplete,
  AutocompleteProps,
  FormLabel,
  Grid,
} from "@mui/material";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import ControlledFormElement from "./ControlledFormElement";

type FormAutoCompleteProps<T extends FieldValues = FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
} & Omit<AutocompleteProps<any, undefined, undefined, undefined>, "">;

const FormAutoComplete = <T extends FieldValues>(
  props: FormAutoCompleteProps<T>
) => {
  const { control, name, label, ...otherProps } = props;

  return (
    <ControlledFormElement<T> control={control} name={name}>
      {({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <Grid container sx={otherProps.sx} flexDirection="column">
          <Autocomplete
            value={value}
            onChange={(e, data) => onChange(data)}
            {...otherProps}
            sx={{ width: "100%" }}
            autoSelect
            autoComplete
            autoHighlight
          />
          <FormLabel sx={{ color: "error.main", fontSize: 13 }}>
            {error?.message ?? " "}
          </FormLabel>
        </Grid>
      )}
    </ControlledFormElement>
  );
};

export default FormAutoComplete;
