import { Grid, Select, MenuItem, Box, SelectChangeEvent } from "@mui/material";
import { useCallback, useMemo, useState, useRef, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import {
  CategoryType,
  NavigationActionType,
} from "../../../utils/constants/ga";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { userGetPosologyReferenceImages } from "../../../services/user";

const PageMobile = () => {
  const { t } = useTranslation();
  const COMMON_WHITE = "common.white";
  const COMMON_BLACK = "common.black";
  const NUM_300 = 300;
  const NUM_2 = 2;
  const NUM_3 = 3;
  const inputRef = useRef<HTMLDivElement | null>(null);
  const [selectWidth, setSelectWidth] = useState(NUM_300);
  const select_data = [
    {
      id: 1,
      displayText: t("pTIReferencePage.tab.title1"),
    },
    {
      id: 2,
      displayText: t("pTIReferencePage.tab.title2"),
    },
    {
      id: 3,
      displayText: t("pTIReferencePage.tab.title3"),
    },
  ];

  const [selValue, setSelValue] = useState(1);

  const { data } = useQuery(
    [REACT_QUERY_KEYS.POSOLOGY_REFERENCE_IMAGE],
    () => {
      return userGetPosologyReferenceImages();
    },
    {
      retry: true,
    }
  );

  const [loading_1Img, pti_1Img, pti_2Img, dAA_1Img, dAA_2Img] = useMemo(() => {
    if (inputRef.current !== null) {
      setSelectWidth(inputRef.current.offsetWidth);
    }
    const loading_1Image = data?.data?.find(
      (e) => e.name === "loading_1"
    )?.imageUrl;
    const pti_1Image = data?.data?.find((e) => e.name === "pti_1")?.imageUrl;
    const pti_2Image = data?.data?.find((e) => e.name === "pti_2")?.imageUrl;
    const dAA_1Image = data?.data?.find((e) => e.name === "daa_1")?.imageUrl;
    const dAA_2Image = data?.data?.find((e) => e.name === "daa_2")?.imageUrl;

    return [loading_1Image, pti_1Image, pti_2Image, dAA_1Image, dAA_2Image];
  }, [data]);

  const handleValueChange = useCallback((val: number) => {
    let action = "";
    if (val === 1) action = NavigationActionType.PTIRef_Tab1;
    if (val === NUM_2) action = NavigationActionType.PTIRef_Tab2;
    if (val === NUM_3) action = NavigationActionType.PTIRef_Tab3;

    ReactGA.event({
      category: CategoryType.Navigation,
      action,
    });
  }, []);

  return (
    <Grid item xs={12} sx={{ mt: 2 }} ref={inputRef}>
      <Box sx={{ width: "100%" }}>
        <Select
          value={selValue}
          sx={{ width: selectWidth, background: "#FFFFFF" }}
          size="small"
          onChange={(e: SelectChangeEvent<number>) => {
            const temp = e.target.value as number;
            setSelValue(temp);
            handleValueChange(temp);
          }}
        >
          {select_data.map(({ id, displayText }) => (
            <MenuItem key={id} value={id} sx={{ whiteSpace: "normal" }}>
              {displayText}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Grid sx={{ mt: 3 }}>
        {selValue === 1 && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: COMMON_WHITE }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderStyle: "solid",
              }}
            >
              <img
                src={loading_1Img}
                alt="Initiation_Phase"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        )}
        {selValue === NUM_2 && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: COMMON_WHITE }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderBottomWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={dAA_1Img}
                alt="Disease_Activity_Assessment_1"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderTopWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={dAA_2Img}
                alt="Disease_Activity_Assessment_2"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        )}
        {selValue === NUM_3 && (
          <Grid
            container
            item
            xs={12}
            justifyContent="center"
            sx={{ backgroundColor: COMMON_WHITE }}
          >
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderBottomWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={pti_1Img}
                alt="Personalized_Treatment_Interval_1"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderColor: COMMON_BLACK,
                borderWidth: 1,
                borderTopWidth: 0,
                borderStyle: "solid",
              }}
            >
              <img
                src={pti_2Img}
                alt="Personalized_Treatment_Interval_2"
                loading="lazy"
                style={{ width: "100%", objectFit: "cover" }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default PageMobile;
