import { Typography, Box, Modal, Paper, Grid } from "@mui/material";
import BaseButton from "../button/BaseButton";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import { ModalViewType } from "../../../utils/constants/ga";
import ReactGA from "react-ga4";
import ModalBoldTitle from "./BoldTitle";
import SecondaryButton from "../button/SecondaryButton";
import { Theme } from "@mui/material/styles";

const style = (theme: Theme) =>
  ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 700,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  } as const);

interface SimpleModalProps {
  header: string;
  body: React.ReactNode;
  open: boolean;
  handleClose: () => void;
  buttons?: {
    positiveButtonIsLoading: boolean;
    positiveAction: () => void;
  };
}

const SimpleModal = (props: SimpleModalProps) => {
  const { header, body, open, handleClose, buttons } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (open)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.SimpleModal}/${header}`,
      });
  }, [open]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal"
      aria-describedby="simple-modal"
    >
      <Grid container sx={style}>
        <ModalBoldTitle title={header} />

        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            maxHeight: "50vh",
            overflow: "auto",
          }}
        >
          {typeof body === "string" ? (
            <Typography id="modal-modal-description">{body}</Typography>
          ) : (
            body
          )}
        </Grid>

        {buttons && (
          <Grid
            container
            item
            xs={12}
            alignItems="center"
            direction={"row"}
            justifyContent="flex-end"
            sx={{ mt: 1 }}
          >
            <SecondaryButton
              sx={{ mr: 1 }}
              onClick={handleClose}
              disabled={buttons.positiveButtonIsLoading}
            >
              {t("singlePharse.cancel")}
            </SecondaryButton>
            <BaseButton
              onClick={buttons.positiveAction}
              disabled={buttons.positiveButtonIsLoading}
            >
              {t("singlePharse.confirm")}
            </BaseButton>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};

export default SimpleModal;
