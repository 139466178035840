import { FormControl, FormControlLabel, FormLabel, Radio } from "@mui/material";
import { Control, useController } from "react-hook-form";
import { TreatmentRecordFormState } from "../../../../utils/types/ui/treatmentRecordForm";
import FormRadioGroup from "../../../shareComponents/form/FormRadioGroup";

type LABEL_TYPE = "HEME" | "PED" | "IRF/IRC" | "SRF";
type NAME_TYPE = "heme" | "ped" | "irf" | "srf";

interface TreatmentOtherInfoRadioInputProps {
  label: LABEL_TYPE;
  name: NAME_TYPE;
  control: Control<TreatmentRecordFormState>;
}

const TreatmentOtherInfoRadioInput = (
  props: TreatmentOtherInfoRadioInputProps
) => {
  const { label, name, control } = props;
  const useControllerReturnValues = useController({
    control,
    name,
  });
  const fieldState = useControllerReturnValues.fieldState;

  return (
    <FormControl sx={{ pb: 2 }}>
      <FormLabel sx={{ color: fieldState.error ? "error.main" : undefined }}>
        {label}
      </FormLabel>
      <FormRadioGroup control={control} name={name} row>
        <FormControlLabel
          control={<Radio size="small" />}
          label="Yes"
          value={true}
        />
        <FormControlLabel
          control={<Radio />}
          label="No"
          sx={{ ml: 4.5 }}
          value={false}
        />
        <FormControlLabel
          control={<Radio />}
          label="N/A"
          sx={{ ml: 4.5 }}
          value={"0"}
        />
      </FormRadioGroup>
    </FormControl>
  );
};

export default TreatmentOtherInfoRadioInput;
