import {
  Box,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  Stack,
  Typography,
  IconButton,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, Fragment, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { MONTHS, timeLimits } from "../../../../../utils/constants/timeRelated";
import FormInput from "../../../form/FormInput";
import FormRadioGroup from "../../../form/FormRadioGroup";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import NoneDisplayInput from "../../../input/NonDisplayForwardRefInput";
import { useMutation } from "@tanstack/react-query";
import {
  checkPatientConsent,
  sendAuthSMS,
} from "../../../../../services/patient";
import { toast } from "react-toastify";
import RefreshIcon from "@mui/icons-material/Refresh";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  PatientDetails,
  PatientFormState,
} from "../../../../../utils/types/services/patients";
import SimpleToolTipMobile from "../../../tooltip/SimpleToolTipMobile";
import BaseButton from "../../../button/BaseButton";
import { PatientModalMode } from "../../../../../utils/types/ui/patientModalMode";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
  FormType,
} from "../../../../../utils/constants/ga";
import { mobileNumberPrefix } from "../../../../../utils/constants/general";
import SmsIcon from "../../../../../assets/images/Innovision/sms_icon.png";

interface PatientPersonalDetailsFormProps {
  patient?: PatientDetails;
  mode: PatientModalMode;
  requestForPatientAccess: () => void;
  handleMobileNumberCheck: (data: string) => void;
  isPatientExists: {
    status: boolean;
    msg: string;
  };
  isAvailable: boolean;
  setIsPatientExists: (data: { status: boolean; msg: string }) => void;
}

const PatientPersonalDetailsForm = (props: PatientPersonalDetailsFormProps) => {
  const {
    patient,
    mode,
    requestForPatientAccess,
    handleMobileNumberCheck,
    isPatientExists,
    setIsPatientExists,
    isAvailable,
  } = props;

  const { t } = useTranslation();
  const NUM_1000 = 1000;

  const { mutateAsync, isLoading, data } = useMutation(sendAuthSMS);
  const { mutateAsync: mutateAsync2, isLoading: isLoading2 } =
    useMutation(checkPatientConsent);
  const [hasPatientConsent, setHasPatientConsent] = useState<boolean>(false);
  const [resendSeconds, setResendSeconds] = useState<number>(0);

  useEffect(() => {
    if (data?.success === true) {
      setResendSeconds(timeLimits.twoFactorAuthResendSeconds);
      const countDownInterval = setInterval(() => {
        setResendSeconds((val) => {
          --val;
          return Math.max(val, 0);
        });
      }, NUM_1000);

      return () => clearInterval(countDownInterval);
    }
  }, [data?.success]);

  const { watch, getValues, control, trigger, formState, setValue } =
    useFormContext<PatientFormState>();

  const daysInMonthArray = useMemo(() => {
    const [year, month] = getValues(["year", "month"]);

    const birthMonth = dayjs(`1-${month}-${year}`, "D-M-YYYY");
    if (!birthMonth.isValid()) return [];
    const daysInMonth = birthMonth.daysInMonth();

    return Array.from(Array(daysInMonth).keys()).map((x) => x + 1);
  }, [...watch(["year", "month"])]);

  useEffect(() => {
    const day = getValues("day");

    const isDayValueValid = daysInMonthArray.includes(+day);
    if (!isDayValueValid) setValue("day", 0);
  }, [...watch(["year", "month"])]);

  const patientAge = useMemo(() => {
    const [year, month, day] = getValues(["year", "month", "day"]);
    const birthday = `${year}-${month}-${day}`;
    if (!dayjs(birthday, "YYYY-M-D").isValid()) return null;
    return dayjs().diff(dayjs(birthday, "YYYY-M-D"), "year");
  }, [...watch(["year", "month", "day"])]);

  const onClickSendSMS2Patient = useCallback(async () => {
    const triggerResult = await trigger([
      "firstName",
      "lastName",
      "mobileLocal",
    ]);

    const [firstName, lastName, mobileLocal, mobilePrefix] = getValues([
      "firstName",
      "lastName",
      "mobileLocal",
      "mobilePrefix",
    ]);

    if (!triggerResult || firstName === "" || lastName === "") {
      const toastMsg = t("Enter First Name, Last Name and Mobile");
      toast.error(toastMsg);
      ReactGA.event({
        category: `${CategoryType.FormValidationError}_Other_${FormType.PatientSendSMSForm}`,
        action: FormType.PatientSendSMSForm,
        label:
          JSON.stringify(formState.dirtyFields) +
          JSON.stringify(formState.errors),
        value: 400,
      });
      return;
    }

    await mutateAsync({
      firstName: firstName!,
      lastName: lastName!,
      mobileNumber: mobilePrefix + mobileLocal,
      patientId: patient?.id,
    });
  }, [mutateAsync, getValues, t, trigger, patient, formState]);

  const onClickVerifyConsent = useCallback(async () => {
    const triggerResult = await trigger(["mobileLocal"]);
    if (!triggerResult) {
      const toastMsg = t("Enter Mobile");
      toast.error(toastMsg);
      return;
    }
    const mobileNumber = getValues("mobilePrefix") + getValues("mobileLocal");
    const checkResult = await mutateAsync2({
      mobileNumber,
      patientId: patient?.id,
    });
    if (checkResult.data?.consent) setHasPatientConsent(true);

    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Send_SMS,
    });
  }, [getValues, trigger, t, patient]);

  useEffect(() => {
    setHasPatientConsent(false);
  }, [...watch(["mobileLocal", "mobilePrefix"])]);

  const onMobileInputChange = () => {
    setIsPatientExists({ status: false, msg: "" });
    if (
      mode === "add" &&
      formState.dirtyFields["mobileLocal"] === true &&
      formState.errors["mobileLocal"] === undefined
    ) {
      handleMobileNumberCheck(
        getValues("mobilePrefix") + getValues("mobileLocal")
      );
    }
  };

  return (
    <Box>
      {patient?.id && (
        <Stack>
          <Typography variant="caption" color="text.secondary">
            {t("singlePharse.patientId")}
          </Typography>
          <Typography variant="subtitle1" fontWeight="bold">
            {patient.id}
          </Typography>
        </Stack>
      )}

      <Grid
        container
        direction={"row"}
        columns={{ md: 13 }}
        alignItems={"stretch"}
        justifyContent={"center"}
      >
        <Grid item xs={12} sm={12} md={6}>
          <FormLabel
            error={control._formState.errors.hasOwnProperty("firstName")}
            className="labelStyles"
          >
            {t("singlePharse.firstName", { context: "input" })}
          </FormLabel>
          <FormInput
            control={control}
            name="firstName"
            size="small"
            variant="outlined"
            type="text"
            placeholder="Type here..."
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "98%",
              },
            }}
          />
          <FormLabel
            error={control._formState.errors.hasOwnProperty("lastName")}
            className="labelStyles"
          >
            {t("singlePharse.lastName", { context: "input" })}
          </FormLabel>
          <FormInput
            control={control}
            name="lastName"
            size="small"
            variant="outlined"
            placeholder="Type here..."
            type="text"
            sx={{
              width: {
                xs: "100%",
                sm: "100%",
                md: "98%",
              },
            }}
          />
        </Grid>

        <Grid
          item
          md={1}
          display={{
            xs: "none",
            sm: "none",
            md: "flex",
          }}
        >
          <Grid container justifyContent={"center"}>
            <Divider orientation="vertical">
              <Typography variant="body2">{t("singlePharse.or")}</Typography>
            </Divider>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12} md={6} sx={{ width: "100%" }}>
          <Grid container>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FormLabel
                error={control._formState.errors.hasOwnProperty(
                  "recognitionId"
                )}
                className="labelStyles"
              >
                {t("singlePharse.recognitionId", { context: "input" })}
              </FormLabel>
              <SimpleToolTipMobile
                text={"patientModal.addPatient.patientTempIDToolTip"}
              />
            </Box>
            <FormInput
              control={control}
              name="recognitionId"
              size="small"
              variant="outlined"
              placeholder="Type here..."
              type="text"
              sx={{ flexGrow: 1 }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <FormLabel
            error={control._formState.errors.hasOwnProperty("mobileLocal")}
            className="labelStyles"
          >
            {t("singlePharse.mobile")}
          </FormLabel>
          {!patient?.hasConsent && (
            <SimpleToolTipMobile
              text={"patientModal.addPatient.consentToolTip"}
            />
          )}
        </Box>
        <Grid item xs={12} container direction="row">
          <Grid item xs={3}>
            <FormInput
              select
              control={control}
              size="small"
              name={"mobilePrefix"}
              sx={{ width: 1 }}
            >
              {[
                { id: "HK", displayText: "+852" },
                { id: "MO", displayText: "+853" },
              ].map(({ id, displayText }) => (
                <MenuItem key={id} value={displayText}>
                  {displayText}
                </MenuItem>
              ))}
            </FormInput>
          </Grid>
          <Grid item xs={9}>
            <FormInput
              isCustomError={isPatientExists.status}
              customErrorMessage={isPatientExists.msg}
              control={control}
              name="mobileLocal"
              size="small"
              placeholder="Type here..."
              variant="outlined"
              sx={{ width: 1 }}
              type="tel-local"
              onKeyUp={() => onMobileInputChange()}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {isPatientExists.status && (
            <Fragment>
              <BaseButton
                onClick={() => requestForPatientAccess()}
                disabled={isAvailable}
                sx={{
                  marginLeft: 1,
                }}
              >
                Request Access
              </BaseButton>
            </Fragment>
          )}
          {!patient?.hasConsent &&
            (mode === "edit" ||
              formState.dirtyFields["mobileLocal"] === true) &&
            formState.errors["mobileLocal"] === undefined && (
              <Fragment>
                <BaseButton
                  startIcon={
                    <img
                      src={SmsIcon}
                      className="imgCrisp"
                      style={{ width: "24px", height: "24px" }}
                    />
                  }
                  onClick={onClickSendSMS2Patient}
                  disabled={
                    resendSeconds !== 0 ||
                    hasPatientConsent ||
                    isLoading ||
                    isLoading2
                  }
                  sx={{
                    marginLeft: 1,
                  }}
                >
                  {hasPatientConsent
                    ? t("singlePharse.verified")
                    : `${t("patientModal.addPatient.sendPatientAppLink")}${
                        resendSeconds === 0 ? "" : `(${resendSeconds}s)`
                      }`}
                </BaseButton>

                <IconButton
                  onClick={onClickVerifyConsent}
                  disabled={hasPatientConsent || isLoading || isLoading2}
                >
                  {hasPatientConsent && <VerifiedIcon color="success" />}
                </IconButton>
              </Fragment>
            )}
        </Grid>
      </Grid>

      <FormControl>
        <FormLabel>{`${t("singlePharse.gender")} *`}</FormLabel>
        <FormRadioGroup control={control} name="gender" row>
          <FormControlLabel
            value="male"
            control={<Radio />}
            label={t("singlePharse.male")}
          />
          <FormControlLabel
            value="female"
            control={<Radio />}
            label={t("singlePharse.female")}
            sx={{ ml: 4.5 }}
          />
        </FormRadioGroup>
      </FormControl>

      <Box>
        <FormLabel>{`${t("singlePharse.dateOfBirth")} *`}</FormLabel>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            gap: 1,
            marginTop: "5px",
          }}
        >
          <FormControl>
            <FormInput
              control={control}
              name="year"
              size="small"
              label={t("patientModal.dateOfBirth.year", {
                context: "input",
              })}
              inputProps={{
                min: dayjs().year() - timeLimits.maxHumanAge,
                max: dayjs().year(),
              }}
              variant="outlined"
              type="number"
              sx={{ width: 170 }}
            />
          </FormControl>

          <FormControl sx={{ flexGrow: 1 }}>
            <FormInput
              select
              control={control}
              size="small"
              name="month"
              label={t("patientModal.dateOfBirth.month")}
              fullWidth
            >
              <MenuItem value={0}>-</MenuItem>
              {MONTHS.map(({ index, displayText }) => (
                <MenuItem key={index} value={index}>
                  {displayText}
                </MenuItem>
              ))}
            </FormInput>
          </FormControl>

          <FormControl sx={{ flexGrow: 1 }}>
            <FormInput
              select
              control={control}
              size="small"
              name="day"
              label={t("patientModal.dateOfBirth.day")}
              fullWidth
            >
              <MenuItem value={0}>-</MenuItem>
              {daysInMonthArray.map((day) => (
                <MenuItem key={day} value={day}>
                  {day}
                </MenuItem>
              ))}
            </FormInput>
          </FormControl>
        </Box>
        {Number.isInteger(patientAge) && (
          <Typography color="primary">
            {patientAge} {t("singlePharse.yearsOld")}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default PatientPersonalDetailsForm;
