import { AppBar, Popover, Toolbar, Typography, Grid } from "@mui/material";
import { useQueries } from "@tanstack/react-query";
import { useCallback, useState } from "react";
import Avatar from "../../components/shareComponents/avatar/UserAvatar";
import { userGetData } from "../../services/user";
import PopoverState from "../../utils/types/ui/PopoverState";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { DRAWER_WIDTH } from "../constants";
import dayjs from "dayjs";
import InnovisionNameWithIcon from "../../assets/images/Innovision/NameWithIcon.png";
import MobileMenu from "./MobileMenu";
import { Theme } from "@mui/material/styles";

const app_bar_styles = (theme: Theme) =>
  ({
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    ml: `${DRAWER_WIDTH}px`,
    backgroundColor: "background.paper",
    color: "text.primary",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      ml: 0,
    },
  } as const);

const Appbar = () => {
  const [popoverState, setPopoverState] = useState<PopoverState>({
    elementRef: null,
    content: null,
    maxWidth: 0,
  });

  const [{ data: user }] = useQueries({
    queries: [{ queryKey: [REACT_QUERY_KEYS.USER_DATA], queryFn: userGetData }],
  });

  const handlePopoverClose = useCallback(() => {
    setPopoverState((state) => ({ ...state, elementRef: null, content: null }));
  }, []);

  return (
    <AppBar position="fixed" sx={app_bar_styles}>
      <Popover
        anchorEl={popoverState.elementRef}
        open={!!popoverState.elementRef}
        anchorOrigin={popoverState?.anchorOrigin}
        onClose={handlePopoverClose}
        PaperProps={{
          sx: { width: "100%", maxWidth: popoverState.maxWidth },
        }}
      >
        {popoverState.content}
      </Popover>
      <Toolbar>
        <Grid
          container
          item
          xs={12}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Grid item display={{ md: "none", sm: "flex", xs: "flex" }}>
            <MobileMenu />
          </Grid>

          <Grid item>
            <Grid
              container
              alignItems={"center"}
              display={{ xs: "none", sm: "flex" }}
            >
              <img
                src={InnovisionNameWithIcon}
                style={{
                  maxHeight: "100%",
                  maxWidth: "10vmax",
                  objectFit: "contain",
                }}
                alt="app-icon"
                loading="lazy"
              />
            </Grid>
          </Grid>
          <Grid item>
            <Grid container direction={"row"} alignItems={"center"}>
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", marginRight: 3 }}
              >
                {dayjs().format("ddd, MMM DD")}
              </Typography>

              <Avatar
                profileImgUrl={user?.data?.image ?? undefined}
                profileImgAltName={
                  (user?.data?.nickName?.charAt(0) ?? "").toUpperCase() +
                  (user?.data?.lastName?.charAt(0) ?? "").toUpperCase()
                }
                setPopoverState={setPopoverState}
                handlePopoverClose={handlePopoverClose}
              />
            </Grid>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Appbar;
