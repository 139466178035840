import { Control, FieldPath, FieldValues } from "react-hook-form";
import StyledDropzone from "../drop-zone/StyledDropzone";
import StyledDropzoneMobile from "../drop-zone/StyledDropzoneMobile";
import { Grid } from "@mui/material";

import ControlledFormElement from "./ControlledFormElement";

interface FormDropzoneProps<T extends FieldValues = FieldValues> {
  name: FieldPath<T>;
  control: Control<T>;
  onDrop: (files: File[]) => void;
  multiple?: boolean;
}

const FormDropzone = <T extends FieldValues>(props: FormDropzoneProps<T>) => {
  const { control, name, onDrop, multiple } = props;

  return (
    <ControlledFormElement control={control} name={name}>
      {({ field: { onChange } }) => (
        <Grid>
          <Grid
            item
            xs
            sx={{ display: { xs: "block", sm: "none", md: "none" } }}
          >
            <StyledDropzoneMobile
              onDrop={onDrop}
              onChange={(e) => {
                onChange(e);
              }}
              multiple={multiple}
            />
          </Grid>
          <Grid
            item
            md
            sm
            sx={{ display: { xs: "none", sm: "block", md: "block" } }}
          >
            <StyledDropzone
              onDrop={onDrop}
              onChange={(e) => {
                onChange(e);
              }}
              multiple={multiple}
            />
          </Grid>
        </Grid>
      )}
    </ControlledFormElement>
  );
};

export default FormDropzone;
