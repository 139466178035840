import { Typography, Grid, MenuItem } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  EyeDetailsProps,
  eyeStatus,
} from "../../../utils/types/services/patients";
import BaseInput from "../../shareComponents/input/BaseInput";
import _ from "lodash";
import { useCallback, useState, ChangeEvent, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { toggleEyeTreatmentStatus } from "../../../services/patient";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";

interface TreatmentInfoHeaderProps {
  header: string;
  eyeDetails: EyeDetailsProps;
  handleGraphModalOpen: () => void;
  isOwner: boolean;
}

const TreatmentInfoHeader = (props: TreatmentInfoHeaderProps) => {
  const { header, eyeDetails, handleGraphModalOpen, isOwner } = props;
  const { t } = useTranslation();

  const { mutateAsync } = useMutation(toggleEyeTreatmentStatus);

  const [treatmentStatus, setTreatmentStatus] = useState<
    keyof typeof eyeStatus | null
  >(null);
  const onChangeStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTreatmentStatus(event.target.value as keyof typeof eyeStatus);
      mutateAsync({ eyeId: eyeDetails.id, status: event.target.value });
    },
    [eyeDetails.id, mutateAsync]
  );

  const selectionStatus = useMemo(() => {
    return _.uniq([
      eyeDetails.calStatus!,
      eyeDetails.status!,
      eyeStatus.completed,
    ]);
  }, [eyeDetails.calStatus, eyeDetails.status]);

  useEffect(() => {
    if (treatmentStatus === null || !selectionStatus.includes(treatmentStatus))
      setTreatmentStatus(eyeDetails.status);
  }, [eyeDetails.status]);

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="center"
      sx={{ minHeight: "5vh", marginBottom: 1 }}
    >
      <Grid
        container
        item
        md={4.5}
        sm={6}
        xs={6}
        direction="row"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container item sm alignItems="center" justifyContent="center">
          <Typography variant="h5" fontWeight="bold" align="center">
            {eyeDetails.diseaseType}
          </Typography>
        </Grid>
        <Grid container item sm alignItems="center" justifyContent="center">
          {eyeDetails.status && eyeDetails.calStatus && treatmentStatus && (
            <BaseInput
              label={t("singlePharse.status")}
              select
              value={treatmentStatus}
              onChange={onChangeStatus}
              size="small"
              sx={{ minWidth: "5vw" }}
              disabled={!isOwner}
            >
              {selectionStatus.map((e) => (
                <MenuItem key={e} value={e}>
                  {e.toUpperCase()}
                </MenuItem>
              ))}
            </BaseInput>
          )}
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={3}
        sm={12}
        xs={12}
        alignItems="center"
        justifyContent="center"
        display={{ sm: "none", xs: "none", md: "block" }}
      >
        <Typography variant="h5" fontWeight="bold" align="center">
          {header}
        </Typography>
      </Grid>

      <Grid
        container
        item
        md={4.5}
        sm={6}
        xs={6}
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          onClick={handleGraphModalOpen}
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          sx={{
            cursor: "pointer",
            width: "fit-content",
          }}
        >
          <Typography
            variant="h5"
            color="#006544"
            textAlign={"center"}
            fontWeight="bold"
          >
            {t("patientDetailPage.cstvaGraphName")}
          </Typography>
          <TrendingUpOutlinedIcon
            sx={{
              color: "#006544",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TreatmentInfoHeader;
