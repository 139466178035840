import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PatientDetails } from "../../../../utils/types/services/patients";
import { PatientModalMode } from "../../../../utils/types/ui/patientModalMode";
import SideDrawer from "../../side-drawer";
import AddPatientForm from "./AddPatientForm";
import AddPatientFormMobile from "./AddPatientFormMobile";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { ModalViewType } from "../../../../utils/constants/ga";
import { isMobile } from "react-device-detect";

interface PatientModalProps {
  isOpen: boolean;
  handleClose: () => void;
  patient?: PatientDetails;
}

const PatientModal = (props: PatientModalProps) => {
  const { isOpen, handleClose, patient } = props;

  const { t } = useTranslation();

  const mode: PatientModalMode = patient ? "edit" : "add";
  const [showRequestAccess, setShowRequestAccess] = useState<boolean>(false);
  const [moveBack, setMoveBack] = useState<boolean>(false);

  const headerText =
    mode === "add"
      ? showRequestAccess
        ? "Request for Access"
        : t("patientListingPage.addPatient")
      : t("patientListingPage.editPatient");

  const requestForPatientAccess = () => {
    setShowRequestAccess(true);
  };

  const handleCloseDrawer = () => {
    setShowRequestAccess(false);
    if (moveBack && isMobile) {
      window.history.back();
    }
    handleClose();
  };

  useEffect(() => {
    if (isOpen)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.PatientModal}/${headerText}`,
      });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && isMobile) {
      window.history.pushState({}, "", "");
      setMoveBack(true);
      const temp = () => {
        setMoveBack(false);
        handleCloseDrawer();
        window.removeEventListener("popstate", temp);
      };
      window.addEventListener("popstate", temp);
    }
  }, [isOpen]);

  return (
    <SideDrawer
      open={isOpen}
      onClose={handleCloseDrawer}
      headerText={headerText}
      handleCloseDrawerClick={handleCloseDrawer}
    >
      <Grid
        item
        md
        sm
        sx={{ display: { xs: "none", sm: "block", md: "block" } }}
      >
        <AddPatientForm
          handleClose={handleCloseDrawer}
          patient={patient}
          mode={mode}
          requestForPatientAccess={requestForPatientAccess}
          resetHeaderName={() => setShowRequestAccess(false)}
        />
      </Grid>
      <Grid item xs sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
        <AddPatientFormMobile
          handleClose={handleCloseDrawer}
          patient={patient}
          mode={mode}
          requestForPatientAccess={requestForPatientAccess}
          resetHeaderName={() => setShowRequestAccess(false)}
        />
      </Grid>
    </SideDrawer>
  );
};

export default PatientModal;
