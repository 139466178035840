import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
  Link,
} from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../../services/dbConstants";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";
import AddIcon from "@mui/icons-material/Add";

const defaultSelectItems = [
  {
    id: -1,
    displayText: "All Diseases",
  },
];

interface PatientListOptionBarMobileProps {
  selectedDiseaseType: number;
  setSelectedDiseaseType: Dispatch<SetStateAction<number>>;
  handleAddPatientClick: () => void;
}

const PatientListOptionBarMobile = (props: PatientListOptionBarMobileProps) => {
  const { selectedDiseaseType, setSelectedDiseaseType, handleAddPatientClick } =
    props;

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const diseaseTypeSelectItems = defaultSelectItems.concat(
    dbConstants?.data?.diseaseTypes ?? []
  );

  const handleDiseaseTypeSelect = useCallback((event: SelectChangeEvent) => {
    setSelectedDiseaseType(+event.target.value);
    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Table_Filter,
      value: +event.target.value,
    });
  }, []);

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: 2.8, py: 0.5, mb: 3, mt: 1 }}
    >
      {!showFilter && (
        <Box>
          <Link
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShowFilter(true);
            }}
          >
            Filter Patients
          </Link>
        </Box>
      )}
      {showFilter && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="subtitle2" sx={{ mr: 2 }}>
              Filter
            </Typography>

            <FormControl sx={{ ml: 0.5, mt: 0, mb: 0 }} size="small">
              <Select
                value={selectedDiseaseType.toString()}
                sx={{ width: 150 }}
                onChange={handleDiseaseTypeSelect}
              >
                {diseaseTypeSelectItems.map(({ id, displayText }) => (
                  <MenuItem key={id} value={id}>
                    {displayText}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <Link
              href="#"
              underline="none"
              onClick={(e) => {
                e.preventDefault();
                setShowFilter(false);
              }}
              sx={{ fontStyle: "italic" }}
            >
              Hide
            </Link>
          </Box>
        </Box>
      )}
      <Box sx={{ position: "fixed", zIndex: 99, right: "8vw", bottom: "3vh" }}>
        <BaseButton
          startIcon={<AddIcon />}
          onClick={handleAddPatientClick}
          sx={{ fontSize: "16px" }}
        >
          Patient
        </BaseButton>
      </Box>
    </Grid>
  );
};

export default PatientListOptionBarMobile;
