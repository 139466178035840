import { Modal, Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { ModalViewType } from "../../../utils/constants/ga";
import { AEDataProps } from "../../../services/treatmentRecord";
import Page from "./Page";
import dayjs from "dayjs";
import ModalBoldTitle from "../../shareComponents/modal/BoldTitle";
import { Theme } from "@mui/material/styles";

const style = (theme: Theme) =>
  ({
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  } as const);

interface AEModalProps {
  modalData: AEDataProps | null;
  open: boolean;
  handleClose: () => void;
}

const AEModal = (props: AEModalProps) => {
  const { modalData, open, handleClose } = props;
  const { t } = useTranslation();

  useEffect(() => {
    if (open)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.AEModal}`,
      });
  }, [open]);

  return (
    <Modal
      open={open}
      // onClose={handleClose}
      aria-labelledby="simple-modal"
      aria-describedby="simple-modal"
    >
      <Grid container sx={style}>
        <ModalBoldTitle
          title={
            t("aeReportModal.title") +
            (modalData?.reported_AE
              ? `${t("aeReportModal.alreadyReported")} ${dayjs(
                  modalData?.date
                ).format("DD MMM YYYY")}`
              : "")
          }
        />

        <Page modalData={modalData} handleClose={handleClose} />
      </Grid>
    </Modal>
  );
};

export default AEModal;
