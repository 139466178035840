import { Grid, Typography } from "@mui/material";
import TreatmentOtherInfoSymbol from "./TreatmentOtherInfoSymbol";

const LABELS = {
  heme: "HEME",
  ped: "PED",
  irf: "IRF/IRC",
  srf: "SRF",
} as const;

interface LastTreatmentOtherInfoItemProps {
  label: string;
  checked: boolean | null;
}

const LastTreatmentOtherInfoItem = (props: LastTreatmentOtherInfoItemProps) => {
  const { label, checked } = props;

  return (
    <Grid container item xs={5} flexDirection="column" sx={{ mb: 2 }}>
      <Typography variant="subtitle2" color="text.secondary">
        {label}
      </Typography>
      <TreatmentOtherInfoSymbol checked={checked} />
    </Grid>
  );
};

interface LastTreatmentOtherInfoProps {
  heme: boolean | null;
  ped: boolean | null;
  irf: boolean | null;
  srf: boolean | null;
}

const LastTreatmentOtherInfo = (props: LastTreatmentOtherInfoProps) => {
  const { heme, ped, irf, srf } = props;

  return (
    <Grid container>
      <LastTreatmentOtherInfoItem label={LABELS.heme} checked={heme} />
      <LastTreatmentOtherInfoItem label={LABELS.ped} checked={ped} />
      <LastTreatmentOtherInfoItem label={LABELS.irf} checked={irf} />
      <LastTreatmentOtherInfoItem label={LABELS.srf} checked={srf} />
    </Grid>
  );
};

export default LastTreatmentOtherInfo;
