import { Modal, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../../button/BaseButton";
import { deleteHcp } from "../../../../services/hcp";
import { useMutation } from "@tanstack/react-query";
import ModalBoldTitle from "../../modal/BoldTitle";
import SecondaryButton from "../../button/SecondaryButton";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  maxWidth: "90%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
} as const;

interface RevokeHCPAccessModalProps {
  isOpen: boolean;
  toggleModal: () => void;
  handleDeleteHcpConfirm: () => void;
}

const RevokeHCPAccessModal = (props: RevokeHCPAccessModalProps) => {
  const { isOpen, toggleModal, handleDeleteHcpConfirm } = props;
  const { t } = useTranslation();

  const title = "Revoke Access?";

  return (
    <Modal
      open={isOpen}
      onClose={toggleModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Grid container sx={style}>
        <ModalBoldTitle title={title} />

        <Grid
          item
          xs={12}
          sx={{
            p: 1,
            maxHeight: "50vh",
            overflow: "auto",
          }}
        >
          <Grid item xs={12}>
            <Typography id="modal-modal-warning">
              Are You sure you want to revoke access to this HCP?
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="center"
          direction={"row"}
          justifyContent="flex-end"
          sx={{ mt: 1 }}
        >
          <SecondaryButton sx={{ mr: 1 }} onClick={toggleModal}>
            {t("singlePharse.cancel")}
          </SecondaryButton>
          <BaseButton
            onClick={() => {
              handleDeleteHcpConfirm();
            }}
          >
            {t("singlePharse.confirm")}
          </BaseButton>
        </Grid>
      </Grid>
    </Modal>
  );
};

export default RevokeHCPAccessModal;
