import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { Typography } from "@mui/material";

interface TreatmentOtherInfoSymbolProps {
  checked: boolean | null;
}

const TreatmentOtherInfoSymbol = (props: TreatmentOtherInfoSymbolProps) => {
  const { checked } = props;

  return checked === null ? (
    <Typography>N/A</Typography>
  ) : checked ? (
    <CheckCircleOutlineIcon style={{ color: "#005D50" }} fontSize="small" />
  ) : (
    <CancelOutlinedIcon style={{ color: "#CC0033" }} fontSize="small" />
  );
};

export default TreatmentOtherInfoSymbol;
