import React, { useState, useEffect } from "react";
import { Scanner } from "@yudiel/react-qr-scanner";
import { Box } from "@mui/material";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import BaseButton from "../button/BaseButton";
import { useTranslation } from "react-i18next";

const ScannerComponent = ({
  onUpdate,
  onError,
  handleSampleClick,
  width = "100%",
  height = "100%",
  facingMode = "environment",
  torch,
  videoConstraints,
}: {
  onUpdate: (error: unknown, result?: any) => void;
  onError?: (error: string | DOMException) => void;
  handleSampleClick: () => void;
  width?: number | string;
  height?: number | string;
  facingMode?: "environment";
  torch?: boolean;
  videoConstraints?: MediaTrackConstraints;
}): React.ReactElement => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [deviceId, setDeviceId] = useState<string>("pending");
   const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    try{
        navigator.mediaDevices.enumerateDevices().then((devices) => {
        const videoDevice = devices.filter((e) => e.kind === "videoinput");
        setDevices(videoDevice);
        setDeviceId(videoDevice.slice(-1)[0]?.deviceId ?? "");      
        });
     } 
    catch (error) {
          console.error("Error getting media devices:", error);
          setError("Error getting media devices.");
     }
  }, []);

  if (deviceId === "pending") return <></>;

  const handleError = (error: unknown) => {
    if (onError) {
      if (error instanceof DOMException || typeof error === 'string') {
        onError(error);
      } else {
        console.error("Unexpected error type:", error);
        onError("Unexpected error occurred.");
      }
    }
  };

  const constraints: MediaTrackConstraints = {
    ...videoConstraints,
    facingMode: facingMode,
    deviceId: deviceId ? { exact: deviceId } : undefined
  };
  
  return (
    <Box sx={{ position: "relative"}}>
      {error && <Box sx={{ color: "red" }}>{error}</Box>}
      {devices.length > 0 && (
        <select
          value={deviceId}
          onChange={(event) => {
            setDeviceId(event.target.value);
          }}
        >
          {devices.map((device) => (
            <option key={device.deviceId} value={device.deviceId}>
              {device.label}
            </option>
          ))}
        </select>
      )}
      <Scanner
        key={deviceId}
        onScan={(result) => {
          onUpdate(null, result);
        }}
        onError={(error) => {
          handleError(error);
        }}
        formats={
          ["data_matrix"]}
        constraints={{deviceId}}
        components={{
          tracker: (detectedCodes) => {
              console.log(detectedCodes);
          },
          audio: true,
          onOff: true,
          torch,
          zoom: true,
          finder: true,
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: "-80px",
          left: "33%",
        }}
      >
        <BaseButton
          size="medium"
          sx={{ p: 2 }}
          endIcon={<QrCodeScannerIcon />}
          onClick={handleSampleClick}
        >
         {t("productScan.viewSample")}
        </BaseButton>
      </Box>
    </Box>
  );
};

export default ScannerComponent;