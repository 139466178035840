import { Grid } from "@mui/material";
import { useEffect } from "react";
import UserAvatar from "./UserAvatar";
import UserProfile from "./UserProfile";

const UserProfilePage = () => {
  useEffect(() => {
    document.title = "InnoVision - User Profile";
  }, []);

  return (
    <Grid container sx={{ paddingX: { md: "15%", sm: "7%", xs: "0%" } }}>
      <UserAvatar />
      <UserProfile />
    </Grid>
  );
};

export default UserProfilePage;
