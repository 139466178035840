import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useCallback, useMemo, useState } from "react";
import { ChartData, ChartOptions } from "chart.js";
import { Grid } from "@mui/material";
import FilterOptionsPopover, {
  FilterValueProps,
  DiseaseTypeProps,
} from "./FilterOptionsPopover";
import FilterPopover from "../FilterPopover";
import { ChartItemColor } from "../type";
import { getDrugComparisonByVA } from "../../../services/dashboard";
import { useQueries } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import _ from "lodash";
import { useTheme } from "@mui/system";
import ReactGA from "react-ga4";
import {
  CategoryType,
  OtherButtonActionType,
} from "../../../utils/constants/ga";

const defaultValue = {
  diseaseType: {
    nAMD: true,
    PCV: true,
    DME: true,
    RVO: true,
    mCNV: true,
    others: true,
  },
  treatmentPhase: {
    loading: true,
    maintenance: true,
  },
} as const;

const DrugComparisonChart = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const NUM_100 = 100;

  const [value, setValue] = useState<FilterValueProps>(defaultValue);
  const [closePopover, setClosePopover] = useState<number>(0);

  const [{ data: graphData }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.DRUGCOMPARISONCHART],
        queryFn: getDrugComparisonByVA,
        retry: true,
        refetchOnMount: "always",
      },
    ],
  });

  const filteredLabels = useMemo(() => {
    const graph_data = graphData?.data ?? [];
    const uniqueData = _.uniqBy(graph_data, "drugName");
    return uniqueData.map((e) => e.drugName);
  }, [graphData]);

  const filteredData = useMemo(() => {
    const graph_data = graphData?.data ?? [];
    const newData: { name: string; number: number[] }[] = [];
    const selectedList: string[] = [];
    const stableArray: number[] = [];
    const improvedArray: number[] = [];
    const worsenArray: number[] = [];

    const diseaseTypeKeys = Object.keys(value.diseaseType);
    diseaseTypeKeys.forEach((e) => {
      if (!value.diseaseType[e as keyof DiseaseTypeProps]) return;
      selectedList.push(e.toLowerCase());
    });

    for (const label of filteredLabels) {
      const stableData = graph_data.filter(
        (ee) =>
          ee.drugName === label &&
          ee.status === "Stable" &&
          selectedList.includes(ee.diseaseTypesName.toLowerCase()) &&
          ((value.treatmentPhase.loading && value.treatmentPhase.maintenance) ||
            ee.treatmentPhasesAdjName ===
              (value.treatmentPhase.loading
                ? "Loading"
                : value.treatmentPhase.maintenance
                ? "Maintenance"
                : null))
      );
      const improvedData = graph_data.filter(
        (ee) =>
          ee.drugName === label &&
          ee.status === "Improved" &&
          selectedList.includes(ee.diseaseTypesName.toLowerCase()) &&
          ((value.treatmentPhase.loading && value.treatmentPhase.maintenance) ||
            ee.treatmentPhasesAdjName ===
              (value.treatmentPhase.loading
                ? "Loading"
                : value.treatmentPhase.maintenance
                ? "Maintenance"
                : null))
      );
      const worsenData = graph_data.filter(
        (ee) =>
          ee.drugName === label &&
          ee.status === "Worsen" &&
          selectedList.includes(ee.diseaseTypesName.toLowerCase()) &&
          ((value.treatmentPhase.loading && value.treatmentPhase.maintenance) ||
            ee.treatmentPhasesAdjName ===
              (value.treatmentPhase.loading
                ? "Loading"
                : value.treatmentPhase.maintenance
                ? "Maintenance"
                : null))
      );
      let stableNumber = 0;
      let improvedNumber = 0;
      let worsenNumber = 0;
      for (const i of stableData) {
        stableNumber += i.count;
      }
      for (const j of improvedData) {
        improvedNumber += j.count;
      }
      for (const k of worsenData) {
        worsenNumber += k.count;
      }
      stableArray.push(stableNumber);
      improvedArray.push(improvedNumber);
      worsenArray.push(worsenNumber);
    }

    newData.push({ name: "Improved (>5 Letters)", number: improvedArray });
    newData.push({ name: "Stable (±5 Letters)", number: stableArray });
    newData.push({ name: "Worsen (<5 Letters)", number: worsenArray });

    const percentData = newData.map((e, i) => {
      const numberArray = e.number.map((ee, ii) => {
        const total = newData.reduce(
          (partialSum, a) => partialSum + a.number[ii],
          0
        );
        ee = (ee / total) * NUM_100;
        return ee;
      });
      return { ...e, number: numberArray };
    });
    return percentData;
  }, [value.diseaseType, graphData, value.treatmentPhase, filteredLabels]);

  const data = useMemo<ChartData<"bar", any[], unknown>>(
    () => ({
      labels: filteredLabels,
      datasets: filteredData.map((e, i) => ({
        label: e.name,
        data: e.number,
        backgroundColor: [
          ChartItemColor[
            Object.keys(ChartItemColor)[i] as keyof typeof ChartItemColor
          ],
        ],
        borderColor: [
          ChartItemColor[
            Object.keys(ChartItemColor)[i] as keyof typeof ChartItemColor
          ],
        ],
        borderWidth: 1,
      })),
    }),
    [filteredLabels, filteredData, t]
  );

  const options = useMemo<ChartOptions<"bar">>(
    () => ({
      maintainAspectRatio: false,
      layout: {
        padding: 2,
      },
      plugins: {
        legend: {
          onClick: () => null,
          labels: {
            color: theme.palette.text.primary,
          },
        },
        datalabels: {
          display: "auto",
          color: theme.palette.common.white,
          font: {
            size: 17,
          },
          formatter: (val: number, context) => {
            if (val === 0) return "";
            return `${val.toFixed(1)}%`;
          },
          textAlign: "center",
        },
        tooltip: {
          callbacks: {
            label: ({ parsed: { y } }) => {
              return `${y.toFixed(1)}%`;
            },
          },
        },
        title: {
          font: {
            size: 20,
          },
          color: theme.palette.text.primary,
          display: true,
          text: t("dashboardPage.drugComparisonChart.title"),
        },
      },
      scales: {
        x: {
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            color: theme.palette.text.primary,
          },
        },
        y: {
          max: 100,
          stacked: true,
          grid: {
            display: false,
          },
          ticks: {
            color: theme.palette.text.primary,
          },
          title: {
            font: {
              size: 15,
            },
            color: theme.palette.text.primary,
            display: true,
            text: t("dashboardPage.drugComparisonChart.performance%"),
          },
        },
      },
    }),
    [t]
  );

  const applyFilter = useCallback(
    (apply: boolean, filter_data: FilterValueProps | null) => {
      if (apply) setValue({ ...filter_data! });
      setClosePopover((val) => {
        ++val;
        return val;
      });
      ReactGA.event({
        category: CategoryType.OtherButtonAction,
        action: OtherButtonActionType.Dashboard_Apply_Filter,
        label: t("dashboardPage.drugComparisonChart.title"),
      });
    },
    [t]
  );

  const isFilterApplied = useMemo(() => {
    return !_.isEqual(defaultValue, value);
  }, [value]);

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: "background.paper",
        p: 2,
      }}
      direction="row"
    >
      <Grid
        item
        xs={11}
        sx={{ height: { md: "37vh", sm: "45vh", xs: "45vh" }, minHeight: 300 }}
      >
        <Bar data={data} options={options} />
      </Grid>
      <Grid item xs={1}>
        <FilterPopover
          filtered={isFilterApplied}
          closePopover={closePopover}
          content={
            <FilterOptionsPopover value={value} applyFilter={applyFilter} />
          }
        />
      </Grid>
    </Grid>
  );
};

export default DrugComparisonChart;
