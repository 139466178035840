import { Divider, Grid, Box } from "@mui/material";
import { useCallback, useMemo, useState } from "react";
import {
  TreatmentRecord,
  EyeDetailsProps,
} from "../../../utils/types/services/patients";
import LastTreatmentInfo from "./LastTreatmentInfo";
import NextTreatmentRecommendation from "./NextTreatmentRecommendation";
import TreatmentInfoHeader from "./TreatmentInfoHeader";
import TreatmentRecords from "./TreatmentRecords";
import ExpandCollapseIcon from "../../shareComponents/icon/ExpandCollapseIcon";
import { AEDataProps } from "../../../services/treatmentRecord";

const box_styles = () =>
  ({
    display: "flex",
    flexDirection: "column",
  } as const);

interface TreatmentInfoProps {
  header: string;
  treatmentRecords: TreatmentRecord[];
  eyeDetails: EyeDetailsProps;
  handleGraphModalOpen: () => void;
  handleAddTreatmentRecordClick: () => void;
  onEditTreatmentInfoClick: (id: number) => void;
  showEyeInfo: "OD" | "OS" | "BOTH";
  treatmentMethod: number;
  handleChangeTreatmentMethods: (value: number) => void;
  handleOpenAEModal: (data: AEDataProps) => void;
  isOwner: boolean;
}

const TreatmentInfo = (props: TreatmentInfoProps) => {
  const {
    header,
    treatmentRecords,
    handleGraphModalOpen,
    handleAddTreatmentRecordClick,
    onEditTreatmentInfoClick,
    showEyeInfo,
    eyeDetails,
    treatmentMethod,
    handleChangeTreatmentMethods,
    handleOpenAEModal,
    isOwner,
  } = props;

  const NUM_2 = 2;

  const [showRecordInfo, setShowRecordInfo] = useState<"Table" | "BOTH">(
    "BOTH"
  );

  const onUpExpandClick = useCallback(() => {
    setShowRecordInfo((val) => {
      if (val === "BOTH") return "Table";
      return val;
    });
  }, []);

  const onDownExpandClick = useCallback(() => {
    setShowRecordInfo((val) => {
      if (val === "Table") return "BOTH";
      return val;
    });
  }, []);

  const latestTreatment = useMemo(
    () => treatmentRecords.find((e) => e.drugId !== null && e.recordStatus),
    [treatmentRecords]
  );

  return (
    <Grid container item xs={12} sx={{ flexGrow: 1 }}>
      <TreatmentInfoHeader
        header={header}
        eyeDetails={eyeDetails}
        handleGraphModalOpen={handleGraphModalOpen}
        isOwner={isOwner}
      />

      <Grid item xs={12}>
        <Divider
          sx={{
            backgroundColor: "primary.main",
            borderBottomWidth: 3,
          }}
        />
      </Grid>
      <Box sx={box_styles}>
        <Grid
          container
          item
          xs={12}
          flexDirection="row"
          justifyContent="flex-start"
          alignItems="stretch"
          sx={{
            display:
              showRecordInfo === "BOTH"
                ? "flex"
                : showRecordInfo === "Table"
                ? "none"
                : "flex",
          }}
        >
          <Grid item xs={12} sm={12} md={8}>
            <LastTreatmentInfo record={latestTreatment} />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <NextTreatmentRecommendation
              eyeDetails={eyeDetails}
              treatmentMethod={treatmentMethod}
              handleChangeTreatmentMethods={handleChangeTreatmentMethods}
              record={latestTreatment}
              isOwner={isOwner}
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid
            item
            xs
            sm
            sx={{
              display: { xs: "block", sm: "block", md: "none" },
              justifyContent: "space-around",
              textAlign: "center",
            }}
          ></Grid>
          <Divider>
            <ExpandCollapseIcon
              orientation="vertical"
              onUpLeftClick={onUpExpandClick}
              onDownRightClick={onDownExpandClick}
              showUpLeftIcon={showRecordInfo === "BOTH"}
              showDownRightIcon={showRecordInfo === "Table"}
            />
          </Divider>
        </Grid>

        <TreatmentRecords
          eyeDetails={eyeDetails}
          records={treatmentRecords}
          onRecordClick={onEditTreatmentInfoClick}
          handleAddTreatmentRecordClick={handleAddTreatmentRecordClick}
          tableHeightMuliplier={showRecordInfo === "Table" ? NUM_2 : 1}
          tableItemExpand={showEyeInfo !== "BOTH"}
          handleOpenAEModal={handleOpenAEModal}
        />
      </Box>
    </Grid>
  );
};

export default TreatmentInfo;
