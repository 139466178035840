import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { Paper, Stack, Tooltip, Typography, Link } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { CategoryType, OtherButtonActionType } from "../../utils/constants/ga";
import { PatientDetails } from "../../utils/types/services/patients";
import { useParams } from "react-router-dom";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";

interface ShareToPatientProps {
  patient: PatientDetails;
}

const ShareToPatient = (props: ShareToPatientProps) => {
  const { patient } = props;
  const { t } = useTranslation();
  const { id } = useParams();

  const onClickPreview = useCallback(() => {
    ReactGA.event({
      category: CategoryType.OtherButtonAction,
      action: OtherButtonActionType.Patient_Preview_Report,
      value: patient.id,
    });
  }, [patient.id]);

  return (
    <Paper elevation={0} sx={{ flexGrow: 1, p: 2.8 }}>
      <Stack>
        <Stack direction="row" alignItems="center">
          <Typography variant="h6" fontWeight="bold">
            {t("patientDetailPage.report")}
          </Typography>
          <Tooltip title={t("patientDetailPage.reportExplain")}>
            <HelpOutlineIcon fontSize="small" sx={{ ml: 2 }} />
          </Tooltip>
        </Stack>

        <Stack direction="row" alignItems="center">
          <Link
            href={ROUTE_PATHS.PATIENT_PREVIEW_DETAILS.replace(":id", id!)}
            target="_blank"
            sx={{ textDecoration: "none" }}
          >
            <Typography
              variant="subtitle2"
              color="primary.main"
              sx={{
                "&:hover": {
                  cursor: "pointer",
                },
              }}
              onClick={onClickPreview}
            >
              {t("patientDetailPage.previewReport")}
            </Typography>
          </Link>
        </Stack>
      </Stack>
    </Paper>
  );
};

export default ShareToPatient;
