import { Grid } from "@mui/material";
import { TreatmentRecord } from "../../../utils/types/services/patients";
import { TreatmentModalMode } from "../../../utils/types/ui/treatmentModalMode";
import SideDrawer from "../../shareComponents/side-drawer";
import TreatmentInfoFormMobile from "./TreatmentInfoFormMobile";
import TreatmentInfoForm from "./TreatmentInfoForm";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga4";
import { ModalViewType } from "../../../utils/constants/ga";
import { useEffect, useState } from "react";
import { Eye } from "../../../utils/constants/general";
import { AEDataProps } from "../../../services/treatmentRecord";
import { isMobile } from "react-device-detect";

interface TreatmentRecordModalProps {
  isOpen: boolean;
  treatmentDates: [any, any];
  handleClose: () => void;
  treatmentRecord?: TreatmentRecord | null;
  treatmentMethod: number;
  patientId: number;
  currentEye: Eye;
  handleOpenAEModal: (data: AEDataProps) => void;
}

const TreatmentRecordModal = (props: TreatmentRecordModalProps) => {
  const {
    isOpen,
    treatmentDates,
    handleClose,
    treatmentRecord,
    treatmentMethod,
    patientId,
    currentEye,
    handleOpenAEModal,
  } = props;
  const { t } = useTranslation();
  const [moveBack, setMoveBack] = useState<boolean>(false);

  const mode: TreatmentModalMode = treatmentRecord ? "edit" : "add";

  const headerText =
    mode === "add"
      ? t("treatmentRecordModal.addTreatmentRecord")
      : `${t("treatmentRecordModal.treatmentRecord")} ${
          treatmentRecord?.recordStatus === false
            ? "(" + t("singlePharse.inactive") + ")"
            : ""
        }`;

  const handleCloseDrawer = () => {
    if (moveBack && isMobile) {
      window.history.back();
    }
    handleClose();
  };

  useEffect(() => {
    if (isOpen)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.TreatmentRecordModal}/${headerText}`,
      });
  }, [isOpen]);

  useEffect(() => {
    if (isOpen && isMobile) {
      window.history.pushState({}, "", "");
      setMoveBack(true);
      const temp = () => {
        setMoveBack(false);
        handleCloseDrawer();
        window.removeEventListener("popstate", temp);
      };
      window.addEventListener("popstate", temp);
    }
  }, [isOpen]);

  return (
    <SideDrawer
      open={isOpen}
      onClose={handleCloseDrawer}
      headerText={headerText}
      handleCloseDrawerClick={handleCloseDrawer}
      anchor={currentEye === Eye.LEFT ? "left" : "right"}
    >
      <Grid item xs sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
        <TreatmentInfoFormMobile
          handleCancelClick={handleCloseDrawer}
          mode={mode}
          recordsDates={
            currentEye === Eye.RIGHT ? treatmentDates[0] : treatmentDates[1]
          }
          treatmentRecord={treatmentRecord}
          treatmentMethod={treatmentMethod}
          patientId={patientId}
          currentEye={currentEye}
          handleOpenAEModal={handleOpenAEModal}
        />
      </Grid>
      <Grid
        item
        sm
        md
        sx={{ display: { xs: "none", sm: "block", md: "block" } }}
      >
        <TreatmentInfoForm
          handleCancelClick={handleCloseDrawer}
          recordsDates={
            currentEye === Eye.RIGHT ? treatmentDates[0] : treatmentDates[1]
          }
          mode={mode}
          treatmentRecord={treatmentRecord}
          treatmentMethod={treatmentMethod}
          patientId={patientId}
          currentEye={currentEye}
          handleOpenAEModal={handleOpenAEModal}
        />
      </Grid>
    </SideDrawer>
  );
};

export default TreatmentRecordModal;
