import * as yup from "yup";
const REGEX = /^[A-Za-z\s\u4e00-\u9fff]*$/;
const NUM_50 = 50;

const nameYup = yup
  .string()
  .min(1, "required min input 1 characters")
  .max(NUM_50, "required max input 50 characters")
  .test("test-name", "required character/space", (val) => {
    if (val === undefined) return false;
    if (REGEX.test(val)) return true;
    return false;
  })
  .required("required");

export default nameYup;

export const nameOptionalYup = yup
  .string()
  .max(NUM_50, "required max input 50 characters")
  .test("test-name", "required character/space", (val) => {
    if (val === undefined) return false;
    if (REGEX.test(val)) return true;
    return false;
  });
