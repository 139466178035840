import { Grid, Typography } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";

const AccountInfo = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const changePassword = useCallback(() => {
    navigate(ROUTE_PATHS.CHANGE_PASSWORD);
  }, []);

  return (
    <Grid
      container
      justifyContent={"flex-start"}
      direction={"row"}
      alignItems={"center"}
    >
      <Grid item xs={12}>
        <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
          {t("singlePharse.security")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          onClick={changePassword}
          variant="subtitle1"
          align="left"
          sx={{
            color: "primary.main",
            alignSelf: "center",
            cursor: "pointer",
            paddingX: "1%",
          }}
        >
          {t("singlePharse.changePassword")}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default AccountInfo;
