import { Modal, Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useQuery, useQueries, useQueryClient } from "@tanstack/react-query";
import {
  useCallback,
  useEffect,
  useState,
  createRef,
  ChangeEvent,
} from "react";
import { userUpdatePSPStatus } from "../../../services/user";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import ConsentPage from "./ConsentPage";
import TermsAndConditions from "./TermsAndConditions";
import { Theme } from "@mui/material/styles";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import { isMobile } from "react-device-detect";
import CancelConfirmModal from "./CancelConfirmModal";
import AcceptModal from "./AcceptModal";
import useModal from "../../../hooks/useModal";

const style = (theme: Theme) =>
  ({
    position: "absolute",
    top: 0,
    left: "72px",
    height: "30vh",
    width: "100%",
    background: "#0066CC",
    [theme.breakpoints.down("md")]: {
      left: 0,
    },
  } as const);

const PSPConsentPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [acceptPSP, setAcceptPSP] = useState<boolean | null>(null);
  const {
    isOpen: isCancelConfirmModalOpen,
    closeModal: closeCancelConfirmModal,
    openModal: openCancelConfirmModal,
  } = useModal();

  const {
    isOpen: isAcceptOpen,
    closeModal: closeAcceptModal,
    openModal: openAcceptModal,
  } = useModal();

  const backToHome = () => {
    const routeToRedirect = location?.state?.history
      ? location.state.history
      : isMobile
      ? ROUTE_PATHS.PATIENTS
      : ROUTE_PATHS.HCPINIT;

    navigate(routeToRedirect);
  };

  const handleDecline = () => {
    openCancelConfirmModal();
  };

  const participate = () => {
    setShowTerms(true);
    window.scroll(0, 0);
  };

  const backFromTerms = () => {
    setShowTerms(false);
    window.scroll(0, 0);
  };

  const handleAccept = () => {
    openAcceptModal();
  };

  const { data, refetch, isLoading } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    () => {
      return userUpdatePSPStatus(acceptPSP);
    },
    {
      structuralSharing: (oldData, newData) => {
        if (oldData === undefined || newData.success || newData.status === 401)
          return newData;
        if (!newData.success) {
          return {
            success: newData.success,
            status: oldData.status,
            data: oldData.data,
          };
        }
        return newData;
      },
    }
  );

  const handleConfirmReject = async () => {
    await setAcceptPSP(false);
    await refetch();
    backToHome();
  };

  const handleConfirmAccept = async () => {
    await setAcceptPSP(true);
    await refetch();
    backToHome();
  };

  return (
    <Grid item>
      {data?.data?.joinedPsp !== true && (
        <Grid item container xs={12}>
          <CancelConfirmModal
            isOpen={isCancelConfirmModalOpen}
            toggleCancelConfirmModal={closeCancelConfirmModal}
            handleConfirmReject={handleConfirmReject}
          />
          <AcceptModal
            isOpen={isAcceptOpen}
            toggleAcceptModal={closeAcceptModal}
            handleConfirmAccept={handleConfirmAccept}
            isLoading={isLoading}
          />
          <Grid sx={style}></Grid>
          <Grid
            container
            justifyContent={"center"}
            direction={"row"}
            alignItems={"center"}
            sx={{
              zIndex: "9",
            }}
          >
            <Grid
              container
              item
              xs={12}
              sm={12}
              md={10}
              xl={8}
              justifyContent={"center"}
              direction={"row"}
              alignItems={"center"}
            >
              {!showTerms && (
                <ConsentPage
                  handleDecline={handleDecline}
                  participate={participate}
                  isAlreadyJoined={data?.data?.joinedPsp}
                ></ConsentPage>
              )}
              {showTerms && (
                <TermsAndConditions
                  backFromTerms={backFromTerms}
                  handleAccept={handleAccept}
                ></TermsAndConditions>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default PSPConsentPage;
