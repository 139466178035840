import DoneIcon from "@mui/icons-material/Done";
import { Chip } from "@mui/material";

interface ToggleChipProps {
  label: string;
  checked: boolean;
  handleClick: () => void;
}

const ToggleChip = (props: ToggleChipProps) => {
  const { label, checked, handleClick } = props;
  const PRIMARY_MAIN = "primary.main";
  const sharedProps = {
    label,
    onClick: handleClick,
  };

  if (checked)
    return (
      <Chip
        icon={
          <DoneIcon
            sx={{ "&&": { color: PRIMARY_MAIN }, color: PRIMARY_MAIN }}
          />
        }
        sx={{
          backgroundColor: "primary.light",
          color: PRIMARY_MAIN,
        }}
        {...sharedProps}
      />
    );

  return (
    <Chip
      variant="outlined"
      sx={{
        borderColor: PRIMARY_MAIN,
        color: PRIMARY_MAIN,
      }}
      {...sharedProps}
    />
  );
};

export default ToggleChip;
