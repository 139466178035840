import {
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  FormGroup,
  Divider,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  ChangeEvent,
  SyntheticEvent,
  useCallback,
  useMemo,
  useState,
} from "react";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import BaseInput from "../../shareComponents/input/BaseInput";
import dayjs, { Dayjs } from "dayjs";
import BaseButton from "../../shareComponents/button/BaseButton";
import SecondaryButton from "../../shareComponents/button/SecondaryButton";
type NUM_2 = 2;

export interface DiseaseTypeProps {
  nAMD: boolean;
  PCV: boolean;
  DME: boolean;
  RVO: boolean;
  mCNV: boolean;
  others: boolean;
}

export type FilterValueProps = {
  countBy: 1 | NUM_2;
  diseaseType: DiseaseTypeProps;
  timeReleated: {
    allTime: boolean;
    fromDate: Dayjs | null;
    toDate: Dayjs | null;
  };
};

interface FilterOptionsPopoverProps {
  applyFilter: (apply: boolean, data: FilterValueProps | null) => void;
  value: FilterValueProps;
}

const FilterOptionsPopover = (props: FilterOptionsPopoverProps) => {
  const { applyFilter, value: initValue } = props;
  const { t } = useTranslation();

  const [value, setValue] = useState<FilterValueProps>(initValue);

  const handleRadioGroupChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, sel_value: string) => {
      setValue((val) => ({ ...val, countBy: +sel_value as 1 | NUM_2 }));
    },
    []
  );

  const handleToggleAllCheckBox = useCallback(
    (event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setValue((val) => ({
        ...val,
        diseaseType: {
          nAMD: checked,
          PCV: checked,
          DME: checked,
          RVO: checked,
          mCNV: checked,
          others: checked,
        },
      }));
    },
    []
  );

  const handleDiseaseTypeCheckBox = useCallback(
    (
      event: SyntheticEvent<Element, Event>,
      checked: boolean,
      diseaseType: keyof typeof value.diseaseType
    ) => {
      setValue((val) => ({
        ...val,
        diseaseType: {
          ...val.diseaseType,
          [diseaseType]: checked,
        },
      }));
    },
    []
  );

  const handleAllTimeCheckBox = useCallback(
    (event: SyntheticEvent<Element, Event>, checked: boolean) => {
      setValue((val) => ({
        ...val,
        timeReleated: {
          ...val.timeReleated,
          allTime: checked,
        },
      }));
    },
    []
  );

  const handleChangeDate = useCallback(
    (newValue: Dayjs | null, dateName: string) => {
      setValue((val) => ({
        ...val,
        timeReleated: {
          ...val.timeReleated,
          [dateName]: newValue,
        },
      }));
    },
    []
  );

  const allChecked = useMemo(() => {
    const diseaseTypeArray = Object.values(value.diseaseType);
    return !diseaseTypeArray.some((e) => e === false);
  }, [value.diseaseType]);

  return (
    <Grid container sx={{ minWidth: 300, maxWidth: 500, p: 2 }}>
      <Grid item xs={12}>
        <Typography variant="h6" sx={{ p: 2 }}>
          {t("dashboardPage.filters")}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl>
          <FormLabel id="radio-buttons-group-label">
            {t("dashboardPage.countBy")}
          </FormLabel>
          <RadioGroup
            aria-labelledby="radio-buttons-group-label"
            name="radio-buttons-group"
            value={value.countBy}
            onChange={handleRadioGroupChange}
          >
            <FormControlLabel
              value={1}
              control={<Radio />}
              label={t("dashboardPage.patientDiseaseTypeChart.treatedPatients")}
            />
            <FormControlLabel
              value={2}
              control={<Radio />}
              label={t("dashboardPage.patientDiseaseTypeChart.treatedEyes")}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <FormGroup>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <FormLabel id="radio-buttons-group-label">
              {t("singlePharse.diseaseType")}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label={t("dashboardPage.allDiseaseTypes")}
              checked={allChecked}
              onChange={handleToggleAllCheckBox}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider sx={{ my: 1 }} />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="nAMD"
              checked={value.diseaseType.nAMD}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "nAMD")
              }
            />
          </Grid>

          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="PCV"
              checked={value.diseaseType.PCV}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "PCV")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="DME"
              checked={value.diseaseType.DME}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "DME")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="RVO"
              checked={value.diseaseType.RVO}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "RVO")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="mCNV"
              checked={value.diseaseType.mCNV}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "mCNV")
              }
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3}>
            <FormControlLabel
              control={<Checkbox />}
              label="Others"
              checked={value.diseaseType.others}
              onChange={(event, checked) =>
                handleDiseaseTypeCheckBox(event, checked, "others")
              }
            />
          </Grid>
        </Grid>
      </FormGroup>
      <FormGroup>
        <Grid container item xs={12}>
          <Grid item xs={12}>
            <FormLabel id="radio-buttons-group-label">
              {t("dashboardPage.patientDiseaseTypeChart.treatmentPeriod")}
            </FormLabel>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              control={<Checkbox />}
              label={t("dashboardPage.patientDiseaseTypeChart.allTime")}
              checked={value.timeReleated.allTime}
              onChange={handleAllTimeCheckBox}
            />
          </Grid>
        </Grid>
      </FormGroup>
      <Grid container item xs={12} direction="row" columnSpacing={1}>
        <Grid item sm={12} md={5}>
          <DesktopDatePicker
            label={t("dashboardPage.patientDiseaseTypeChart.fromDate")}
            inputFormat="DD/MM/YYYY"
            maxDate={value.timeReleated.toDate ?? dayjs()}
            value={value.timeReleated.fromDate}
            onAccept={(val) => handleChangeDate(val, "fromDate")}
            onChange={(val) => {
              val === null && handleChangeDate(val, "fromDate");
            }}
            disabled={value.timeReleated.allTime}
            renderInput={(params) => <BaseInput {...params} />}
          />
        </Grid>
        <Grid item sm={12} md={5}>
          <DesktopDatePicker
            label={t("dashboardPage.patientDiseaseTypeChart.toDate")}
            inputFormat="DD/MM/YYYY"
            minDate={value.timeReleated.fromDate ?? undefined}
            maxDate={dayjs()}
            value={value.timeReleated.toDate}
            onAccept={(val) => handleChangeDate(val, "toDate")}
            onChange={(val) => {
              val === null && handleChangeDate(val, "toDate");
            }}
            disabled={value.timeReleated.allTime}
            renderInput={(params) => <BaseInput {...params} />}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} direction="row-reverse" sx={{ mt: 1 }}>
        <BaseButton sx={{ ml: 1 }} onClick={() => applyFilter(true, value)}>
          {t("singlePharse.apply")}
        </BaseButton>
        <SecondaryButton onClick={() => applyFilter(false, null)}>
          {t("singlePharse.cancel")}
        </SecondaryButton>
      </Grid>
    </Grid>
  );
};

export default FilterOptionsPopover;
