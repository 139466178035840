import { Grid, Box, Typography, Button, Avatar } from "@mui/material";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import { SharedHcpsProps } from "../../../../utils/types/services/hcp";

const style = () =>
  ({
    width: 1,
    border: "1px solid #C2BAB5",
    pb: 2,
    px: 2,
    mt: 2,
    maxHeight: "68vh",
    overflow: "auto",
  } as const);

interface AddUserAccessProps {
  usersList: SharedHcpsProps[] | [];
  handleAddAccess: (id: number) => void;
}

const AddUserAccess = (props: AddUserAccessProps) => {
  const { usersList, handleAddAccess } = props;

  const { t } = useTranslation();

  const renderlistItems = useCallback(
    (row: SharedHcpsProps, index: number) => {
      const {
        user_id: hcp_id,
        first_name,
        last_name,
        avatar_url,
        institute_name,
      } = row;

      return (
        <Box
          key={hcp_id}
          sx={{
            mt: 4,
            width: 1,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Avatar alt="Profile-Image" src={avatar_url ?? undefined}>
              {(first_name?.charAt(0) ?? "").toUpperCase() +
                (last_name.charAt(0) ?? "").toUpperCase()}
            </Avatar>
            <Box sx={{ display: "flex", flexDirection: "column", ml: 3 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                {first_name + " " + last_name}
              </Typography>
              <Typography sx={{ color: "#544F4F" }}>
                {institute_name}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Button
              variant="text"
              sx={{ color: "#000000" }}
              onClick={() => handleAddAccess(hcp_id)}
              startIcon={<PersonAddAltOutlinedIcon />}
            >
              Add
            </Button>
          </Box>
        </Box>
      );
    },
    [t]
  );

  return (
    <Grid onClick={(e) => e.stopPropagation()} sx={style}>
      {usersList.length !== 0 && usersList.map(renderlistItems)}
      {usersList.length === 0 && (
        <Typography sx={{ textAlign: "center", pt: 2 }}>
          No data found
        </Typography>
      )}
    </Grid>
  );
};

export default AddUserAccess;
