import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./locales/en/translation.json";
import translationZH from "./locales/zh-HK/translation.json";

const resources = {
  en: {
    translation: translationEN,
  },
  zh: {
    translation: translationZH,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  // keySeparator: false, // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
