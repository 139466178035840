import { PatientDetails, PickType } from "../utils/types/services/patients";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import api from "./api";
import notificationToUser from "../utils/helperFunction/notificationToUser";
import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import { getOctImagesProps } from "../utils/types/services/treatmentRecord";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";
import { QRScanProps } from "../utils/types/services/productdetails";
const STATUS_200 = 200;

export const getOctImages = async (
  ids: number[]
): Promise<{
  success: boolean;
  status?: number;
  data?: getOctImagesProps[];
}> => {
  let getOctImagesResult = null;
  try {
    getOctImagesResult = await api.get(API_ROUTE_FULL_PATHS.getOctImages, {
      params: { ids: JSON.stringify(ids) },
    });
    if (getOctImagesResult.status === STATUS_200) {
      return {
        success: true,
        data: getOctImagesResult.data.data,
      };
    }

    notificationToUser({ msg: getOctImagesResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: getOctImagesResult });
  }
};

export interface AEDataProps {
  treatmentId: number;
  date: string;
  drugId: number;
  treatmentPhaseId: number;
  diseaseTypeId: number;
  reported_AE: boolean;
  remark: string | null;
}

export const toggleTreatmentStatus = async ({
  id,
  status,
}: {
  id: number;
  status: boolean;
}): Promise<{
  success: boolean;
  status?: number;
  data?: {
    patientId: PickType<PatientDetails, "id">;
    aeResult: {
      exists: boolean;
      data: AEDataProps | null;
    };
  };
}> => {
  let getOctImagesResult = null;
  try {
    getOctImagesResult = await api.post(
      API_ROUTE_FULL_PATHS.toggleTreatmentStatus,
      { id, status }
    );
    if (getOctImagesResult.status === STATUS_200) {
      return {
        success: true,
        data: getOctImagesResult.data.data,
      };
    }

    notificationToUser({ msg: getOctImagesResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: getOctImagesResult });
  }
};

export const addTreatmentRecord = async (
  treatmentFormData: FormData | null
): Promise<{
  success: boolean;
  status?: number;
  data?: {
    aeResult: {
      exists: boolean;
      data: AEDataProps | null;
    };
  };
}> => {
  let addTreatmentRecordResult = null;
  try {
    addTreatmentRecordResult = await api.post(
      API_ROUTE_FULL_PATHS.treatmentRecordAdd,
      treatmentFormData
    );
    if (addTreatmentRecordResult.status === STATUS_200) {
      return {
        success: true,
        data: addTreatmentRecordResult.data.data,
      };
    }

    notificationToUser({ msg: addTreatmentRecordResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: addTreatmentRecordResult });
  }
};

export const updateTreatmentRecord = async (
  treatmentFormData: FormData | null
): Promise<{
  success: boolean;
  status?: number;
  data?: {
    aeResult: {
      exists: boolean;
      data: AEDataProps | null;
    };
  };
}> => {
  let updateTreatmentRecordResult = null;
  try {
    updateTreatmentRecordResult = await api.post(
      API_ROUTE_FULL_PATHS.treatmentRecordUpdate,
      treatmentFormData
    );
    if (updateTreatmentRecordResult.status === STATUS_200) {
      return {
        success: true,
        data: updateTreatmentRecordResult.data.data,
      };
    }

    notificationToUser({ msg: updateTreatmentRecordResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: updateTreatmentRecordResult });
  }
};

export const reportAE = async ({
  freeText,
  treatmentId,
  furtherContact,
}: {
  freeText: string | null;
  treatmentId: number;
  furtherContact: boolean;
}): Promise<{
  success: boolean;
  data?: { aeResult: { pvReferenceNo: string } };
  status?: number;
}> => {
  let reportAEResult = null;
  try {
    reportAEResult = await api.post(API_ROUTE_FULL_PATHS.reportAE, {
      freeText,
      furtherContact,
      treatmentId,
    });
    if (reportAEResult.status === STATUS_200) {
      return {
        success: true,
        data: reportAEResult.data.data,
      };
    }

    notificationToUser({ msg: reportAEResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: reportAEResult });
  }
};

export const getProductDetailsFromQRScan = async ({
  serialNumber,
  lotNumber,
}: {
  serialNumber: string | null;
  lotNumber: string | null;
}): Promise<{
  success: boolean;
  status?: number;
  data?: QRScanProps;
}> => {
  let productDetailsResult = null;
  try {
    productDetailsResult = await api.post(
      API_ROUTE_FULL_PATHS.getProductDetailsFromScan,
      { serialNumber, lotNumber }
    );
    if (productDetailsResult.status === STATUS_200) {
      return {
        success: true,
        data: productDetailsResult.data.data,
      };
    }

    notificationToUser({ msg: productDetailsResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: productDetailsResult });
  }
};
