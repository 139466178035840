import { Grid, Typography } from "@mui/material";
import { useMutation, useQueries } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useCallback, useState, ChangeEvent } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import {
  getPatientEncryptData,
  postPatientEncryptData,
} from "../../services/patient";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import BaseButton from "../shareComponents/button/BaseButton";
import BaseCheckBox from "../shareComponents/checkbox/BaseCheckBox";

const ConfirmPatientConsetPage = () => {
  const { t } = useTranslation();
  const { encryptdata } = useParams();

  const [{ data, isLoading }] = useQueries({
    queries: [
      {
        queryKey: [REACT_QUERY_KEYS.PATIENT_ENCRYPT_DATA],
        queryFn: () => getPatientEncryptData({ encryptdata: encryptdata! }),
        refetchOnMount: "always",
      },
    ],
  });

  const {
    mutateAsync,
    isLoading: isLoading2,
    data: mutateData,
  } = useMutation(postPatientEncryptData);

  const [checked, setChecked] = useState<boolean>(false);

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  }, []);

  const onClickConfirm = useCallback(async () => {
    const result = await mutateAsync({ encryptdata: encryptdata! });
    if (result.success) window.scrollTo(0, 0);
  }, [mutateAsync, encryptdata]);

  if (isLoading) return <div>Loading...</div>;
  if (data?.success === false) return <div>Invalid</div>;
  if (mutateData?.success)
    return (
      <Typography>感謝您的確認！ Thank you for your confirmation!</Typography>
    );

  return (
    <Grid
      container
      item
      xs={12}
      sx={{
        backgroundColor: "background.paper",
        p: 2,
      }}
      direction="row"
    >
      <Grid container flexDirection={"column"}>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.mainTitle")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse1")}
        </Typography>

        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse2")}
          <u>
            {data?.data?.firstName}&nbsp;{data?.data?.lastName}
          </u>
          {t("policyText.patientConsentForm.zh.pharse3")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse4")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse5")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse6")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse7")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse8")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.zh.pharse9")}
        </Typography>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.zh.pharse10")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.zh.pharse11")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.zh.pharse12")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.zh.pharse13")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Typography variant="subtitle1" paragraph>
            {t("policyText.patientConsentForm.zh.pharse14")}
            <u>{dayjs().format("DD MMM YYYY")}</u>
          </Typography>
        </Grid>
      </Grid>

      <Grid container flexDirection={"column"}>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.mainTitle")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse1")}
        </Typography>

        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse2")}
          <u>
            {data?.data?.firstName}&nbsp;{data?.data?.lastName}
          </u>
          {t("policyText.patientConsentForm.en.pharse3")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse4")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse5")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse6")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse7")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse8")}
        </Typography>
        <Typography variant="subtitle1" paragraph>
          {t("policyText.patientConsentForm.en.pharse9")}
        </Typography>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.en.pharse10")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.en.pharse11")}
            </Typography>
          </Grid>
        </Grid>
        <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            •
          </Typography>
          &nbsp;&nbsp;
          <Grid item xs>
            <Typography variant="subtitle1" paragraph>
              {t("policyText.patientConsentForm.en.pharse12")}
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Typography variant="subtitle1" paragraph>
            {t("policyText.patientConsentForm.en.pharse14")}
            <u>{dayjs().format("DD MMM YYYY")}</u>
          </Typography>
        </Grid>
      </Grid>

      <Grid container flexDirection={"row"} alignItems="center">
        <Grid>
          <BaseCheckBox onChange={handleChange} />
        </Grid>
        <Grid xs item>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {t("policyText.patientConsentForm.zh.pharse15")}
            <u>
              {data?.data?.firstName}&nbsp;{data?.data?.lastName}
            </u>
            {t("policyText.patientConsentForm.zh.pharse16")}
          </Typography>
          <Typography variant="subtitle1" fontWeight={"bold"}>
            {t("policyText.patientConsentForm.en.pharse15")}
            <u>
              {data?.data?.firstName}&nbsp;{data?.data?.lastName}
            </u>
            {t("policyText.patientConsentForm.en.pharse16")}
          </Typography>
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-end">
        <BaseButton onClick={onClickConfirm} disabled={isLoading2 || !checked}>
          {t("singlePharse.confirmZh") + " / " + t("singlePharse.confirm")}
        </BaseButton>
      </Grid>
    </Grid>
  );
};

export default ConfirmPatientConsetPage;
