import * as yup from "yup";
const MAX_50 = "required max input 50 characters";
const NUM_8 = 8;
const NUM_50 = 50;

const passwordYup = yup
  .string()
  .min(NUM_8, "required min input 8 characters")
  .max(NUM_50, MAX_50)
  .test("test-password", "required password", (val, { createError }) => {
    if (val === undefined) return false;
    if (val.search(/[a-z]/) < 0)
      return createError({
        message: "required at least one small letter",
      });
    if (val.search(/[A-Z]/) < 0)
      return createError({
        message: "required at least one capital letter",
      });
    if (val.search(/[0-9]/) < 0)
      return createError({
        message: "required at least one number",
      });
    if (val.search(/[!@#$%^&*]/) < 0)
      return createError({
        message: "required at least one special character of !@#$%^&*",
      });
    if (val.search(/\s/) >= 0)
      return createError({ message: "required no space" });
    return true;
  })
  .required("required");

export default passwordYup;

export const passwordSimpleYup = yup
  .string()
  .max(NUM_50, MAX_50)
  .required("required");

export const passwordSimpleOptionalYup = yup
  .string()
  .max(NUM_50, MAX_50);
