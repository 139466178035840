import { DatePicker, DatePickerProps } from "@mui/x-date-pickers";
import { Dayjs } from "dayjs";
import { Control, FieldPath, FieldValues } from "react-hook-form";
import BaseInput from "../input/BaseInput";
import ControlledFormElement from "./ControlledFormElement";
import { InputAdornment, Grid } from "@mui/material";
import DateRangeIcon from "@mui/icons-material/DateRange";

type FormDatePickerProps<T extends FieldValues = FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  label?: string;
  placeholder?: string;
} & Omit<DatePickerProps<Dayjs, Dayjs>, "onChange" | "value" | "renderInput">;

const FormDatePicker = <T extends FieldValues>(
  props: FormDatePickerProps<T>
) => {
  const { control, name, label, placeholder, ...datePickerProps } = props;

  return (
    <ControlledFormElement<T> control={control} name={name}>
      {({ field: { value, onChange, ref }, fieldState: { error } }) => (
        <DatePicker
          {...datePickerProps}
          inputRef={ref}
          value={value}
          onChange={(e) => {
            onChange(e);
          }}
          label={label}
          renderInput={(params) => (
            <Grid>
              <Grid
                item
                xs
                sx={{ display: { xs: "block", sm: "none", md: "none" } }}
              >
                <BaseInput
                  {...params}
                  error={!!error}
                  helperText={error?.message ?? " "}
                  sx={{ width: 1 }}
                  size="small"
                  placeholder={placeholder}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <DateRangeIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                md
                sm
                sx={{ display: { xs: "none", sm: "block", md: "block" } }}
              >
                <BaseInput
                  {...params}
                  error={!!error}
                  helperText={error?.message ?? " "}
                />
              </Grid>
            </Grid>
          )}
        />
      )}
    </ControlledFormElement>
  );
};

export default FormDatePicker;
