import { Typography, Grid, MenuItem, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  EyeDetailsProps,
  eyeStatus,
} from "../../../utils/types/services/patients";
import BaseInput from "../../shareComponents/input/BaseInput";
import _ from "lodash";
import { useCallback, useState, ChangeEvent, useEffect, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";
import { toggleEyeTreatmentStatus } from "../../../services/patient";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";

interface TreatmentInfoHeaderProps {
  eyeDetails: EyeDetailsProps;
  handleGraphModalOpen: () => void;
  isOwner: boolean;
  treatmentStatusValue: keyof typeof eyeStatus | null;
  handleChangeTreatmentStatus: (value: keyof typeof eyeStatus | null) => void;
}

const TreatmentInfoHeader = (props: TreatmentInfoHeaderProps) => {
  const {
    eyeDetails,
    handleGraphModalOpen,
    isOwner,
    treatmentStatusValue,
    handleChangeTreatmentStatus,
  } = props;
  const { t } = useTranslation();

  const { mutateAsync } = useMutation(toggleEyeTreatmentStatus);

  const [treatmentStatus, setTreatmentStatus] = useState<
    keyof typeof eyeStatus | null
  >(null);
  const onChangeStatus = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTreatmentStatus(event.target.value as keyof typeof eyeStatus);
      handleChangeTreatmentStatus(event.target.value as keyof typeof eyeStatus);
      mutateAsync({ eyeId: eyeDetails.id, status: event.target.value });
    },
    [eyeDetails.id, mutateAsync, treatmentStatusValue]
  );

  const selectionStatus = useMemo(() => {
    return _.uniq([
      eyeDetails.calStatus!,
      eyeDetails.status!,
      eyeStatus.completed,
    ]);
  }, [eyeDetails.calStatus, eyeDetails.status]);

  useEffect(() => {
    setTreatmentStatus(treatmentStatusValue);
  }, [treatmentStatusValue]);

  return (
    <Grid
      container
      item
      xs={12}
      direction="row"
      justifyContent="space-between"
      sx={{ minHeight: "5vh", mb: 3, px: 2 }}
    >
      <Grid
        container
        item
        xs={10}
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
      >
        <Box sx={{ pr: 1 }}>
          <Typography variant="h6" fontWeight="bold" align="center">
            {eyeDetails.diseaseType}
          </Typography>
        </Box>
        <Box>
          {eyeDetails.status && eyeDetails.calStatus && treatmentStatus && (
            <BaseInput
              label={t("singlePharse.status")}
              select
              size="small"
              value={treatmentStatus}
              onChange={onChangeStatus}
              sx={{ minWidth: "5vw" }}
              disabled={!isOwner}
            >
              {selectionStatus.map((e) => (
                <MenuItem key={e} value={e}>
                  {e.toUpperCase()}
                </MenuItem>
              ))}
            </BaseInput>
          )}
        </Box>
      </Grid>

      <Grid container item xs={2} alignItems="center" justifyContent="flex-end">
        <Grid
          container
          onClick={handleGraphModalOpen}
          alignItems="center"
          justifyContent="center"
          flexDirection="row"
          sx={{
            cursor: "pointer",
            width: "fit-content",
          }}
        >
          <TrendingUpOutlinedIcon
            fontSize="large"
            sx={{
              color: "#006544",
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TreatmentInfoHeader;
