import SearchIcon from "@mui/icons-material/Search";
import { Grid, InputAdornment, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useTranslation } from "react-i18next";
import BaseInput from "../../shareComponents/input/BaseInput";
import { Theme } from "@mui/material/styles";

const style = (theme: Theme) =>
  ({
    width: "30%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  } as const);

interface PatientListPageHeaderProps {
  setSearchText: Dispatch<SetStateAction<string>>;
}

const PatientListPageHeader = (props: PatientListPageHeaderProps) => {
  const { setSearchText } = props;

  const { t } = useTranslation();

  const handleSearchInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchText(event.target.value);
    },
    []
  );

  return (
    <Grid
      container
      alignItems="center"
      justifyContent="space-between"
      sx={{ padding: 2.8 }}
    >
      <Typography variant="h5" fontWeight="bold">
        {t("patientListingPage.header")}
      </Typography>

      <BaseInput
        placeholder={t("patientListingPage.searchByName")}
        size="small"
        sx={style}
        type="search"
        onChange={handleSearchInputChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Grid>
  );
};

export default PatientListPageHeader;
