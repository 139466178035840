import { toast } from "react-toastify";

interface NotificationProps {
  msg: any;
}

const notificationToUser = (props: NotificationProps) => {
  const { msg } = props;
  if (typeof msg === "string") toast(msg);
};

export default notificationToUser;
