import InfoIcon from "@mui/icons-material/Info";
import { IconButton, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { styled } from "@mui/material/styles";
import Tooltip, { TooltipProps } from "@mui/material/Tooltip";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    enterTouchDelay={0}
    leaveTouchDelay={3000}
    classes={{ popper: className }}
  />
))({});

const SimpleTooltip = ({ text }: { text: string }) => {
  const { t } = useTranslation();
  return (
    <CustomWidthTooltip
      title={<Typography color="inherit">{t(text)}</Typography>}
    >
      <IconButton>
        <InfoIcon sx={{ color: "info.main" }} />
      </IconButton>
    </CustomWidthTooltip>
  );
};

export default SimpleTooltip;
